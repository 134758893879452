import { useConfirm } from "material-ui-confirm";
import { useContext, useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";
import Input from "../../../components/Input/inputs";
import Table from "../../../components/Table";

import { AuthContext } from "../../../services/context";
import * as utils from "../../../services/utilities";
import MUIDialog from "../../../components/Modal";
import { enqueueSnackbar } from "notistack";

export default function Exemptions() {
  utils.log.component("Exemptions");
  // #region Assertions
  //utils.assert(survey != null, "Survey is required");
  // #endregion
  // #region Functions
  const updateList = async () => {
    const result = await exemptionService.getAll();
    setList(result);
    utils.log.stateChange("setList(result)", result);
  };
  // #endregion
  // #region Initialize
  const confirm = useConfirm();
  const { exemptionService, cache } = useContext(AuthContext);
  const [openDetail, setDetailOpen] = useState(false);
  const [openAdd, setAddOpen] = useState(false);
  const [detail, setDetail] = useState("");
  const [list, setList] = useState([]);

  const columns = [
    { Header: "Code", accessor: "code" },
    { Header: "Reason", accessor: "reason" },
    { Header: "Description", accessor: "description" },
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    updateList();
  }, [openAdd, openDetail]);
  const handleAddOpen = () => {
    utils.log.event("handleAddOpen()");
    setDetail(null);
    setAddOpen(true);
  };
  const handleClose = () => {
    utils.log.event("handleClose()");
    setAddOpen(false);
    setDetailOpen(false);
    updateList();
    cache.clear();
  };

  const handleEdit = (edit) => {
    utils.log.event(`handleEdit(${edit.code})`, edit);
    setDetail(edit);
    cache.setDetails(edit);
    setDetailOpen(true);
  };
  const handleDelete = (code) => {
    utils.log.event(`handleDelete(${code})`);

    confirm({
      description: `Confirm deletion of survey ${code}.`,
    })
      .then(() => {
        exemptionService
          .remove(code)
          .then(async (result) => {
            await updateList(); // Ensure Table component below has a conditional on list.
            //setSurveyOpen(false);
            //utils.log.stateChange("setSurveyOpen(false)");
          })
          .catch((error) => {
            utils.log.error(error);
          });
      })
      .catch(() => utils.log.warn("Survey deletion cancelled."));
  };
  // #endregion
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            onClick={() => handleAddOpen()}
            endIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={list}
            selected
            onSelected={(row) => handleEdit(row.original)}
            actions={{
              delete: (e, row) => {
                handleDelete(row.code);
              },
            }}
          ></Table>
        </Grid>
      </Grid>
      {openAdd && (
        <Add
          /* survey={survey}  */
          open={openAdd}
          onClose={handleClose}
        />
      )}
      {openDetail && (
        <Details
          /* survey={survey} */
          exemption={detail}
          open={openDetail}
          onClose={handleClose}
        />
      )}
    </>
  );
}
const Details = ({ exemption, open, onClose }) => {
  utils.log.component(`Details( detail: ${exemption.code})`, exemption);

  const { cache, exemptionService } = useContext(AuthContext);
  //formUtility.setDetail(exemption);
  // #endregion
  const handleUpdate = () => {
    exemptionService.update(exemption);
    cache.clear();
    onClose();
  };
  // #endregion
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Update Exemption"
      description="Exemptions allow recipients to excuse themselves from participating in
    a survey."
      maxWidth="sm"
      actions={
        <>
          {/* <Button onClick={onClose}>Close</Button> */}
          <Button onClick={handleUpdate}>Update</Button>
        </>
      }
      PaperProps={{style:{height:"450px"}}}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <Input
            label="Code"
            fullWidth
            name="code"
            disabled
            /*               onChange={(event) => formUtility.handleChange(event, setInputs)}
              value={formUtility.getValue("code")} */
            onChange={(e) => cache.set(e)}
            defaultValue={cache.get("code")}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Input
            label="Reason"
            fullWidth
            name="reason"
            /*               onChange={(event) => formUtility.handleChange(event, setInputs)}
              value={formUtility.getValue("reason")} */
            onChange={(e) => cache.set(e)}
            defaultValue={cache.get("reason")}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            label="Description"
            fullWidth
            name="description"
            /*               onChange={(event) => formUtility.handleChange(event, setInputs)}
              value={formUtility.getValue("description")} */
            onChange={(e) => cache.set(e)}
            defaultValue={cache.get("description")}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const Add = ({ open, onClose }) => {
  utils.log.component(`Add()`);
  const { exemptionService } = useContext(AuthContext);
  const [exemptionDetails, setExemptionDetails] = useState({
    code: "",
    description: "",
    reason: "",
  });

  const handleChange = (e) => {
    const {name,value} = e.target;
    setExemptionDetails((prev) => {
      return{
        ...prev,
        [name]:value
      }
    } )
  }

  const handleSubmit = async () => {
    const response = await exemptionService.add(exemptionDetails);
    if(response?.ok){
      enqueueSnackbar("Exemption Added Successfully",{variant:"success"});
      onClose(); 
    }
  };
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Add Recipient Exemption"
      description="Define recipient exemption instructions."
      actions={
        <>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </>
      }
      PaperProps={{style:{height:"450px"}}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Input
            label="Code"
            fullWidth
            name="code"
            value={exemptionDetails?.code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Input
            label="Reason"
            fullWidth
            name="reason"
            value={exemptionDetails?.reason}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            label="Description"
            fullWidth
            name="description"
            value={exemptionDetails?.description}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};
