/*eslint-disable*/
import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Button as MUIButton,
  Box,
  InputAdornment,
  useTheme,
  useMediaQuery,
  FormControl,
  Select,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import logo from "../../assets/GPG_Logo.svg";
import logout from "../../assets/logout.svg";
import orgPeople from "../../assets/organization_people.svg";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import Button from "../Button";
import { NAVBAR } from "../../utils/contentData";
import NavBarToggleIcon from "../../assets/NavBarTogglerIconNew.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Item from "antd/lib/list/Item";
import { getHeaders } from "../../services/context";
import { enqueueSnackbar } from "notistack";
import Form from "../Form";
import EmployeeService from "../../services/employee";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LoginIcon from "@mui/icons-material/Login";
import SignUpIcon from "../../assets/get started.svg";
import styles from "./style.module.css";

const ROLE_PRIMARY_CORD = "PrimaryCoordinator";
const ROLE_DEPARTMENT_CORD = "DepartmentCoordinator";
const USE_CASE = "Use Case";
const RULE_TWENTY_TWO = "Rule 2202";
const UNIVERSITIES = "Universities";
const EMPLOYEES = "Employees";
const TDM = "Transport Demand Management";


function currentUserRole(roles) {
  if (
    roles?.includes(ROLE_PRIMARY_CORD) &&
    roles?.includes(ROLE_DEPARTMENT_CORD)
  ) {
    return "Primary Coordinator";
  } else if (roles?.includes(ROLE_PRIMARY_CORD)) {
    return "Primary Coordinator";
  } else if (roles?.includes(ROLE_DEPARTMENT_CORD)) {
    return "Department Coordinator";
  } else {
    return null;
  }
}


export default function NavBar({ isSideBarOpen, handleToggleSideBar }) {
  const navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { employee, token } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProvisioningVisible, setIsProvisioningVisible] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutWithRedirect = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };


  useEffect(() => {
    if (employee === null) return;
    if (employee && Object.keys(employee).length > 0) {
      setIsProvisioningVisible(false);
    } else {
      setIsProvisioningVisible(true);
    }
  }, [employee]);

  useEffect(() => {
    if (employee === null) return;
    if (isProvisioningVisible) {
      navigate("/provisioning");
    }
  }, [isProvisioningVisible, employee]);

  useEffect(() => {
    if (employee?.roles.includes("DepartmentCoordinator") && !employee?.plan) {
      navigate("/profile?view=plan");
    }
  }, [employee]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  // const tempUseCasePage = [{"use-case":"USE CASE PAGE"},{"rule-book":"RULE TWENTY TWO"},{"universities":"UNIVERSITIES"},{"employees":"EMPLOYEES"},{"transport-demand-management":"TDM"}];
  const tempUseCasePage = [];
  const handleUseCase = (e) => {
    const currentSelectedVal = e.target.value;
      navigate(`/use-case/${currentSelectedVal}`)
  };

  return (
      <div className={` ${styles.root} ${styles.AppBarContainer} `}>
        <div className={styles.navItems}>
          <div style={{ position: "relative" }}>
            {isAuthenticated &&
              (isSideBarOpen && employee?.organizations?.length > 0 ? (
                <MenuOpenIcon
                  onClick={handleToggleSideBar}
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) :  employee?.organizations?.length > 0 && (
                <MenuIcon
                  onClick={handleToggleSideBar}
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ))}
          </div>

          <Link to="/?view=coordinators">
            <img
              src={logo}
              width="auto"
              height="48"
              className={styles.logo}
              alt="GoPassGo Networks, LLC"
            />
          </Link>

          {!isAuthenticated ? (
            isMd ? (
              <></>
            ) : (
              NAVBAR.links.map((item) => {
                if (!item.condition && item.link !== "/use-case") {
                  return (
                    <NavLink
                      to={item?.link}
                      key={item?.link}
                      className={({ isActive }) =>
                        isActive ? styles.isActive : styles.isNotActive
                      }
                    >
                      {({ isActive }) => (
                        <MUIButton
                          className={`${styles.noOutlineButton} ${
                            isActive ? styles.isActiveButton : ""
                          }`}
                        >
                          {item.title}
                        </MUIButton>
                      )}
                    </NavLink>
                  );
                }
                if (item.link === "/use-case") {
                  return (
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={""}
                        displayEmpty
                        label="Age"
                        renderValue={(selected) => {
                          if (selected === "") {
                            return <div>{USE_CASE}</div>;
                          }
                        }}
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: {
                              marginTop: "8px",
                            },
                          },
                        }}
                        onChange={handleUseCase}
                      >
                        {
                          tempUseCasePage?.map((item,index)=>{
                            const key = Object.keys(item)[0];
                            return(
                            <MenuItem value={key} key={index}>
                            {item[key]}
                            </MenuItem>)
                          })
                        }
                      </Select>
                    </FormControl>
                  );
                }

                if (item?.condition === "provisioning") {
                  return (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        isActive ? styles.isActiveLink : null
                      }
                    >
                      <MUIButton className={styles.noOutlineButtonProvisining}>
                        {item.title}
                      </MUIButton>
                    </NavLink>
                  );
                }

                return null;
              })
            )
          ) :  employee?.organizations.length > 0 && (
            <>
              <div
                className={` ${styles.organisationSelector} onboarding-1`}
                onClick={handleClick}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: isMd ? "space-between" : "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: isMd ? "100%" : "auto",
                  }}
                >
                  <Item style={{ listStyle: "none" }}>
                    <img
                      src={orgPeople}
                      alt="GoPassGo Networks, LLC"
                      width="34"
                      height="34"
                    />
                  </Item>
                  <Item style={{ listStyle: "none" }}>
                    <Stack direction={"column"} sx={{ height: "full" }}>
                      <Box
                        sx={{
                          color: "#07070780",
                          fontWeight: "600",
                          lineHeight: "18px",
                          paddingBottom: "2px",
                          fontSize: "12px",
                        }}
                      >
                        Switch Organization :
                      </Box>
                      <Box className={styles.selectedOptionEmployee}>
                        {employee?.organization?.legalName}
                      </Box>
                    </Stack>
                  </Item>
                  <Item style={{ listStyle: "none" }}>
                    <Stack direction={"column"} spacing={-3.5}>
                      <Box sx={{ fontWeight: "bold", color: "black" }}>
                        <img src={NavBarToggleIcon} alt="toggle-icon" />
                      </Box>
                      <Box
                        sx={{
                          fontWeight: "bold",
                          transform: "rotate(-180deg)",
                          color: "black",
                        }}
                      >
                        <img src={NavBarToggleIcon} alt="toggle-icon" />
                      </Box>
                    </Stack>
                  </Item>
                </Stack>

                {isProvisioningVisible && (
                  <p route="/provisioning" label="Get Started"></p>
                )}
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{
                  left: 0,
                  right: 0,
                  width: "265px",
                }}
              >
                <OrganisationSelector />
              </Menu>
            </>
          )}
        </div>

        <div className={styles.loginGetStarted}>
          {!isAuthenticated ? (
            isMd ? (
              <HamBarger />
            ) : (
              <>
                <Button
                  id="qsLoginBtn"
                  style={{ marginRight: "10px" }}
                  color="primary"
                  className={styles.noOutline}
                  variant="text"
                  onClick={() => loginWithRedirect()}
                >
                  Login
                </Button>
                <Button
                  id="qsSignUpBtn"
                  style={{ borderRadius: "40px" }}
                  variant="contained-primary"
                  color="primary"
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: "signup",
                      },
                    })
                  }
                >
                  Get Started
                </Button>
              </>
            )
          ) : (
            <UserMenu
              user={user}
              token={token}
              logoutWithRedirect={logoutWithRedirect}
            />
          )}
        </div>
      </div>
  );
}

const UserMenu = ({ user, token, logoutWithRedirect }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { employee } = useContext(AuthContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isSsoOpen, setIsSsoOpen] = useState(false);
  const handleSsoClose = () => {
    setIsSsoOpen(false);
  };
  const handleSSO = () => {
    setIsSsoOpen(true);
  };

  const profileTabWidth =
    !employee?.firstName &&
    !employee?.lastName &&
    !currentUserRole(employee?.roles)
      ? "100px"
      : "200px";

  return (
    <>
      <div className="onboarding-2">
        <Stack
          direction="row"
          spacing={2}
          className={[styles.userMenuContainer, styles.cursorPointer]}
          onClick={handleClick}
        >
          <IconButton
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 40, height: 40 }} src={user.picture} />
          </IconButton>

          <Stack direction="column" className={styles.hideEmployeeDetail}>
            <span className={styles.selectedOption}>
              {employee?.firstName}&nbsp;{employee?.lastName}
            </span>

            <span className={styles.userProfileRoleTitle}>
              {currentUserRole(employee?.roles)}
            </span>
          </Stack>

          <Stack>
            <img
              src={NavBarToggleIcon}
              alt="toggle-icon"
              style={{
                cursor: "pointer",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.1s ease",
              }}
            />
          </Stack>

          {/* #TODO: Add Arrow  */}
        </Stack>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          PaperProps={{
            style:{
              width:"180px"
            }
          }}
          style={{
            width: "180px",
            left: 0,
            right: 0,
          }}
        >
          <Link style={{textDecoration:"none"}} to={ employee?.organizations?.length > 0 ? "/profile?view=details" : "/provisioning"}>
            <MenuItem onClick={handleClose} sx={{ width: "100%" }}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText style={{ color: "#000" }}>Profile</ListItemText>
            </MenuItem>
          </Link>

          <Divider />

          <MenuItem
            onClick={() => {
              handleSSO();
              handleClose();
            }}
            sx={{
              width: "100%",
            }}
          >
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText style={{ color: "#000" }}>SSO</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              document.cookie = "GPG-CST=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
              logoutWithRedirect();
              handleClose();
            }}
            sx={{ width: "100%" }}
          >
            <ListItemIcon>
              <img
                src={logout}
                alt="GoPassGo Networks, LLC"
                width="20"
                height="20"
              />
            </ListItemIcon>
            <ListItemText style={{ color: "#000" }}>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      {isSsoOpen && (
        <SSO open={isSsoOpen} onClose={handleSsoClose} token={token}></SSO>
      )}
    </>
  );
};

const SSO = ({ onClose, open, token }) => {
  utils.assert(token != null, "Token required.  Unable to find recipient.");

  const { user } = useAuth0();
  const { setHeaders, setIsEmployeeLoading, setEmployee, setEmployeeService } =
    useContext(AuthContext);
  const [input, setInput] = useState({
    newToken: token,
    email: user?.email,
  });

  const handleCopy = (textToCopy) => {
    const clipboard = navigator.clipboard;
    clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar("Copied to clipboard successfully!", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Unable to write to clipboard. Error: ${error}`, {
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleUpdateToken = async (e) => {
    e.preventDefault();
    try {
      setIsEmployeeLoading(true);
      const newHeaders = getHeaders(input.newToken);
      await setHeaders(newHeaders);
      let employeeService = new EmployeeService(input.email, newHeaders);
      const employeeResponse = await employeeService.get(
        input.email,
        newHeaders
      );
      employeeService.currentEmployee = employeeResponse;

      await setEmployeeService(employeeService);
      await setEmployee(employeeResponse);
      if (
        window.location.pathname.includes("provisioning") &&
        employeeResponse?.organization
      ) {
        window.location.href("/?view=coordinators");
      }
    } catch (error) {
      utils.log.error(error);
    } finally {
      setIsEmployeeLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      PaperProps={{
        style: { maxWidth: "700px" },
      }}
    >
      <Form onSubmit={handleUpdateToken}>
        <DialogTitle>Single Sign-On</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "25px" }}>
            Copy the session token below (exactly) and paste it into the SSO
            login page.
          </DialogContentText>
          <Grid container spacing="20">
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                name="email"
                value={input.email}
                style={{ width: "100%", cursor: "pointer" }}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ContentCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCopy(input?.email);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Session Token"
                fullWidth
                name="newToken"
                value={input.newToken}
                style={{ width: "100%" }}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ContentCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCopy(input?.newToken);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Set</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export const OrganisationSelector = ({ menuItemClass }) => {
  const { employeeService, employee, setUpEmployee } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleSelectOrganisation = async (organisationCode) => {
    try {
      await employeeService.setOrganisation(organisationCode);
      await setUpEmployee(); // Fetch employee latest info
    } catch (error) {
      utils.log.error("Error selecting organisation", error);
    }
  };
  return (
    <MenuList open={open} onClose={handleClose} onClick={handleClose}>
      {employee?.organizations?.length > 0 &&
        employee.organizations.map((org) => (
          <MenuItem
            onClick={() => handleSelectOrganisation(org.code)}
            key={org.code}
            sx={menuItemClass}
          >
            {employee?.organization?.code === org.code ? (
              <ListItemIcon>
                <RadioButtonCheckedIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <RadioButtonUncheckedIcon />
              </ListItemIcon>
            )}
            <ListItemText
              className={
                employee?.organization?.code === org?.code
                  ? styles.selectedItemColor
                  : styles.menuItemColor
              }

              // inset={employee?.organization?.code !== org.code}
            >
              {org.legalName}
            </ListItemText>
          </MenuItem>
        ))}
      <Link to="/provisioning" className={styles.addOrgButton}>
        <MenuItem>
          <ListItemIcon>
            <Add className={styles.addIcon} />
          </ListItemIcon>
          <ListItemText style={{ color: "#000" }}>
            Add organization
          </ListItemText>
        </MenuItem>
      </Link>
    </MenuList>
  );
};

const HamBarger = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const open = Boolean(isMenuOpen);

  const { loginWithRedirect } = useAuth0();

  const handleClick = (event) => {
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div ref={menuRef}>
        {open ? (
          <MenuOpenIcon
            onClick={handleClose}
            sx={{
              height: "30px",
              width: "30px",
              color: "black",
              cursor: "pointer",
            }}
            key={"1"}
          />
        ) : (
          <MenuIcon
            onClick={handleClick}
            sx={{
              height: "30px",
              width: "30px",
              color: "black",
              cursor: "pointer",
            }}
            key={"0"}
          />
        )}
      </div>

      <Menu
        id="account-menu"
        anchorEl={menuRef.current}
        open={Boolean(isMenuOpen)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          top: 55,
          right: 0,
        }}
        key={"11"}
      >
        {NAVBAR.links.map((item, index) => {
          if (!item.condition) {
            return (
              <Box key={`div-${index}`}>
                <MenuItem key={`menuItem-${index}`} onClick={handleClose}>
                  <NavLink
                    to={item?.link}
                    key={`navlink-${index}`}
                    className={({ isActive }) =>
                      isActive ? styles.isActive : styles.isNotActive
                    }
                  >
                    {({ isActive }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ListItemIcon
                          size="small"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          {item?.icon}
                        </ListItemIcon>
                        <ListItemText>
                          <MUIButton
                            className={`${styles.noOutlineButton} ${
                              isActive ? styles.isActiveButton : ""
                            }`}
                          >
                            {item.title}
                          </MUIButton>
                        </ListItemText>
                      </div>
                    )}
                    {/* </ListItemText> */}
                  </NavLink>
                </MenuItem>

                <Divider />
              </Box>
            );
          }
        })}
        <MenuItem onClick={() => loginWithRedirect()} key={"/login"}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText
            style={{ color: "#041433", fontWeight: 500, padding: "0px 10px" }}
          >
            Login
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            })
          }
          key={"/signup"}
        >
          <ListItemIcon>
            <img src={SignUpIcon} alt="signup-login" />
          </ListItemIcon>
          <ListItemText
            style={{ color: "#041433", fontWeight: 500, padding: "0px 10px" }}
          >
            Get Started
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
