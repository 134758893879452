// TODO: 051323 - Deprecate FormUtility in favor of cache.js
// TODO: 051423 - Review if we need to add time zone info for recipient and organization to calculate correct dates.
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid } from "@mui/material";
import * as utils from "../../services/utilities";
import {
  updateQuestionAnswer,
  initValueRangeObject,
} from "../../services/question";
import * as config from "../../services/config";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";

import FormUtility from "../../services/form";
// import useMediaQuery from "../../hooks/useMediaQuery";
const formUtility = new FormUtility();

export const comparators = [
  "equals",
  "not",
  "before",
  "after",

  "between",
  "weekday",
  "weekend",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "first day of month",
  "last day of month",
  //"not leap year",
  //"leap year",
];
export const comparatorIsValue = [
  "weekday",
  "weekend",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "first day of month",
  "last day of month",
];

export function New() {
  return {
    type: "DateInput",
    code: null,
    title: "New Date Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    min: dayjs(),
    max: dayjs().add(1, "day"),
  };
}
export function Reset(question) {
  // PURPOSE: Reset user answers specific to this question type.
  utils.assert(question != null, "question is null.");
  utils.log.component(`DateInput.Reset(${question.code})`, question);
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  utils.log.component(`DateInput.Edit(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  // #region Initialize
  formUtility.setDetail(question);
  // Validate min/max exists

  const disablePastDates = (date) =>  dayjs(date).isBefore(question?.min, "day");

  question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs()
  );

  const disableFutureDates = (date) => dayjs(date).isAfter(question?.max, "day"); 

  question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day")
  );

  // #endregion
  // #region Events
  const handleChange = (e, target) => {
    // NOTE: target = The question.property to update
    // Valid value
    const isoDate = dayjs(e.$d).format(config.defaults.DATE_STORAGE_FORMAT);
    question[target] = isoDate; //value.toLocaleDateString();
    // Adjust min/max
    const targetOpposite = target === "min" ? "max" : "min";
    const mode = target === "min" ? "up" : "down";
    question[targetOpposite] = utils.adjustDate(
      dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
      dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
      mode
    )[targetOpposite];

    // Update question
    const event = {
      target: { name: target, value: isoDate },
    };
    formUtility.handleChange(event, setInputs);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography id="non-linear-slider" gutterBottom style={{fontFamily:'Public-sans'}}>
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.min}
            label="Minimum"
            onChange={(e) => handleChange(e, "min")}
            shouldDisableDate={disableFutureDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.max}
            label="Maximum"
            onChange={(e) => handleChange(e, "max")}
            shouldDisableDate={disablePastDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export const DependencySelector = ({
  context,
  questions,
  setDependency,
  selectedQuestion,
  formUtility,
  inputs,
  setInputs,
}) => {
  // !NOTE: useState() is prohibited in this component.
  // #region Initialize
  utils.log.section("DependencySelector(DateInput)");
  const dependency = dependencyUtil.get(context);
  const question = dependency.question;
  const list = utils.toSelectItem(comparators);

  const comparator = dependency?.comparator ?? list[0].value;
  /*   const [comparator, setComparator] = useState(
    dependency?.comparator ?? list[0].value
  ); */

  //formUtility.setDetail(dependency); // TODO: 062823 Remove dependency on formUtility.
  //inputs["value"] = dependency?.value ?? ""; // Restore value from existing dependency

  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  /*   const [value, setValue] = useState(
    valueConvertByComparator(dependency, minDate, maxDate)
  ); */
  const value = valueConvertByComparator(dependency, minDate, maxDate);

  if (dependency != null && dependency?.value == null) {
    // Add a value if it doesn't exist
    dependency.comparator = list[0].value;
    dependency.value = null;
  }
  // #endregion
  // #region Events
  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency.comparator = e.target.value;
    dependencyUtil.set(context, dependency);
    utils.log.event(
      `DateInput.DependencySelector(${context.question.code}).handleComparatorChange(${dependency.comparator})`
    );
    setInputs({ ...inputs, comparator: e.target.value }); // !KLUDGE - Force re-render
  };
  const handleValueChange = (e) => {
    const isoDate = e.format(config.defaults.DATE_STORAGE_FORMAT);
    const dependency = dependencyUtil.get(context);
    dependency.value = isoDate;
    dependencyUtil.set(context, dependency);
    //setValue(isoDate);
    setInputs({ ...inputs, value: isoDate }); // !KLUDGE - Force re-render
  };
  // #endregion
  return (
    <>
      <FormSelect
        id="dateInput-comparators"
        label="Comparators"
        data={list}
        defaultValue={comparator}
        format={config.defaults.DATE_DISPLAY_FORMAT}
        onChange={handleComparatorChange}
        style={{
          width: utils.getTextBoxWidth(comparator.length),
        }}
      ></FormSelect>{" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {dependency.comparator === "between" && (
          <ComparatorBetween
            context={context}
            minDate={minDate}
            maxDate={maxDate}
          ></ComparatorBetween>
        )}
        {dependency.comparator !== "between" && (
          // TODO: 070223 - REVIEW for issues with value.  ASSERT typeof value === string
          <DatePicker
            renderInput={(props) => (
              <Input style={{ width: "150px" }} {...props} />
            )}
            label="Value"
            defaultValue={value} /* value={value} */
            value={value}
            disabled={comparatorIsValue.includes(dependency.comparator)}
            onChange={handleValueChange}
            minDate={minDate}
            maxDate={maxDate}
            /*             style={{
              width: {
                textBoxWidthFormatted: utils.getTextBoxWidth(value.length),
              },
            }} */
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("DateInput.Render()", props);
  // #region Initialize
  const question = props.question;
  utils.assert(question != null, "question is null.");
  // TODO: 062923 Why is question of type DateInput appearing here with choice collection?
  const preview = props.preview ?? false;

  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let answer =
    question.answer == null
      ? null
      : utils.checkDateValidity(
          `dependency(${question.code}).value`,
          question.answer,
          minDate
        );

  // #endregion
  // #region Events

  const handleChange = (e) => {
    let answer = e.format(config.defaults.DATE_STORAGE_FORMAT); //e.$d.toLocaleDateString(); // Format to date only.
    utils.log.event(`handleChange(e): ${answer}`);
    //question.answer = answer;
    updateQuestionAnswer(question, answer, preview, props.setQuestions);
  };

  const isQuestionCompleted  = isCompleted(question);

  // const {isSmDown} = useMediaQuery();


  // #endregion
  return (
    <>
       <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          {/* <div>{helperText}</div> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => <Input {...props} />}
              label="Answer"
              value={answer}
              onChange={handleChange}
              minDate={minDate}
              maxDate={maxDate}
              // sx={{  width: isSmDown ? "100%":"300px", height: "56px", background: "#F8F8F8" }}
              sx={{  width: "300px", height: "56px", background: "#F8F8F8" }}
            />
          </LocalizationProvider>
        </div>

      </div>
    </>
  );
}

// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  //debugger;
  if (dependency == null) {
    utils.log.info(`DateInput.isAnswered(false): dependency is null`);
    return false;
  }
  // *** Evaluate ***
  switch (dependency.comparator) {
    case "equals":
    case "not":
      return handleComparatorEquality(dependency);
    case "before":
    case "after":
    case "between":
      return handleComparatorPrepositions(dependency);
    case "first day of month":
    case "last day of month":
      return handleComparatorDaysOfMonth(dependency);
    case "not leap year":
    case "leap year":
      return handleComparatorLeapYear(dependency);
    case "monday":
    case "tuesday":
    case "wednesday":
    case "thursday":
    case "friday":
    case "saturday":
    case "sunday":
    case "weekday":
    case "weekend":
      return handleComparatorDaysOfWeek(dependency);

    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
export const valueConvertByComparator = (dependency, minDate, maxDate) => {
  // PURPOSE: Convert value to object for between comparator and string for other comparators.
  /* PARAMETERS:
  dependency: required object
  minDate: string
  maxDate: string
*/
  // #region Assertions
  utils.assert(
    dependency.hasOwnProperty("comparator"),
    `Required property(comparator) is not in dependency.`
  );
  utils.assert(
    dependency.hasOwnProperty("value"),
    `Required property(value) is not in dependency.`
  );
  // #endregion Assertions

  if (dependency.comparator === "between") {
    if (typeof dependency.value !== "object") {
      // Initialize value to object for between comparator
      dependency.value = initValueRangeObject(minDate, maxDate);
      utils.log.info(
        "Converted dependency.value to object for between comparator."
      );
    }
    return dependency.value;
  } else {
    // Comparator is NOT betwen and value is a string
    // Check if value is object and convert to string

    if (typeof dependency.value === "object") {
      dependency.value = minDate;
      utils.log.info(
        `Converted dependency.value to string for ${dependency.comparator} comparator.`
      );
    }
    dependency.value = utils
      .checkDateValidity(
        `dependency(${dependency.question.code}).value`,
        dependency.value,
        minDate
      )
      .format(config.defaults.DATE_STORAGE_FORMAT);
    return dependency.value;
  }
};
// #region Comparators
const handleComparatorEquality = (dependency) => {
  const answer = dayjs(dependency.question.answer);
  const value = dayjs(dependency.value);
  switch (dependency.comparator) {
    case "equals":
      return value.isSame(answer, "day");
    case "not":
      return !value.isSame(answer, "day");
    default:
      throw new Error(
        `handleComparatorEquality(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorPrepositions = (dependency) => {
  const answer = dayjs(dependency.question.answer);
  switch (dependency.comparator) {
    case "before":
      return answer.isBefore(dependency.value);
    case "after":
      return answer.isAfter(dependency.value);
    case "between":
      const isAfterMin =
        answer.isAfter(dependency.value.min) ||
        answer.isSame(dependency.value.min, "day");
      const isBeforeMax =
        answer.isBefore(dependency.value.max) ||
        answer.isSame(dependency.value.max, "day");
      return isAfterMin && isBeforeMax;
    default:
      throw new Error(
        `handleComparatorPrepositions(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorDaysOfMonth = (dependency) => {
  const answer = dayjs(dependency.question.answer);
  let result = false;
  switch (dependency.comparator) {
    case "first day of month":
      const dayOfMonth = answer.date();
      result = dayOfMonth === 1;
      return result;
    case "last day of month":
      return answer.date() === answer.endOf("month").date();
    default:
      throw new Error(
        `handleComparatorDaysOfMonth(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};

const handleComparatorLeapYear = (dependency) => {
  // TODO: 063023 Untested
  const answer = dayjs(dependency.question.answer);
  switch (dependency.comparator) {
    case "not leap year":
      return !answer.isLeapYear();
    case "leap year":
      return answer.isLeapYear();
    default:
      throw new Error(
        `handleComparatorLeapYear(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorDaysOfWeek = (dependency) => {
  const dayOfWeek = dayjs(dependency.question.answer).day();
  switch (dependency.comparator) {
    case "monday":
      return dayOfWeek === 1;
    case "tuesday":
      return dayOfWeek === 2;
    case "wednesday":
      return dayOfWeek === 3;
    case "thursday":
      return dayOfWeek === 4;
    case "friday":
      return dayOfWeek === 5;
    case "saturday":
      return dayOfWeek === 6;
    case "sunday":
      return dayOfWeek === 0;
    case "weekday":
      return dayOfWeek >= 1 && dayOfWeek <= 5;
    case "weekend":
      return dayOfWeek === 0 || dayOfWeek === 6;
    default:
      throw new Error(
        `handleComparatorDaysOfWeek(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
// #endregion Comparators

export const ComparatorBetween = ({ context, minDate, maxDate }) => {
  // PURPOSE: Component for between comparator
  // #region Initialize

  utils.log.component(
    `ComparatorBetween(minDate: ${minDate}, maxDate: ${maxDate})`,
    context
  );

  const dependency = dependencyUtil.get(context);
  if (typeof dependency.value !== "object") {
    dependency.value = initValueRangeObject();
    dependencyUtil.set(context, dependency);
  }
  const [valueMin, setValueMin] = useState(dependency.value.min);
  const [valueMax, setValueMax] = useState(dependency.value.max);
  // #endregion
  // #region Events
  const handleChange = (e, target) => {
    // NOTE: target = The question.property to update
    // Valid value
    const isoDate = dayjs(e.$d).format(config.defaults.DATE_STORAGE_FORMAT);
    dependency.value[target] = isoDate;
    // Adjust min/max
    const targetOpposite = target === "min" ? "max" : "min";
    const mode = target === "min" ? "up" : "down";
    dependency.value[targetOpposite] = utils.adjustDate(
      dayjs(dependency.value.min).format(config.defaults.DATE_STORAGE_FORMAT),
      dayjs(dependency.value.max).format(config.defaults.DATE_STORAGE_FORMAT),
      mode
    )[targetOpposite];

    // Update dependency.value
    dependencyUtil.set(context, dependency);
    setValueMin(dependency.value.min);
    setValueMax(dependency.value.max);
  };
  // #endregion
  return (
    <>
      <DatePicker
        renderInput={(props) => <Input style={{ width: "150px" }} {...props} />}
        label="Minimum"
        defaultValue={valueMin} /* value={value} */
        value={valueMin}
        onChange={(e) => handleChange(e, "min")}
        minDate={minDate}
        maxDate={maxDate}
      />{" "}
      <DatePicker
        renderInput={(props) => <Input style={{ width: "150px" }} {...props} />}
        label="Maximum"
        defaultValue={valueMax} /* value={value} */
        value={valueMax}
        onChange={(e) => handleChange(e, "max")}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
};

export const isCompleted = (question) => {
  if (!question) {
    return false;
  }

  const { answer, min, max } = question;

  if (answer == null) return false;

  const dayjsAnswer = dayjs(answer);

  if (min != null && dayjsAnswer.isBefore(min)) return false;
  if (max != null && dayjsAnswer.isAfter(max)) return false;

  return true;
};

export const getValue = (question) => {
  return {
    value: question.answer,
  };
};

// #endregion
/* 
FIXED
  051423 - Date picker not showing correct selectable date range sometimes
  STEPS: 
    1. Specify a date range.
    2. Click on Preview tab.
    3. Click on the date picker.
  EXPECTED:
    The date picker should allow selection of dates the same as the specified date range.
  ACTUAL:
    * The date picker allows selection of dates outside of the specified date range.
    * The issue occurs sometimes and not others.
  CAUSE:
    * When min/max date is retrieved, new Date(string) is converting the date to GMT.
  RESOLUTION:
    * Review later to see if we need to add time zone for recipient.
  FIX:
    * In Render, used Luxon to instantiate the date object for min/max.
*/
