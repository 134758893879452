import Grid from "../Grid";
import FormSelect from ".";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton } from "@mui/material";
import { useState } from "react";
import Input from "../Input/inputs";
import { useSnackbar } from "notistack";
import * as utils from "../../services/utilities";

export default function SimpleListEdit({ label, list, handleUpdate }) {
  utils.log.component(`SimpleListEdit(label: ${label})`, list);

  const { enqueueSnackbar } = useSnackbar();
  const [addOpen, setAddOpen] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [data, setData] = useState(list ?? []);
  const [selected, setSelected] = useState(data[0] ?? "");
  const handleChange = (event) => {
    const { name, value } = event.target;
    utils.assert(value !== null, "SimpleListEdit.handleChange() value is null");
    utils.log.info(`handleChange(name: ${name} value: ${value})`);
    setInputs((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleValue = (fieldName) => {
    return inputs == null ? "" : inputs[fieldName];
  };

  const handleAdd = (event) => {
    utils.log.info(`SimpleListEdit.handleAdd(${label})`, event);
    event.preventDefault();

    const mode = addOpen ? "submit" : "edit";
    switch (mode) {
      case "submit":
        utils.log.info("SUBMIT MODE");
        if(!inputs){
          return enqueueSnackbar("Please enter new unique Value", { variant: "error" });
        }
        const exists = list.find((c) => c.value === inputs.value) != null;
        if (!exists) {
          const newList = [...list, inputs.value];
          handleUpdate((prev) => ({ ...prev, categories: newList }));
          setInputs(null);
          setAddOpen(false);
          setData(newList);
          utils.log.info(`Added: ${inputs.value}`);
          utils.log.stateChange(
            `setData(newList: ${newList.join(", ")})`,
            newList
          );
          enqueueSnackbar("Added", { variant: "success" });
        } else {
          enqueueSnackbar(`${label} already exists`, { variant: "error" });
        }
        break;
      default:
        utils.log.info("EDIT MODE");
        setAddOpen(true);
        break;
    }
  };
  const handleRemove = (item,event) => {
    utils.log.info(`SimpleListEdit.handleRemove(${label})`, {item});

    if (item == null) {
      debugger;
      setAddOpen(false);
    } else {
      utils.log.info(`Removed: ${selected}`);
      const newList = list.filter((c) => c !== item);

      handleUpdate((prev) => ({ ...prev, categories: newList }));
      enqueueSnackbar("Removed", { variant: "success" });
      utils.log.stateChange(`setData(newList: ${newList.join(", ")})`, newList);
      setData(newList);
      utils.log.stateChange(`setSelected(${newList[0]})`);
      setSelected(newList[0]);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={9}>
          {addOpen && (
            <Input
              label="Enter new unique value"
              fullWidth
              name="value"
              onChange={handleChange}
              value={handleValue("name")}
            />
          )}
          {!addOpen && (
            <FormSelect
              label={label}
              data={data}
              fullWidth
              value={selected}
              debug
              onChange={(event) => {
                setSelected(event.target.value);
                utils.log.stateChange(`setSelected(${event.target.value})`);
              }}
              type="add-categories"
              onRemove={handleRemove}
              select={selected}
            ></FormSelect>
          )}
        </Grid>
        {
          addOpen &&  <Grid item>
          <IconButton sx={{borderRadius:'12px','&:hover': { backgroundColor: 'transparent'}}} onClick={handleAdd}>
            <DoneIcon style={{color:"#18d221"}}></DoneIcon> <span style={{color:"#18d221", fontSize:"18px"}}>Create</span>
          </IconButton>
          <IconButton sx={{borderRadius:'12px','&:hover': { backgroundColor: 'transparent'}}} onClick={()=>{setAddOpen(false)}}>
            <CloseIcon style={{color:"#d21b18"}}></CloseIcon><span style={{ fontSize:"18px"}}>Cancel</span>
          </IconButton>
        </Grid>
        }
        {!addOpen && <Grid item>
          <IconButton sx={{borderRadius:'12px','&:hover': { backgroundColor: 'transparent'}}}  onClick={()=>{setAddOpen(true)}}>
            <AddIcon color="primary"></AddIcon> <span style={{color:"#1876D1", fontSize:"18px"}}>Add Category</span>
          </IconButton>
        </Grid>}
      </Grid>
    </>
  );
}
