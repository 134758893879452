import { useContext, useState ,useEffect,useCallback} from "react";
import DialogBox from "../components/Dialog";
import { AuthContext } from "../services/context";
import * as utils from "../services/utilities";
import icon from "../assets/serverDown.png";
export const ServerDownModal = () => {
  const { employeeService } = useContext(AuthContext);
  const [serverDownModalOpen,setServerDownModalOpen] = useState(false);

const getServerStatusCode = useCallback(async () => {
  try {
    const response = await employeeService.getServerStatus();
    const responseData = await response.json();
    const buildInfo = responseData.find((item) => item.key === "Code:Build")
    if (!buildInfo) {
      setServerDownModalOpen(true);
    }
  } catch (error) {
    utils.log.error(error);
  }
}, [employeeService]); 


useEffect(() => {
  // Function to fetch server status initially
  getServerStatusCode();

  const interval = setInterval(() => {
    getServerStatusCode();
  }, 10 * 60 * 1000); 

  // Clean up the interval to prevent memory leaks
  return () => clearInterval(interval);
}, [getServerStatusCode]); 

   const serverDownImage = ( <img src={icon} height={"144px"} width={"144px"} style={{ marginBottom: "-6px" }} alt="Sever-down"  />)
  return (
    <DialogBox
      open={serverDownModalOpen}
      title={"We are unavailable at this moment."}
      body={<div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {serverDownImage}
        <p>Please try again after some time.</p>
      </div>}
      primaryButtonText={"Try Again"}
      secondaryButtonText={"Contact Support"}
      handlePrimaryButtonClick={() => getServerStatusCode()}
    />
  );
};
