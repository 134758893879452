import { useEffect, useState } from "react";
import * as utils from "../../services/utilities";
import { AppContext } from "../../App";
import { useContext } from "react";

const Viewer = ({ code }) => {
  utils.log.component("Viewer");
  const {helpSection} = useContext(AppContext);

  const [helpContent, setHelpContent] = useState(null);

  useEffect(() => {
    const currentCodeHelpContent = helpSection.find( (helpContent) =>{
        const title = helpContent?.title;
        const start = title.indexOf("[") + 1;
        const end = title.indexOf("]") - 1;
        const helpContentCode = title.substr(start,end);
        return(
          helpContentCode === code
        )
        
      }
    );
    setHelpContent(currentCodeHelpContent);
  }, [code]);

  function generateTitle(title) {
    return title ? title.slice(title.indexOf("]") + 1)?.trim() :"";
  }

  return (
    <div>
      <h4 style={{color:"#333",fontSize:"22px"}}>{generateTitle(helpContent?.title)}</h4>
      <p style={{color:"#555",fontSize:"16px"}} dangerouslySetInnerHTML={{ __html: helpContent?.message }}></p>
    </div>
  );
};


export default Viewer;