import * as utils from "../services/utilities";

const usePermissions = () => {
  
  const canAddCoordinator = (currentCoordinators,plan) => {
    utils.log.component("usePermission hook",plan);
    if (!plan) return false; 
    utils.log.debug(`Checking if coordinator limit (${currentCoordinators}/${plan.coordinatorMax}) is reached`);
    return currentCoordinators < plan.coordinatorMax;
  };

  const canAddQuestion = (currentQuestions,plan) => {
    if (!plan) return false; 
    utils.log.debug(`Checking if question limit (${currentQuestions}/${plan.questionMax}) is reached`);
    return currentQuestions < plan.questionMax;
  };

  
  const canAddSurvey = (currentSurveys,plan) => {
    if (!plan) return false; 
    utils.log.debug(`Checking if survey limit (${currentSurveys}/${plan.surveyMax}) is reached`);
    return currentSurveys < plan.surveyMax;
  };

  return {
    canAddCoordinator,
    canAddQuestion,
    canAddSurvey,
  };
};

export default usePermissions;
