import { useContext, useState } from "react";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";
import Input from "../../../components/Input/inputs";
import Cache from "../../../services/cache";
import { AuthContext } from "../../../services/context";
import * as utils from "../../../services/utilities";
import Recipient from "../detail";
const cache = new Cache();

export default function Find({ survey }) {
  utils.log.component(`Recipient.Find(surveyCode: ${survey.code})`, survey);
  // #region Assertions
  utils.assert(survey != null, "Survey required.  Unable to find recipient.");
  // #endregion
  // #region Initialize
  const { recipientService } = useContext(AuthContext);
  const [recipient, setRecipient] = useState("");
  //const [uid, setUid] = useState(null);

  //const searchFields = ["UID", "Occupation"];
  // #endregion
  // #region Events
  const handleFind = async () => {
    //setUid(cache.get("uid")); //setUid(inputs.uid);
    utils.log.stateChange(`Find.setUid(${cache.get("uid")})`);

    if (cache.get("uid") != null && survey.code != null && recipient == null) {
      const recipient = await recipientService.getByUid(
        cache.get("uid"),
        survey.code
      );
      setRecipient(recipient);
      cache.setDetails(recipient);
      utils.log.stateChange("Find.setRecipient()", { recipient, cache });
    }
  };
  // #endregion

  return (
    /*     <Dialog open={open} onClose={onClose}>
      <DialogTitle>Find Recipient</DialogTitle>
      <DialogContent>
        <DialogContentText>Find recipient instructions</DialogContentText> */

    <Grid container spacing={2}>
      {/*           <Grid item xs={12}>
            <WorksiteSelector
              worksiteCode={worksiteCode}
              setWorksiteCode={setWorksiteCode}
            />
          </Grid> */}

      <Grid item xs={12} sm={10} className="reactTour30Step">
        <Input
          label="UID"
          fullWidth
          name="uid"
          onChange={(e) => cache.set(e)}
          defaultValue={() => cache.get("uid")}
        ></Input>
      </Grid>
      <Grid item xs={12} sm={2} container  justifyContent={"flex-end"}>
        <Button onClick={handleFind} style={{ padding: "16.5px 40px",marginBottom:"20px" }}>
          Find
        </Button>
      </Grid>
      {recipient != null && (
        // <Grid item container spacing={2}>
        <div style={{paddingLeft:"16px"}}>
          <Recipient
            surveyCode={survey.code}
            uid={cache.get("uid")}
            recipient={recipient}
          ></Recipient>
        {/* </Grid> */}
        </div>
      )}
    </Grid>
    /*         <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog> */
  );
}
