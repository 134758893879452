// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker as DatePickerAntD } from "antd";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid } from "@mui/material";
import { QuestionHeader } from "../recipients/question";
import * as config from "../../services/config";
import { valueConvertByComparator, ComparatorBetween } from "./dateInput";
import * as utils from "../../services/utilities";
import {
  updateQuestionAnswer,
  initAnswerObject,
} from "../../services/question";
import * as dependencyUtil from "./dependency";
import FormUtility from "../../services/form";
import useMediaQuery from "../../hooks/useMediaQuery";
const formUtility = new FormUtility();

export const comparators = [
  "equals",
  "not",
  "before",
  "after",
  "between",
  "weekday",
  "weekend",
  //"length", // TODO: 070623 Implement length comparator
];
export const comparatorIsValue = ["weekday", "weekend"];
export function New() {
  return {
    type: "DateInputRange",
    code: null,
    title: "New Date Input (Range) Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: initAnswerObject(),
    min: dayjs(),
    max: dayjs().add(1, "day"),
  };
}
export function Reset(question) {
  // PURPOSE: Reset user answers specific to this question type.
  utils.assert(question != null, "question is null.");
  utils.log.component(`DateInputRange.Reset(${question.code})`, question);
  question.answer = initAnswerObject();
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  utils.log.component(`DateInputRange.Edit(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  // #region Initialize
  formUtility.setDetail(question);

  const disablePastDates = (date) => dayjs(date).isBefore(question.min, "day");

  question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  );

  const disableFutureDates = (date) => dayjs(date).isAfter(question.max, "day");

  question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  );

  /*   // Adjust min/max
  question.min = utils.adjustDate(question.max, question.min, "up").min;
  question.max = utils.adjustDate(question.min, question.max, "down").max; */
  // #endregion
  // #region Events
  const handleChange = (e, target) => {
    // NOTE: target = The question.property to update
    // Valid value
    const isoDate = dayjs(e.$d).format(config.defaults.DATE_STORAGE_FORMAT);
    question[target] = isoDate; //value.toLocaleDateString();
    // Adjust min/max
    const targetOpposite = target === "min" ? "max" : "min";
    const mode = target === "min" ? "up" : "down";
    question[targetOpposite] = utils.adjustDate(
      dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
      dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
      mode
    )[targetOpposite];

    // Update question
    const event = {
      target: { name: target, value: isoDate },
    };
    formUtility.handleChange(event, setInputs);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography id="non-linear-slider" gutterBottom style={{fontFamily:'Public-sans'}}>
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.min}
            label="Minimum"
            onChange={(e) => handleChange(e, "min")}
            shouldDisableDate={disableFutureDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.max}
            label="Maximum"
            onChange={(e) => handleChange(e, "max")}
            shouldDisableDate={disablePastDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
//export const comparators = ["equals", "contains", "startsWith", "endsWith"];
export const DependencySelector = ({
  context,
  questions,
  setDependency,
  selectedQuestion,
  formUtility,
  inputs,
  setInputs,
}) => {
  // !NOTE: useState() is prohibited in this component.
  utils.log.section("DependencySelector(DateInputRange)");
  // #region Initialize
  const dependency = dependencyUtil.get(context);
  const question = dependency.question;
  const list = utils.toSelectItem(comparators);
  const comparator = dependency?.comparator ?? list[0].value;

  if (dependency == null || dependency.value == null) {
    // Add a value if it doesn't exist
    dependency.comparator = list[0].value;
    dependency.value = null;
  }
  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  /*   const [value, setValue] = useState(
    valueConvertByComparator(dependency, minDate, maxDate)
  ); */
  const value = valueConvertByComparator(dependency, minDate, maxDate);
  // #endregion
  // #region Events
  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency.comparator = e.target.value;
    dependencyUtil.set(context, dependency);
    utils.log.event(
      `DateInputRange.DependencySelector(${context.question.code}).handleComparatorChange(${dependency.comparator})`
    );
    setInputs({ ...inputs, comparator: e.target.value }); // !KLUDGE - Force re-render
  };
  const handleValueChange = (e) => {
    const isoDate = e.format(config.defaults.DATE_STORAGE_FORMAT);
    const dependency = dependencyUtil.get(context);
    dependency.value = isoDate;
    dependencyUtil.set(context, dependency);
    //setValue(isoDate);
    setInputs({ ...inputs, value: isoDate }); // !KLUDGE - Force re-render
  };
  // #endregion
  return (
    <>
      <FormSelect
        id="dateInputRange-comparators"
        label="Comparators"
        data={list}
        defaultValue={comparator}
        format={config.defaults.DATE_DISPLAY_FORMAT}
        style={{
          width: utils.getTextBoxWidth(comparator.length),
        }}
        onChange={handleComparatorChange}
      ></FormSelect>{" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {dependency.comparator === "between" && (
          <ComparatorBetween
            context={context}
            minDate={minDate}
            maxDate={maxDate}
          ></ComparatorBetween>
        )}
        {dependency.comparator !== "between" && (
          // TODO: 070223 - REVIEW for issues with value.  ASSERT typeof value === string
          <DatePicker
            renderInput={(props) => (
              <Input style={{ width: "150px" }} {...props} />
            )}
            label="Value"
            /*             style={{
              width: utils.getTextBoxWidth(value.length),
              height: "56px",
            }} */
            defaultValue={value}
            value={value}
            disabled={comparatorIsValue.includes(dependency.comparator)}
            onChange={(e) => handleValueChange(e)}
            minDate={minDate}
            maxDate={maxDate}
            /*             style={{
              width: {
                textBoxWidthFormatted: utils.getTextBoxWidth(value.length),
              },
            }} */
          />
        )}
      </LocalizationProvider>
    </>
  );
};
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("DateInputRange.Render()", props);
  // #region Initialize
  const question = props.question;
  utils.assert(question != null, "question is null.");
  const preview = props.preview ?? false;
  const displayDateFormat = config.defaults.DATE_DISPLAY_FORMAT;
  question.answer ??= initAnswerObject();
  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  const answer = question.answer;
  /* let answer =
    question.answer == null
      ? null
      : checkDate(
          `dependency(${question.code}).value`,
          question.answer,
          minDate
        ); */
  const helperText =
    minDate === maxDate
      ? `You can only enter the following date: ${dayjs(question.min).format(
          displayDateFormat
        )}.`
      : `Enter dates between ${dayjs(question.min).format(
          displayDateFormat
        )} and ${dayjs(question.max).format(displayDateFormat)}.`;

  // #endregion
  // #region Events
  const handleChange = (e) => {
    if(!e)return;

    // NOTE: target = The question.property to update
    const start = dayjs(e[0].$d)?.format(config.defaults.DATE_STORAGE_FORMAT);
    const end = dayjs(e[1].$d)?.format(config.defaults.DATE_STORAGE_FORMAT);
    /*     question.answer[target] = dayjs(e.$d).format(
      config.defaults.DATE_STORAGE_FORMAT
    ); */
    question.answer.start = start;
    question.answer.end = end;
    /*     utils.log.event(
      `handleChange(e): question.answer[${target}] = ${answer[target]}`
    ); */
    utils.log.event(
      `handleChange(e): question.answer(start: ${start}, end: ${end}})`
    );
    updateQuestionAnswer(question, answer, preview, props.setQuestions);
  };
  const isQuestionCompleted  = isCompleted(question);

  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();


  // #endregion

  return (
    <>
   <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
        <QuestionHeader question={question}></QuestionHeader>
        <p>{helperText}</p>
        <RenderPicker
          question={question}
          minDate={minDate}
          maxDate={maxDate}
          handleChange={handleChange}
        ></RenderPicker>
      </div>
    </div>
    </>
  );
}

const RenderPicker = ({ question, minDate, maxDate, handleChange }) => {
  // TODO: 072023 Review to see if this can be refactored similar to TimeInputRange.PickerRange for consistency.
  // PURPOSE: Presents a date picker range with dates disabled that is outside of the question min/max.
  // #region Initialize
  const { RangePicker } = DatePickerAntD;
  const displayDateFormat = config.defaults.DATE_DISPLAY_FORMAT;
  //const answer = question.answer;
  const disabledDates = (currentDate) => {
    // PURPOSE: Return an array of disabled dates.
    const min = dayjs(minDate);
    const max = dayjs(maxDate);
    // Disable dates that are outside the desired range
    return currentDate.isBefore(min, "day") || currentDate.isAfter(max, "day");
  };

  const {isSmDown} = useMediaQuery();
  // #endregion
  // #region Events
  // #endregion
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RangePicker
        disabledTime
        hideDisabledOptions
        format={displayDateFormat}
        //value={[answer.start ?? null, answer.end ?? null]}
        onChange={handleChange}
        disabledDate={disabledDates}
        defaultPickerValue	= {[dayjs(minDate),dayjs(maxDate)]}
        getPopupContainer={(triggerNode) => {
          // PURPOSE:  Used to position time picker relative to the question.
          utils.log.disabled(
            `dateInputRange.js - Render - getPopupContainer(${triggerNode})`,
            triggerNode.parentNode
          );
          return triggerNode.parentNode;
        }}
        style={{ width: isSmDown ? "100%":"300px",height:"56px",background:"#F8F8F8"}}
      />
    </LocalizationProvider>
  );
};
// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  //debugger;
  if (dependency == null) {
    utils.log.info(`DateInputRange.isAnswered(false): dependency is null`);
    return false;
  }
  // *** Evaluate ***
  switch (dependency.comparator) {
    case "equals":
    case "not":
      return handleComparatorEquality(dependency);
    case "before":
    case "after":
    case "between":
      return handleComparatorPrepositions(dependency);
    case "weekday":
    case "weekend":
      return handleComparatorDaysOfWeek(dependency);
    case "length":
      return handleComparatorLength(dependency);

    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
// #region Comparators
const handleComparatorEquality = (dependency) => {
  const start = dayjs(dependency.question.answer.start);
  const end = dayjs(dependency.question.answer.end);
  const value = dayjs(dependency.value);

  const resultStartIsSame = value.isSame(start, "day");
  const resultEndIsSame = value.isSame(end, "day");
  switch (dependency.comparator) {
    case "equals":
      return resultStartIsSame && resultEndIsSame;
    case "not":
      return !(resultStartIsSame && resultEndIsSame);
    default:
      throw new Error(
        `handleComparatorEquality(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorPrepositions = (dependency) => {
  if (
    dependency.question.answer.start === undefined ||
    dependency.question.answer.end === undefined
  )
    return false;
  const start = dayjs(dependency.question.answer.start);
  const end = dayjs(dependency.question.answer.end);

  const valueMin = dayjs(dependency.value.min);
  const valueMax = dayjs(dependency.value.max);

  switch (dependency.comparator) {
    case "before":
      const resultBefore = start.isBefore(valueMin) && end.isBefore(valueMax);
      return resultBefore;
    case "after":
      const resultAfter = start.isAfter(valueMin) && end.isAfter(valueMax);
      return resultAfter;
    case "between":
      const isAfterMin =
        start.isAfter(valueMin) || start.isSame(valueMin, "day");
      const isBeforeMax =
        start.isBefore(valueMax) || start.isSame(valueMax, "day");
      const resultBetween = isAfterMin && isBeforeMax;
      return resultBetween;
    default:
      throw new Error(
        `handleComparatorPrepositions(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorDaysOfWeek = (dependency) => {
  if (
    dependency.question.answer.start === undefined ||
    dependency.question.answer.end === undefined
  )
    return false;
  const dayOfWeekStart = dayjs(dependency.question.answer.end).day();
  const dayOfWeekEnd = dayjs(dependency.question.answer.end).day();
  switch (dependency.comparator) {
    case "weekday":
      return (
        dayOfWeekStart >= 1 &&
        dayOfWeekStart <= 5 &&
        dayOfWeekEnd >= 1 &&
        dayOfWeekEnd <= 5
      );
    case "weekend":
      return (
        (dayOfWeekStart === 0 || dayOfWeekStart === 6) &&
        (dayOfWeekEnd === 0 || dayOfWeekEnd === 6)
      );
    default:
      throw new Error(
        `handleComparatorDaysOfWeek(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorLength = (dependency) => {
  debugger;
  const answer = dependency.question.answer;
  const min = dependency.value.min;
  const max = dependency.value.max;
  const length = answer.end.diff(answer.start, "days");
  return length >= min && length <= max;
};

export const isCompleted = (question) => {
  if (!question) {
    return false;
  }

  if (!question?.answer?.start || !question?.answer?.end) return false;

  const {
    answer: { start, end },
    min,
    max,
  } = question;

  const dayjsStart = dayjs(start);
  const dayjsEnd = dayjs(end);

  if (dayjsStart.isBefore(min) || dayjsEnd.isAfter(max)) return false;

  return true;
};

export const getValue = (question) => {
  return {
    start: question.answer.start,
    end: question.answer.end,
  };
};

// #endregion
// #endregion
