// BUG: 061423 Clicking on Preview does not show the updates to questions unless exiting survey and re-entering.  Need to update survey list with current survey details.
// TODO: Question validator component - Check question to make sure everything is okay by question type.
// TODO: Implement question drag and drop ordering in questions list using React Table (Tan Stack).
// TODO: In Question Detail, consider moving question type specific items to its own tab.
// TODO: 050723 When adding/updating question, prevent duplicate code from being entered.
// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES
*/

/*eslint-disable*/
import { Grid } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useSnackbar } from "notistack";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input/inputs";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { typeLookup } from "../../services/config";
import { AuthContext } from "../../services/context";
import Debug from "../../services/debug";
import FormUtility from "../../services/form";
import * as utils from "../../services/utilities";
import { API_KEY_TINY_MCE } from "../../utils/env";
import { SurveyContext } from "../survey";
import Choices from "./choices";
import { Context, Selector as DependencySelector } from "./dependencySelector";
import QuestionTypeSelector from "./questionTypeSelector";
import { HeaderActions } from "../../components/HeaderActions";
import MUIDialog from "../../components/Modal";
import usePermissions from "../../hooks/usePermission";
import useMediaQuery from "../../hooks/useMediaQuery";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";

const formUtility = new FormUtility();

export const QuestionContext = createContext();

export const Questions = ({
  questions,
  setQuestions,
  setIsCommandBarVisible,
}) => {
  utils.log.component(":: Survey.Questions()");
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  const { selectedSurvey, setSelectedSurvey } = useContext(SurveyContext);
  const { surveyService, cache, employee } = useContext(AuthContext);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [originalQuestion, setOriginalQuestion] = useState(null);
  const { canAddQuestion } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  // const isQuestionLimitReached = !canAddQuestion(
  //   questions?.length,
  //   employee?.plan
  // );

  const columns = [
    { Header: "Code", accessor: "code" },
    { Header: "Title", accessor: "title" },
    { Header: "Description", accessor: "description" },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ cell: value }) => {
        return value.value !== undefined
          ? surveyService.typeLookup(value.value).label
          : "- undefined -";
      },
    },
  ];

  const questionContext = useMemo(
    () => ({
      questions,
      setQuestions,
      selectedQuestion,
      setSelectedQuestion,
      originalQuestion,
    }),
    [
      questions,
      setQuestions,
      selectedQuestion,
      setSelectedQuestion,
      originalQuestion,
    ]
  );

  const handleAddOpen = () => {
    setIsAddModalOpen(true);
  };
  const handleEdit = (question) => {
    setSelectedQuestion(question);
    setDetailOpen(true);
    setOriginalQuestion(question);
  };
  const handleCancel = () => {
    setIsAddModalOpen(false);
    setDetailOpen(false);
    setOriginalQuestion(null);
    setSelectedQuestion(null);
  };

  const handleUpdateQuestion = async (selectedQuestion) => {
    utils.log.info("::handleUpdateQuestion");
    setIsAddModalOpen(false);
    setDetailOpen(false);

    const updatedQuestions = questions.map((question) => {
      return question.code === originalQuestion.code
        ? selectedQuestion
        : question;
    });

    setQuestions(updatedQuestions);
    cache.set("questions", updatedQuestions);

    // let stagedSurvey = {
    //   ...selectedSurvey,
    //   questions: [...updatedQuestions],
    // };
    // await surveyService.surveyPrepare(stagedSurvey);
    // setSelectedSurvey(stagedSurvey);
  };

  const handleQuestionDelete = (question) => {
    if (question == null || typeof question != "object") {
      return enqueueSnackbar("Please select Questions", { variant: "error" });
    }
    const updatedQuestions = questions.filter(
      (question) => question?.code !== originalQuestion?.code
    );
    setQuestions(updatedQuestions);
    cache.set("questions", updatedQuestions);
    setDetailOpen(false);
    setSelectedQuestion(null);
    setOriginalQuestion(null);
  };

  const handleDragEnd = (rows) => {
    utils.log.info(":: Questions.handleDragEnd()");
    setSelectedSurvey((prev) => {
      return {
        ...prev,
        questions: rows.map((row) => row.original),
      };
    });
    setQuestions(rows.map((row) => row.original));
  };

  const headerAction = (
    <HeaderActions
      buttonTitle={"Add"}
      onAdd={handleAddOpen}
      disabled={false}
      // tooltipTitle={
      //   isQuestionLimitReached ? "maximum-question limit is reached" : ""
      // }
      debug
      debugValue={questions}
    />
  );
  return (
    <QuestionContext.Provider value={questionContext}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={questions?.length ? [...questions] : []}
            selected
            isDraggable={true}
            onSelected={(row) => handleEdit(row.original)}
            onDragEnd={handleDragEnd}
            headerAction={headerAction}
          ></Table>
        </Grid>
      </Grid>

      {isAddModalOpen && (
        <Add open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
      )}
      {detailOpen && (
        <Overview
          open={detailOpen}
          onUpdate={handleUpdateQuestion}
          onCancel={handleCancel}
          handleQuestionDelete={handleQuestionDelete}
        ></Overview>
      )}
    </QuestionContext.Provider>
  );
};

const Overview = ({ open, onUpdate, onCancel, handleQuestionDelete }) => {
  utils.log.component(":: Survey.Questions.Overview()");

  const {
    selectedQuestion,
    questions,
    setQuestions,
    setSelectedQuestion,
    originalQuestion,
  } = useContext(QuestionContext);

  const { setSelectedSurvey } = useContext(SurveyContext);
  const { surveyService, error } = useContext(AuthContext);
  /*eslint-disable-next-line*/
  const [inputs, setInputs] = useState({});
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [questionType, setQuestionType] = useState(
    surveyService.typeLookup(selectedQuestion.type)
  );
  const { isSmDown, isMdDown } = useMediaQuery();

  utils.assert(
    questionType != null,
    `Question type(${questionType}) not found.`
  );

  const isValid = () => {
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };

  const handleSetQuestionType = (type) => {
    // utils.log.info(":: selected Question in handleSetQuestionType",type);
    const newQuestionType = surveyService.typeLookup(type);
    // utils.log.info(":: selected Question in handleSetQuestionType newQuestionType" ,newQuestionType);
    const newQuestion = newQuestionType.new();
    setQuestionType(newQuestionType);

    // Update both selectedQuestions and All Questions

    const allQuestions = [...questions];
    // utils.log.info(":: selected Question in handleSetQuestionType allQuestions",allQuestions);
    const questionIndex = allQuestions?.findIndex(
      (question) => question?.code === originalQuestion?.code
    );
    // utils.log.info(":: selected Question in handleSetQuestionType index",questionIndex);

    const commonProperties = Object.keys(newQuestion)
      ?.filter((property) => property !== "type")
      ?.filter((property) =>
        questions[questionIndex]?.hasOwnProperty(property)
      );
    // utils.log.info(":: selected Question in handleSetQuestionType commonProperties",commonProperties);

    commonProperties.forEach((property) => {
      newQuestion[property] = questions[questionIndex][property];
    });
    // questions[questionIndex] = newQuestion;
    const updatedQuestions = questions?.map((question, index) => {
      if (index === questionIndex) {
        return newQuestion;
      } else {
        return question;
      }
    });
    // utils.log.info(":: selected Question in handleSetQuestionType updatedQuestions",updatedQuestions);

    setSelectedQuestion(newQuestion);
    setQuestions(updatedQuestions);
    // setQuestions((prevQuestions) => {
    //   // const questions = [...prevQuestions];
    //   // const index = questions.findIndex(
    //   //   (q) => q.code === originalQuestion.code
    //   // );
    //   // Copy common properties from old question to new question.
    //   const commonProperties = Object.keys(newQuestion)
    //     .filter((property) => property !== "type") // Exclude type property.
    //     .filter((property) => questions[index].hasOwnProperty(property));
    //   commonProperties.forEach((property) => {
    //     newQuestion[property] = questions[index][property];
    //   });
    //   //newQuestionType.reset(newQuestion);
    //   questions[index] = newQuestion;
    //   setSelectedQuestion(questions[index]);
    //   return questions;
    // });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (isValid()) {
      onUpdate({ ...selectedQuestion, type: questionType.type });
    } else {
      utils.log.error("Question handleUpdate(invalid)", error.getErrors());
    }
  };

  const updateQuestionTitle = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Update Question<Debug value={selectedQuestion}></Debug>
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeSelector
            selectedQuestionType={selectedQuestion.type}
            handleSetQuestionType={handleSetQuestionType}
          ></QuestionTypeSelector>
        </Grid>
      </Grid>
    </>
  );

  const updateQuestionActionButtons = (
    <>
      {isSmDown ? (
        <Button
          style={{
            backgroundColor: "red",
            padding: "9px 12px",
            fontSize: "12px",
            borderRadius: "8px",
          }}
          onClick={() => handleQuestionDelete(selectedQuestion)}
          tabIndex={-1}
        >
          Delete
        </Button>
      ) : (
        <div
          onClick={() => handleQuestionDelete(selectedQuestion)}
          style={{ cursor: "pointer" }}
        >
          <DeleteOutlineTwoToneIcon
            sx={{ marginTop: "0px", marginLeft: "-9px" }}
            color="red"
            htmlColor="red"
            fontSize="medium"
          />
        </div>
      )}
      {/* <Button
        variant="contained-white"
        style={{
          border: isSmDown ? "1px solid red" : "none",
          borderRadius: "8px",
          color: "red",
          padding: isSmDown ? "8px 25px" : "0px 10px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        onClick={onCancel}
        tabIndex={-1}
      >
        CANCEL
      </Button> */}
      <Button
        style={{
          padding: isMdDown ? "9px 12px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
          borderRadius: "8px",
        }}
        onClick={handleUpdate}
      >
        UPDATE
      </Button>
    </>
  );

  const choicesTableDragEnd = (updatedChoices) =>
    setSelectedSurvey((prev) => {
      const survey = { ...prev };
      const question = survey.questions.find(
        (q) => q.code === selectedQuestion.code
      );
      question.choices = updatedChoices;
      return survey;
    });
  return (
    <>
      <MUIDialog
        onClose={onCancel}
        open={open}
        fullWidth
        maxWidth="md"
        title={updateQuestionTitle}
        actions={updateQuestionActionButtons}
        height={isMdDown ? "800px" : isSmDown ? "600px" : "800px"}
        onSubmit={onUpdate}
      >
        <Tabs>
          <Tab
            label="Details"
            component={
              <Details
                questionType={questionType}
                setInputs={setInputs}
                codeErrorObject={codeErrorObject}
                setCodeErrorObject={setCodeErrorObject}
                error={error}
                originalQuestion={originalQuestion}
              />
            }
          />
          <Tab
            label="Instructions"
            component={
              <Instructions
                question={selectedQuestion}
                setQuestion={setSelectedQuestion}
                codeErrorObject={codeErrorObject}
              />
            }
          />
          {questionType.showChoicesTab ? (
            <Tab
              label="Choices"
              component={
                <Choices
                  question={selectedQuestion}
                  setQuestion={setSelectedQuestion}
                  questions={questions}
                  onDragEnd={choicesTableDragEnd}
                />
              }
            />
          ) : (
            <Tab hidden></Tab>
          )}
          <Tab
            label="Preview"
            component={questionType?.render({
              question: selectedQuestion,
              questions: questions,
              setQuestions: setSelectedQuestion,
              preview: true,
            })}
          />
        </Tabs>
      </MUIDialog>
    </>
  );
};

const Details = ({
  questionType,
  setInputs,
  codeErrorObject,
  setCodeErrorObject,
  error,
  originalQuestion
}) => {
  utils.log.component(":: Suvrey.Questions.Details()");

  const {
    selectedQuestion,
    setSelectedQuestion,
    questions: allQuestions,
  } = useContext(QuestionContext);

  const { enqueueSnackbar } = useSnackbar();
  const context = Context(selectedQuestion);

  const handleChange = (e) => {
    setSelectedQuestion((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
     const questionsExcludingOriginal = allQuestions?.filter(
      (question) => question?.code !== originalQuestion?.code
    );
    const duplicateCode = questionsExcludingOriginal?.find(
      (question) => question?.code?.toLowerCase() === selectedQuestion?.code?.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateCode) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name, value } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    setSelectedQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <Input
          label="Code"
          fullWidth
          name="code"
          required
          onChange={handleInputChange}
          defaultValue={selectedQuestion.code}
          value={selectedQuestion?.code}
          error={hasError("code")}
          helperText={getHelperTextMessage("code")}
          onBlur={handleInputBlur}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Input
          label="Title"
          fullWidth
          name="title"
          required="true"
          onChange={handleInputChange}
          defaultValue={selectedQuestion.title}
          value={selectedQuestion?.title}
          error={hasError("title")}
          helperText={getHelperTextMessage("title")}
          onBlur={handleInputBlur}
          
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Description"
          fullWidth
          multiline
          rows={5}
          name="description"
          onChange={handleChange}
          defaultValue={selectedQuestion.description}
          style={{ verticalAlign: "top" }}
          InputProps={{
            style: { height: "130px", verticalAlign: "top" },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {questionType?.edit({
          question: selectedQuestion,
          setInputs: setInputs,
          enqueueSnackbar: enqueueSnackbar,
          setQuestion: setSelectedQuestion,
        })}
      </Grid>
      <Grid item xs={12} width="100%">
        <DependencySelector
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          context={context}
          questions={allQuestions}
        ></DependencySelector>
      </Grid>
    </Grid>
  );
};
const Instructions = ({ question, setQuestion, codeErrorObject }) => {
  // #region Initialize
  utils.log.component(":: Survey.Questions.Instructions() component", question);
  const { cache } = useContext(AuthContext);
  const editorRef = useRef(null);
  //const [editMode, setEditMode] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Recipient Instructions
        <Editor
          // TODO: 051323 Complete implementation of image insert
          apiKey={API_KEY_TINY_MCE}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={question?.instructions} //initialValue={cache.get("instructions")}
          onEditorChange={(content, editor) => {
            //cache.set("instructions", content);
            const event = {
              target: { name: "instructions", value: content },
            };
            setQuestion((prev) => ({
              ...prev,
              [event.target.name]:event.target.value
            }))
            /* formUtility.handleChange(event, setInputs); */
            // cache.set(event);
          }}
          /* value={formUtility.getValue("instructions")} */
          error={codeErrorObject?.state}
          helperText={codeErrorObject?.message}
          init={{
            height: "430",
            menubar: false,
            branding: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount textcolor",
            ],
            toolbar:
              "undo redo | formatselect | image | " +
              "bold italic | forecolor backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Public-sans,sans-serif; font-size:14px }",
          }}
        />
      </Grid>
    </Grid>
  );
};

const Add = ({ onClose, open }) => {
  utils.log.component(":: Survey.Questions.Add()");

  const { selectedSurvey, setSelectedSurvey } = useContext(SurveyContext);
  const { questions: allQuestions, setQuestions: setAllQuestions } =
    useContext(QuestionContext);
  const { surveyService, error, cache } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isSmDown, isMdDown } = useMediaQuery();

  const [inputs, setInputs] = useState({});
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const allQuestionTypes = surveyService.getQuestionTypes();
  const [question, setQuestion] = useState(allQuestionTypes?.at(0)?.new());

  const selectedQuestionType = useMemo(
    () => allQuestionTypes.find((q) => q.type === question.type),
    [question.type]
  );

  formUtility.setDetail(null);
  formUtility.setInputs(inputs);

  const handleSetQuestionType = (type) => {
    const questionType = surveyService.typeLookup(type);

    const questionNew = questionType.new(); // Reset fields
    setQuestion({
      ...questionNew,
      type: questionType.type,
    });
  };

  const isValid = () => {
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };
  const checkCategoryChoiceQnValidation = () => {
    if (
      question?.type === "CategoryChoice" &&
      question?.categories.length === 0
    ) {
      enqueueSnackbar("Category is required", { variant: "error" });
      return false;
    }
    return true;
  };
  const checkNumericInputRange = () => {
    if (question?.type === "NumericInputRange") {
      if (
        question?.startMin > question?.startMax ||
        question?.endMin > question?.endMax
      ) {
        enqueueSnackbar("Minimum should be less than Maximum", {
          variant: "error",
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      isValid() &&
      checkCategoryChoiceQnValidation() &&
      checkNumericInputRange()
    ) {
      try {
        const stagedQuestion = {
          ...question,
          code: formUtility.getValue("code"),
          title: formUtility.getValue("title"),
          description: formUtility.getValue("description"),
          instructions: formUtility.getValue("instructions"),
        };
        setAllQuestions((prev) => [...prev, stagedQuestion]);
        cache.set("questions", [...allQuestions, stagedQuestion]);
        let stagedSurvey = {
          ...selectedSurvey,
          questions: [...allQuestions, stagedQuestion],
        };
        await surveyService.surveyPrepare(stagedSurvey);
        setSelectedSurvey(stagedSurvey);
        onClose();
      } catch (error) {
        utils.log.error("Survey.Questions.Add()", error);
      }
    } else {
      utils.log.error(error.getErrors());
    }
  };

  const addQuestionTitle = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Add question
        </Grid>
        <Grid item xs={12}>
          <QuestionTypeSelector
            selectedQuestionType={question.type}
            handleSetQuestionType={handleSetQuestionType}
            /* setInputs={setInputs} */
          ></QuestionTypeSelector>
        </Grid>
      </Grid>
    </>
  );

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const duplicateCode = allQuestions?.find(
      (question) => question?.code?.toLowerCase() === value?.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateCode) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    formUtility.handleChange(e, setInputs);
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };

  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        title={addQuestionTitle}
        description={"Add question title,instructions,descriptions..."}
        actions={
          <>
            {/* <Button
              variant="contained-white"
              style={{
                border: isSmDown ? "1px solid red" : "none",
                borderRadius: "8px",
                color: "red",
                padding: isSmDown ? "8px 25px" : "0px 10px",
                fontSize: isMdDown ? "12px" : "14px",
              }}
              onClick={onClose}
            >
              CANCEL
            </Button> */}
            <Button
              style={{
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
                borderRadius: "8px",
              }}
              type="submit"
            >
              ADD
            </Button>
          </>
        }
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} padding={"10px 0px"}>
          <Grid item xs={12} md={2}>
            <Input
              label="Code"
              fullWidth
              name="code"
              onChange={handleInputChange}
              defaultValue={formUtility.getValue("code")}
              value={formUtility.getValue("code")}
              error={hasError("code")}
              helperText={getHelperTextMessage("code")}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Input
              label="Title"
              fullWidth
              name="title"
              required="true"
              onChange={handleInputChange}
              defaultValue={formUtility.getValue("title")}
              value={formUtility.getValue("title")}
              error={hasError("title")}
              helperText={getHelperTextMessage("title")}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Description"
              fullWidth
              name="description"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Instructions"
              fullWidth
              name="instructions"
              onChange={(e) => formUtility.handleChange(e, setInputs)}
              value={formUtility.getValue("instructions")}
            />
          </Grid>
          <Grid item container xs={12}>
            {selectedQuestionType?.edit({
              question: question,
              setInputs: setInputs,
              enqueueSnackbar: enqueueSnackbar,
              setQuestion,
            })}
          </Grid>
        </Grid>
      </MUIDialog>
    </>
  );
};

export function isQuestionType(unknown) {
  // PURPOSE: Check if type is a question type

  if ("type" in unknown && typeLookup(unknown.type) != null) return true;
  return false;
}

/*
FIXED
051223 - Update(button) not saving when clicked.
  STEPS:
    1. Open survey.
    2. Click on Questions tab.
    3. Click on a question.
    4. Change the title.
    5. Click on Update button.
    6. Click on the same question.
  EXPECTED:
    1. The title should be the updated value.
  CAUSE:
    1. The handleUpdate method passed from Questions to Overview component was in the format (e) => handleUpdate(e,original)
      which means wait until value are present.  What we want is to the function to Overview for it to set the original when validating.
    2. There state variable original was set to null in Questions and should not have been there.    3. 
  RESOLUTION:
    1. Deficit in difference between (e) => function and function(e) => function in Javascript.
  FIX:
    1. In Questions, changed Overview.onUpdate event from (e) => handleUpdate(e) to handleUpdate.
*/
