// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
// BUG(LOW): 072323 - Rule of Hooks error when changing existing question type from something to CategoryChoice.  No real consequences so far.

import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
} from "@mui/material";
import FormSelect from "../../components/Select";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import SimpleListEdit from "../../components/Select/SimpleListEdit";
import { ChoiceSelector } from "./dependencySelector";
import * as utils from "../../services/utilities";
// import useMediaQuery from "../../hooks/useMediaQuery";

export function New() {
  utils.log.component("CategoryChoice.New()");
  return {
    type: "CategoryChoice",
    code: null,
    title: "New Category Choice Question",
    description: "",
    instructions: "",
    dependency: null,
    choices: [],
    categories: [],
    answer: [],
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  utils.log.component("Reset(CategoryChoice):", question);
  question.answer = generateAnswer(question, true).answer;
  assertCorrectAnswerLength(question);
}
export function Edit({ question, enqueueSnackbar, setQuestion }) {
  //formUtility.setDetail(question); // TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
  //assertCorrectAnswerLength(question);
  // #endregion
  // #region Events
  // #endregion
  return (
    <SimpleListEdit
      label="Category"
      list={question.categories}
      handleUpdate={setQuestion}
      enqueueSnackbar={enqueueSnackbar}
    ></SimpleListEdit>
  );
}
export const DependencySelector = ({ context, selectedQuestion }) => {
  // !NOTE: useState() is prohibited in this component.
  utils.log.component(
    `DependencySelector(CategoryChoice): selectedQuestion: ${selectedQuestion.code}`,
    { context, selectedQuestion }
  );
  // #region Assertions
  // #endregion Assertions
  // #region Initialize

  // #endregion Initialize
  // #region Events
  // #endregion Events
  return (
    <>
      <ChoiceSelector
        context={context}
        selectedQuestion={selectedQuestion}
      ></ChoiceSelector>{" "}
      <CategorySelector
        context={context}
        selectedQuestion={selectedQuestion}
      ></CategorySelector>
    </>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("CategoryChoice.Render()", props);
  // #region Assertions
  utils.assert(props != null, "props is null.");
  utils.assert(props.question != null, "props.question is null.");
  // #endregion Assertions
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  const validationMessage = "";
  // #endregion
  // #region Events
  // #endregion
  
  const isQuestionCompleted  = isCompleted(props.question);
  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();
  return (
    <>
    <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
        <QuestionHeader question={question}></QuestionHeader>
        <CategoryChoiceQuestion
          question={generateAnswer(question, preview)}
          preview={preview}
          setQuestions={props.setQuestions}
          questions={props.questions}
        ></CategoryChoiceQuestion>
        {validationMessage}
      </div>
    </div>
    </>
  );
}
// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.

  if (dependency == null) {
    utils.log.info(`CategoryChoice.isAnswered(false): dependency is null`);
    return false;
  }
  const checkedCount = (answers, scope) => {
    let result = null;
    switch (scope) {
      case "question":
        result = answers.filter((answer) => answer.isSelected).length;
        break;
      case "choice":
        result = answers.filter(
          (answer) =>
            answer.choice === dependency.choice.code && answer.isSelected
        ).length;
        break;
      case "category":
        result = answers.filter(
          (answer) =>
            answer.category === dependency.category && answer.isSelected
        ).length;
        break;
      case "categoryChoice":
        result = answers.filter(
          (answer) =>
            answer.category === dependency.category &&
            answer.choice === dependency.choice.code &&
            answer.isSelected
        ).length;
        break;
      default:
        throw new Error(
          `CategoryChoice.isAnswered: Invalid dependency type: ${dependency.type}`
        );
    }
    utils.log.info(
      `CategoryChoice.isAnswered.checkedCount(scope: ${scope}): ${result}`,
      answers
    );
    return result;
  };
  const dependencyType = dependencyUtil.getType(dependency);
  switch (dependencyType) {
    case "question": // TRUE: If any choice is selected.
      // TODO(LOW): When we figure out how to restore survey functions, convert checkedCount to that.
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        utils.log.info(
          "CategoryChoice.isAnswered(true): Choice was selected.",
          dependency.question.choices
        );
        return true;
      } else
        utils.log.warn(
          `CategoryChoice.isAnswered(false): Dependency on question(${dependency.question.code}) which requires at least one choice selected.`,
          dependency
        );
      break;
    case "choice": // TRUE: If this choice is selected.
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        utils.log.info(
          `CategoryChoice.isAnswered(true): Choice(${dependency.choice.valueFormatted?.(
            "."
          )}) was selected.`
        );
        return true;
      } else
        utils.log.warn(
          `CategoryChoice.isAnswered(false): Choice(${dependency.choice.valueFormatted?.(
            "."
          )}) has to be selected.`,
          dependency
        );
      break;

    case "category": // TRUE: If this category is selected.
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        utils.log.info(
          `CategoryChoice.isAnswered(true): Category(${dependency.category}) was selected.`
        );
        return true;
      } else
        utils.log.warn(
          `CategoryChoice.isAnswered(false): Category(${dependency.category}) has to be selected.`,
          dependency
        );
      break;
    case "categoryChoice": // TRUE: If this choice is selected in this category.
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        utils.log.info(
          `CategoryChoice.isAnswered(true): Choice(${dependency.choice.valueFormatted?.(
            "."
          )} Category(${dependency.category}) was selected.`
        );
        return true;
      } else
        utils.log.warn(
          `CategoryChoice.isAnswered(false): Choice(${dependency.choice.valueFormatted?.(
            "."
          )}) Category(${dependency.category}) has to be selected.`,
          dependency
        );
      break;
    default:
      throw new Error(
        `CategoryChoice.isAnswered: Invalid dependency type: ${dependency.type}`
      );
  }
  return false;
}
const CategorySelector = ({ categories, context, selectedQuestion }) => {
  utils.log.component(`CategorySelector: ${selectedQuestion.code}`, {
    categories,
    context,
    selectedQuestion,
  });
  // #region Assertions
  utils.assert(context != null, "context is null.");
  utils.assert(selectedQuestion != null, "selectedQuestion is null.");
  utils.assert(
    selectedQuestion?.dependency?.question?.categories != null,
    "selectedQuestion?.dependency?.question?.categories is null."
  );
  // #endregion Assertions
  // #region Initialize
  const dependency = dependencyUtil.get(context);

  const [selected, setSelected] = useState(dependency.category);
  const list = utils.toSelectItem(selectedQuestion?.dependency?.question?.categories);

  const isQuestionSelected = selectedQuestion != null; // Question selected
  const isChoiceSelected = dependency.choice != null; // Choice selected
  const isDisabled = !isQuestionSelected && !isChoiceSelected;
  utils.log.info(`CategorySelector: isDisabled: ${isDisabled}`, {
    selectedQuestion,
    dependency,
  });
  // #endregion Initialize

  // #region Events
  const handleChange = (e) => {
    utils.log.event("handleChange", e.target.value);
    const category = e.target.value;
    dependency.category = category;
    dependencyUtil.set(context, dependency);
    utils.log.info(`CategorySelector: dependency`, dependency);
    setSelected(category);
    utils.log.stateChange(
      `CategorySelector: handleChange(e) --> setSelected(${category})`,
      e
    );
  };
  // #endregion Events
  return (
    <>
      {" "}
      <FormSelect
        value={selected}
        allowNoSelection={utils.noSelection}
        id="category-select"
        label="Categories"
        disabled={isDisabled}
        data={list}
        onChange={handleChange}
      ></FormSelect>
    </>
  );
};
// #endregion

export const CategoryChoiceQuestion = ({
  question,
  preview = false,
  questions,
  setQuestions,
}) => {
  utils.log.component("CategoryChoiceQuestion", question);
  // #region Assertions
  utils.assert(question != null, "question is null.");
  utils.assert(setQuestions != null, "props.setQuestions is null.");
  utils.assert(
    typeof setQuestions === "function",
    "props.setQuestions is not a function."
  );
  const { choices, categories } = question;
  const [answers, setAnswers] = useState(null);
 
  const visibleChoices = preview
    ? choices // Show all choices in preview mode
    : choices.filter((c) => c.isVisible);

  useEffect(() => {
    // prepare answers
    const stagedQuestion = { ...question };
    questionAddFunctionality(stagedQuestion);
    setAnswers(stagedQuestion.answer);
  }, []);

  // #endregion Initialize
  // #region Events
  /*   useEffect(() => {
    utils.log.useEffect(
      `CategoryChoiceQuestion(${question.code}) - categories: ${categories}`,
      question
    );
    const options = categories.reduce((prev, curr) => {
      prev[curr] = "";
      return prev;
    }, {});
    setSelectedColumns(options);
    //assertCorrectAnswersLength(question);
    utils.log.stateChange(
      `CategoryChoiceQuestion(${
        question.code
      }) - selectedColumns: ${JSON.stringify(selectedColumns)}`,
      selectedColumns
    );
  }, [categories]); */
  const handleSelected = (selectedItem) => {
      const updatedAnswers = answers.map((a) => {
        if (
          a.category === selectedItem.category &&
          a.choice === selectedItem.choice.code
        ) {
          return {
            ...a,
            isSelected: !a.isSelected,
          };
        }
        return {
          ...a,
        };
      });
  
      let updatedQuestion = {
        ...question,
        answer: updatedAnswers,
      };
      if(preview){
        setQuestions(updatedQuestion);
      }else{
      questionAddFunctionality(updatedQuestion);
      setAnswers(updatedAnswers);
  
      const updatedQuestions = questions.map((q) => {
        if (q.code === updatedQuestion.code) {
          return updatedQuestion;
        }
        return q;
      });
  
      setQuestions(updatedQuestions);
    }
  };

  // #endregion Events

  // Visibility relies on dependency being answered

  // const {isSmDown} = useMediaQuery();
  if (!answers || !answers.length) return null;

  return (
    <TableContainer component={Paper} 
    // sx={{ marginLeft:preview? "0" :isSmDown ? "0px": "-50px" }}
    sx={{ marginLeft:preview? "0" : "-50px" }}
    
    >
      <Table
        sx={{ minWidth: 730, background: "#E9E9E9" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {/* Empty cell for spacing issues */}
            <TableCell> </TableCell>
            <TableCell
              sx={{
                padding: "16px 16px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              Category Choices
            </TableCell>
            {categories.map((category, index) => {
              return (
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "16px 16px",
                  }}
                  key={`headers_${category}_${index}`}
                >
                  {category}
                </TableCell>
              );
            })}
            <TableCell > </TableCell>
          </TableRow>
        </TableHead>

        <TableBody key={question.code} style={{ border: "none" }}>
          {visibleChoices.map((choice) => {
            utils.log.info(":: choiceCCQ", choice);
            return (
              <TableRow key={`tablerow_${choice.code}`}>
                <TableCell sx={{ borderBottom: "none" }}></TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {choice.description}
                </TableCell>
                {categories.map((category, index) => {
                  /*                 utils.log.section(
                  `CategoryChoiceQuestion(${question.code}) category: ${category} choice: ${choice.code} index: ${index}}`
                ); */
                  const current = {
                    question: question,
                    choice: choice,
                    category: category,
                  };

                  const categoryChoice = answers?.get(
                    current.category,
                    current.choice.code
                  );
                  return (
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        // padding: "16px 2px",
                        borderBottom: "none",
                      }}
                      key={`tablecell_${category}_${choice.code}_${index}`}
                    >
                      <CategoryChoice
                        current={current}
                        handleSelected={handleSelected}
                        categoryChoice={categoryChoice}
                        answer={answers}
                        noLabel
                      />
                    </TableCell>
                  );
                })}
                <TableCell sx={{ borderBottom: "none" }}></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CategoryChoice = ({
  current, // required object { question, choice }
  handleSelected, // required function
  categoryChoice,
  answer,
}) => {
  // PURPOSE: Renders a single choice for a CategoryChoice question type.
  utils.log.component("CategoryChoice",{current,answer,categoryChoice})
  utils.log.disabled(
    `CategoryChoice(category: ${current.category} choice: ${current.choice.code})`,
    current
  );
  // #region Assertions
  utils.assert(current != null, "current is null.");
  utils.assert(current.question != null, "current.question is null.");
  //utils.assert(handleSelected != null, "handleSelected is null.");
  assertCorrectAnswerLength(current.question);
  // #endregion Assertions
  /*   const categoryChoice = question.answer.get(
    current.category,
    current.choice.code
  ); */
  //assertCorrectAnswersLength(question);
  // #region Initialize
  //const choiceDependency = current.choice.dependencies?.[0];
  /*eslint-disable-next-line*/
  const [choiceDependency, setChoiceDependency] = useState(
    current.choice.dependency
  );
  const isChecked = answer?.get(
    current.category,
    current.choice.code
  )?.isSelected;
  /*   const [isChecked, setIsChecked] = useState(
    question.answer.get(current.category, current.choice.code)?.isSelected
  ); */

  //assertCorrectAnswersLength(question);

  // #endregion Initialize
  // #region Events

  // #endregion Events
  const radio = (
    <div>
      <Radio
        size="small"
        key={`category_${current.category}_choice_${current.choice.code}`}
        checked={isChecked}
        value={`category_${current.category}_choice_${current.choice.code}`}
        /* onChange={(e) => handleSelected(e, item)} */
        onClick={() => {
          handleSelected({
            question: current.question,
            choice: current.choice,
            category: current.category,
            type: "choice",
          });
        }}
      />
      {/* {current.choice.value} */}
    </div>
  );
  //const question = getQuestion(survey.questions, current.question);

  // Effects
  /*   
useEffect(() => {

    if (isVisible) {
      handleVisibleCategories(current.category, "add");
    } else handleVisibleCategories(current.category, "remove");
  }, [survey]); 
  */
  // Visibility relies on dependency being answered
  //if (!categoryChoice.isVisible && choiceDependency != null) {
  // TODO: Review with other question types if !question.isVisible (as in above line) is still necessary
  if (choiceDependency == null) {
    categoryChoice.isVisible = true;
  } else {
    //choiceDependency.category = current.category; // REVIEW: Why is this happening? RPL080823 Removed due to adding category when not needed.
    // Check if dependency is visible to override visibility settings
    // !REVIEW 072223 - Marked code below as possibly not needed
    /*     categoryChoice.isVisible = isDependencyAnswered(
      question,
      choiceDependency,
      survey
    ); */
  }

  if (choiceDependency == null) {
    return radio;
  } else {
    if (categoryChoice.isVisible) {
      return radio;
    } else {
      return null;
    }
  }
};

/* export const resolveDependency = (question, target) => {
  return question.resolveDependency(target);
};
 */

// #region Question functions
const assertCorrectAnswerLength = (question) => {
  utils.log.component("assertCorrectAnswerLength",question);
  // PURPOSE: Determine if question has correct number of answers.
  //return;
  if (
    question.categories.length > 0 && // has categories
    question.choices.length > 0 // has choices
  ) {
    const maxAnswers = question.categories.length * question.choices.length;
    utils.assert(
      question.answer.length === maxAnswers,
      `Incorrect number of answers in question.  Expect: ${maxAnswers} Actual: ${question.answer.length}`
    );
  }
};
const questionAddFunctionality = (question) => {
  utils.log.debug(`questionAddFunctionality(${question.code})`);
  question.answer.isAnswered = function (type, value) {
    // PURPOSE: Determine if question is complete (answered) for CategoryChoice question types
    return question.answer.filter(
      (
        // Filter by type and return number of selected choices
        answer
      ) => {
        return (
          answer[type] === value &&
          //answer.choice === choice.code &&
          answer.isSelected === true
        );
      }
    );
  };
  question.answer.get = function (category, choice) {
    // PURPOSE: Get a specific category/choice
    const result = question.answer.find(
      (answer) => answer.category === category && answer.choice === choice
    );

    return result;
  };
  question.answer.selectChoiceByCategory = (selectedItem) => {
    // PURPOSE: Set choice.isSelected to true by category
    utils.log.debug(`selectChoiceByCategory(${selectedItem.category})`);
    const categoryAnswers = question.answer.filter(
      (answer) => answer.category === selectedItem.category //&& answer.choice === selectedItem.choice.code
    );
    //answers.setIsSelected(false); //resetIsSelected(answers, false);
    question.setIsSelected(categoryAnswers, false);
    const answer = categoryAnswers.find(
      (answer) => answer.choice === selectedItem.choice.code
    );

    answer.isSelected = true;

    return answer;
  };

  question.isComplete = (() => {
    // All category/choice must have a selected choice

    const onlyVisibleQuestions = question.answer.filter(
      (answer) => answer.isVisible
    );
    if (onlyVisibleQuestions.length === 0) return false;
    for (let a = 0; a < onlyVisibleQuestions.length; a++) {
      // iterate through categories
      const category = question.answer[a].category;

      if (question.answer.isAnswered("category", category).length !== 1) {
        // FAIL: At least one category has no selection.
        return false;
      }
    }
    // PASS: Each category must have one choice selected.
    return true;
  })();
  question.resolveDependency = function (source, target) {
    utils.log.debug(`resolveDependency(${source.code}, ${target.code})`);
    if (target.category == null && target.choice != null) {
      // *** Choice dependency ***
      const selectedChoices = target.question.answer.isAnswered(
        "choice",
        target.choice.code
      );
      const selectedChoicesResult = selectedChoices.length > 0;
      //if (selectedChoicesResult) debugger;
      return selectedChoicesResult; // PASS: At least one choice is selected in categories
    }
    if (target.category != null && target.choice == null) {
      // TODO: Untested
      // *** Category dependency ***

      const selectedCategories = question.answer.isAnswered(
        target.question,
        "category",
        target.choice.code
      );
      return selectedCategories.count === 1;
    }
    if (target.category != null && target.choice != null) {
      // TODO: UNTESTED
      // *** Category/Choice dependency ***
      return question.answer.get(target.category, target.choice.code)
        .isSelected;
    }
  };
  question.category = function (category) {};
  question.setIsSelected = function (list, state) {
    // PURPOSE: Sets the isSelected property of list members to state (bool).

    utils.log.info(
      `setIsSelected(state: ${state} list: ${list.length}) BEFORE`,
      list
    );

    /*     for (var i = 0; i < list.length; i++) {
      list[i].isSelected = state;
    } */
    list.forEach((item) => {
      item.isSelected = state;
    });
    utils.log.info(
      `setIsSelected(state: ${state} list: ${list.length}) AFTER`,
      list
    );
  };
};
const generateAnswer = (question, preview = true) => {
  if (!preview) return question;
  // Populate question.answer with an array of objects { category, choice, isSelected }
  question.answer = [];
  question.choices.forEach((choice) => {
    question.categories.forEach((category) => {
      question.answer.push({
        category: category,
        choice: choice.code,
        isSelected: false,
        isVisible: true,
      });
    });
  });
  assertCorrectAnswerLength(question);
  return question;
};

export const isCompleted = (question) => {
  utils.log.component("CategoryChoice.isCompleted()",question);
  // PURPOSE: Determine if question is complete (answered) for CategoryChoice question types
  // All category/choice must have a selected choice

  const onlyVisibleOptions = question?.answer?.filter((answer) => answer?.isVisible);
  if (onlyVisibleOptions?.length === 0) return false;

  // check is any of the visible options is selected
  const hasSelected = onlyVisibleOptions?.some((answer) => answer?.isSelected);

  return Boolean(hasSelected);
};

export const getValue = (question) => {
  utils.log.component("Survey.getValue()",question);
  return {
    values: question?.answer
      ?.filter((element) => element.isSelected)
      .map((element) => ({
        category: element.category,
        value: element.choice,
      })),
  };
};

// #endregion Question functions
