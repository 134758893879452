import { TourProvider } from "@reactour/tour";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/context";
import Loading from "../Loading";
import * as utils from "../../services/utilities";
import OnboardingTourStep from "../OnboardingTour";
export let steps = [];

const OnboardingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { noticeService } = useContext(AuthContext);

  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const fetchNoticeOnBoarding = async () => {
    if (!noticeService) {
      utils.log.info("noticeService is not available.");
      return;
    }
    setLoading(true);
    try {
      const allOnboardingNotice = await noticeService.getBySection(
        "Onboarding",
        "view",
        null,
        true
      );

      utils.log.info(":: all allOnboardingNotice",allOnboardingNotice);
      
      const updatedOnboarding = allOnboardingNotice?.map((item) => {
        const start = item?.title?.indexOf("[");
        const end = item?.title?.indexOf("]");
        const onboardingHeader = item?.title?.slice(start + 1, end);
        const onBoardingSelector = onboardingHeader?.split("_");
        return {
          selector: "." + onBoardingSelector[0],
          id: onBoardingSelector[1],
          content: onBoardingSelector[0] === "onboarding-9" ? (
            <div>
              {stripHtmlTags(item?.message)}
              <OnboardingTourStep />
            </div>
          ) : (
            <div>{stripHtmlTags(item?.message)}</div>
          ),
        };
      });
      steps = updatedOnboarding;
    } catch (err) {
      utils.log.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNoticeOnBoarding();
  }, [noticeService]);

  if (loading) return <Loading />;

  return (
    <>
      <TourProvider
        disableInteraction={true}
        disableFocusLock={false}
        onClickMask={() => null}
        steps={steps ? steps : []}
      >
        {children}
      </TourProvider>
    </>
  );
};

export default OnboardingProvider;
