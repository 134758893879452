/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Box,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Editor } from "@tinymce/tinymce-react";
import Button from "./Button";
import FormSelect from "./Select";
import Grid from "./Grid";
import Table from "./Table";
import Input from "./Input/inputs";
import { AuthContext } from "../services/context";
import * as utils from "../services/utilities";
import { API_KEY_TINY_MCE } from "../utils/env";

const columns = [{ Header: "Code", accessor: "code" }];
export const NoticesViewer = ({
  section,
  title ="section",
  width = "80%",
  id = null,
  filterOutResponses = true,
  isNoticeViewerOpen ,
  setIsNoticeViewerOpen,
  preview
}) => {
  utils.log.component("Notice.Viewer", section);

  // #region Assertions
  utils.assert(section, "section required");
  // #endregion

  // #region Initialize
  // const [isOpen, setIsOpen] = useState(true);
  const [list, setList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const { noticeService } = useContext(AuthContext);
  // #endregion

  // #region Functions
  // Show non-acknowledgement notices only once per session.
  const isClosedDisabled = function (list,preview) {
    if(!preview){
    return list?.filter((item) => item?.isAcknowledgementRequired)?.length > 0;
    }
    return false;
  };

  const hideOnly = list.filter(
    (item) => item.isAcknowledgementRequired === false
  );
  if (hideOnly.length) noticeService.addAlreadyViewed(hideOnly);
  const handleClose = () => {
    setIsNoticeViewerOpen(false);
  };
  // #endregion

  // #region Events
  const fetchNotices = useCallback(async () => {
    try {
      const allNoticeList = await noticeService.getBySection(
        section,
        "view",
        id,
        filterOutResponses
      );
      utils.log.debug("Notice.Viewer.fetchNotices", list);

      let renderList = allNoticeList;
      if(!preview){
        renderList = allNoticeList.filter((item) => !item?.dateAcknowledged);
      }
      setList(renderList);
        if(renderList.length > 0){
          setIsNoticeViewerOpen(true)
        }else{
          setIsNoticeViewerOpen(false)
        }
      setisLoading(false);
    } catch (error) {
      utils.log.error(error);
      setisLoading(false);
    }
  }, [noticeService]);


  useEffect(() => {
    fetchNotices();
  }, []);

  utils.log.info(":: list data",list);

  // const noticeContextValue = useMemo(() => {
  //   return {
  //     list,
  //     setList,
  //     fetchNotices,
  //   };
  // }, [list, fetchNotices]);
  // #endregion

  return (
    <Dialog
      open={isNoticeViewerOpen}
      PaperProps={{
        style: {
          width: width, 
          maxWidth: "none", // Optional: Remove max-width restriction
        },
      }}
    >
      <DialogTitle>
        <h1>{title}</h1>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <hr></hr>
          {list
          .map((notice) => (
            <NoticeViewItem
              key={notice.id}
              notice={notice}
              list={list}
              setList={setList}
              setIsNoticeViewerOpen={setIsNoticeViewerOpen}
            />
          ))}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isClosedDisabled(list,preview)}>
            Close
          </Button>
        </DialogActions>
    </Dialog>
  );
};


export const NoticeViewItem = ({ notice, list, setList,setIsNoticeViewerOpen }) => {
  utils.log.component("Notice.Item", notice);
  // #region Assertions
  utils.assert(notice, "notice required");
  utils.assert(list, "list required");
  utils.assert(setList, "setList required");
  // #endregion
  // #region Initialize
  const { employee, noticeService } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  //const isAcceptVisible = notice.isAcknowledgementRequired ? "block" : "none";
  //const isHideVisible = !notice.isAcknowledgementRequired ? "block" : "none";
  const isAcceptVisible = function (notice) {
    if (notice.isAcknowledgementRequired && notice.dateAcknowledged === null)
      return true;
    // Default
    return false;
  };
  const isHideVisible = function (notice) {
    if (!notice.isAcknowledgementRequired) return true;
    // Default
    return false;
  };
  // #endregion
  // #region Events
  const handleAcknowledgement = async (e) => {
    utils.log.debug("Notice.Item.handleAccept", e, notice);
    const mode = employee ? "coordinator" : "recipient";
    // debugger;
    try {
      if (mode === "coordinator"){
        await noticeService.acknowledgeCoordinator(notice);
      }else{
       await noticeService.acknowledgeRecipient(notice);
      }
      // TODO: 20240911 Remove notice from list
      // Remove notice from list
      setList(list.filter((item) => item.id !== notice.id));
      // CHECK IF THERE IS NO ANY ITEM IN NOTICE BOARD JUST CLOSE THE BOARD(MODAL)
      const remainingNotice = list.filter((item) => !item?.dateAcknowledged) ;
      if(remainingNotice.length === 1){
        setIsNoticeViewerOpen(false);
      }
    } catch (error) {
      utils.log.error(error);
      enqueueSnackbar("Error deleting notice", { variant: "error" });
    }
  };

  const handleHide = async (e) => {
    utils.log.debug("Notice.Item.handleHide", e, notice);
    debugger;
  };
  // #endregion
  // #region Functions
  // #endregion
  

  return (
    <Grid container spacing={2}>
      {/*       <Grid item xs={12}>
        <b>{notice.title}</b>
      </Grid> */}
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: notice.message }}></div>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
        <Button
          fullWidth
          style={{ display: isAcceptVisible(notice) ? "block" : "none" }}
          onClick={handleAcknowledgement}
        >
          Accept
        </Button>
        &nbsp;<br></br>
        <Button
          fullWidth
          style={{ display: isHideVisible(notice) ? "block" : "none" }}
          onClick={handleAcknowledgement}
        >
          Hide{" "}
          {/* Hide is a special kind of acknowledgement that says don't show me this again */}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
      </Grid>
    </Grid>
  );
};
export const NoticeSelector = ({
  section, // string - required
  selectedNotice, // object - required
  setSelectedNotice, // function - required
  //id, // number - optional (e.g. survey.id, exemption.id)
  list, // array - required
}) => {
  utils.log.component("NoticeSelector.section", section);
  // #region Assertions
  utils.assert(section, "section required");
  utils.assert(setSelectedNotice, "setSelected required");
  utils.assert(list, "list required");
  // #endregion
  // #region Initialize

  const { noticeService } = useContext(AuthContext);
  //const [list, setList] = useState([]); // TODO: 20240911 Consider lifting this up to NoticeEditor to allow refresh when ADD notice.

  const label = "Select Notice";
  // #endregion
  // #region Events
  const handleChange = (e) => {
    const notice = list.find((item) => item.id === e.target.value);
    setSelectedNotice(notice);
    utils.log.event("NoticeSelector.handleChange(selectedNotice, list)", {
      notice: notice,
      list: list,
    });
  };
  // #endregion
  // #region Functions
  // #endregion
  if (list.length === 0) return <div>No Notices</div>;
  return (
    <FormSelect
      label={label}
      data={utils.toSelectItem(list, "name", "id") || []}
      fullWidth
      value={selectedNotice?.id}
      setSelected={setSelectedNotice}
      onChange={(e) => {
        handleChange(e);
      }}
    ></FormSelect>
  );
};
export const NoticesList = ({ section }) => {
  utils.log.component("Notice.List", section);
  // #region Assertions
  utils.assert(section, "section required");
  // #endregion
  // #region Initialize
  const { noticeService } = useContext(AuthContext);
  const [list, setList] = useState([]);
  // #endregion
  // #region Events
  useEffect(() => {
    fetchNotices();
  }, []);
  // #endregion
  // #region Functions
  const fetchNotices = async () => {
    try {
      const list = await noticeService.getBySection(section);
      utils.log.debug("Notice.List.fetchNotices", list);
      setList(list);
    } catch (error) {
      utils.log.error(error);
    }
  };
  // #endregion

  if (list.length === 0) return <div>No Notices</div>;
  return (
    <Table
      columns={columns}
      data={list}
      title={section}
      width="100%"
      // onRowClick={handleRowClick}
    />
  );
};
export const NoticeEditor = ({ sections, type, id, editorHeight = 125 }) => {
  utils.log.component("Notice.Editor", sections);
  // #region Assertions
  //utils.assert(section, "section required");
  utils.assert(sections, "sections required");
  // #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const { noticeService } = useContext(AuthContext);
  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [list, setList] = useState([]); // TODO: 20240911 Consider lifting this up to NoticeEditor to allow refresh when ADD notice.
  const [selectedNotice, setSelectedNotice] = useState(null);
  const mode = selectedNotice?.id === 0 ? "Add" : "Edit";
  const isDeleteEnabled = !(selectedNotice?.id !== 0 || mode === "Edit");
  // #endregion
  // #region Events

  const handleSection_Change = (e) => {
    const section = e.target.value;
    setSelectedSection(section);
    utils.log.stateChange("setSelectedSection(section)", section);
  };
  const handleDelete = async () => {
    try {
      utils.log.event("Notice.Editor.handleDelete", selectedNotice);
      await noticeService.remove(selectedNotice);
      enqueueSnackbar("Updated successfully", { variant: "success" });
      await fetchNotices(); // Refresh list of notices
      setSelectedNotice(list[0]);
    } catch (error) {
      utils.log.error(error);
      enqueueSnackbar("Error deleting notice", { variant: "error" });
    }
  };
  const handleAdd = () => {
    utils.log.event("Notice.Editor.handleAdd", selectedNotice);
    // Make a copy of the selected object
    const notice = noticeService.create(selectedSection);

    setSelectedNotice(notice);
  };
  // #endregion
  // #region Functions
  useEffect(() => {
    fetchNotices();
  }, [selectedSection]);
  const fetchNotices = async () => {
    try {
      utils.log.info("Notice.List.fetchNotices", selectedSection);
      const list = await noticeService.getBySection(
        selectedSection,
        "edit",
        id
      );
      utils.log.debug("Notice.List.fetchNotices", list);
      setList(list);
      if (selectedNotice === null && list.length > 0) {
        setSelectedNotice(list[0]);
      } else setSelectedNotice(null);
    } catch (error) {
      utils.log.error(error);
    }
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormSelect
          label="Section"
          data={utils.toSelectItem(sections) || []}
          fullWidth
          allowNoSelection
          value={selectedSection}
          setSelected={setSelectedSection}
          onChange={handleSection_Change}
        ></FormSelect>
      </Grid>
      <Grid item xs={6}>
        <NoticeSelector
          section={selectedSection}
          selectedNotice={selectedNotice}
          setSelectedNotice={setSelectedNotice}
          id={id}
          list={list}
        />
      </Grid>
      <Grid item xs={1}>
        <Button fullWidth disabled={isDeleteEnabled} onClick={handleDelete}>
          Delete
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button fullWidth onClick={handleAdd}>
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        {selectedNotice && (
          <NoticeDetails
            notice={selectedNotice}
            type={type}
            id={id}
            editorHeight={editorHeight}
          />
        )}
      </Grid>
    </Grid>
  );
};
const NoticeDetails = ({
  notice,
  type = null,
  id = null,
  editorHeight = 125,
}) => {
  utils.log.component("Notice.Details", notice);
  // #region Assertions
  utils.assert(notice, "notice required");
  // #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const { noticeService } = useContext(AuthContext);
  const editorRef = useRef(null);
  const mode = notice?.id === 0 ? "Add" : "Edit";
  //const [message, setMessage] = useState(notice.message);
  const [noticeCopy, setNoticeCopy] = useState(notice);
  const noticeAppliesToAllSurveys =
    notice.scope.filter(
      (item) =>
        item.type === "Survey" && item.property === null && item.value === null
    ).length === 1;

  const [isAllChecked, setIsAllChecked] = useState(noticeAppliesToAllSurveys);
  utils.log.debug("NoticeDetails", {
    noticeAppliesToAllSurveys: noticeAppliesToAllSurveys,
    isAllChecked: isAllChecked,
    notice: notice,
    noticeCopy: noticeCopy,
  });
  // #endregion
  // #region Events
  useEffect(() => {
    setNoticeCopy(notice);
    setIsAllChecked(noticeAppliesToAllSurveys);
    utils.log.stateChange("useEffect(notice)", {
      notice: notice,
      noticeAppliesToAllSurveys: noticeAppliesToAllSurveys,
      isAllChecked: isAllChecked,
    });
  }, [notice]);
  const handleIsAllChecked_Change = (e) => {
    const isAllChecked = e.target.checked;
    setIsAllChecked(isAllChecked);
    utils.log.stateChange("handleIsAllChecked_Change", isAllChecked);
  };
  const handleMessage_Change = (e) => {
    const target = e.target.name;
    const value = e.target.value;
    utils.log.info(`Message.${target}(changed): ${value}`, noticeCopy.message);

    const updatedNotice = { ...noticeCopy, message: value }; // Create a new object that's a copy of the current state

    setNoticeCopy(updatedNotice); // Update the state with the new object
    utils.log.stateChange(`setNoticeCopy(updatedNotice)`, updatedNotice);
  };
  const handleSave = async () => {
    if (isAllChecked) {
      noticeCopy.scope = [{ type: type, property: null, value: null }]; // Notice is available to all surveys
    } else {
      noticeCopy.scope = [
        { type: type, property: "id", value: id === null ? null : `${id}` },
      ]; // Notice is available to a specific survey
    }

    try {
      switch (mode) {
        case "Add":
          await noticeService.add(noticeCopy);
          enqueueSnackbar("Added successfully", { variant: "success" });
          break;
        case "Edit":
          await noticeService.update(noticeCopy);
          enqueueSnackbar("Updated successfully", { variant: "success" });
          break;
        default:
          break;
      }
    } catch (error) {
      utils.log.error(error);
      enqueueSnackbar("Error saving notice", { variant: "error" });
    }
  };
  const handleInput_Change = (e) => {
    const target = e.target.id;
    const type = e.target.type;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    utils.log.info(`Notice.${target}(changed): ${value}`, notice);
    // Update notice with the new value
    const updatedNotice = { ...noticeCopy, [target]: value };

    setNoticeCopy(updatedNotice);
    utils.log.stateChange(`setNoticeCopy(updatedNotice)`, updatedNotice);
  };
  // #endregion
  // #region Functions
  // #endregion

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <b>Notice {mode}</b>
        </Grid>
        <Grid item xs={7}>
          TODO: Antd ranged date/time picker (effective/expire date)
        </Grid>
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <Tooltip title="For surveys and exemptions, applies to all.">
            <Checkbox
              checked={isAllChecked}
              onChange={handleIsAllChecked_Change}
            />
            <br></br>All
          </Tooltip>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <Tooltip title="Recipient/coordinator is required to agree to proceed.">
            <Checkbox
              id="isAcknowledgementRequired"
              checked={noticeCopy.isAcknowledgementRequired}
              onChange={handleInput_Change}
            />
            <br></br>Required
          </Tooltip>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <Tooltip title="Regardless of whether the recipient/coordinator has agreed to this, it will always display.">
            <Checkbox
              id="alwaysShow"
              checked={noticeCopy.alwaysShow}
              onChange={handleInput_Change}
            />
            <br></br>Always
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Input
            type="string"
            label="Name"
            id="name"
            fullWidth
            required
            value={noticeCopy.name}
            onChange={handleInput_Change}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="string"
            label="Title"
            id="title"
            fullWidth
            required
            value={noticeCopy.title}
            onChange={handleInput_Change}
          />
        </Grid>

        <Grid item xs={12}>
          <Editor
            // TODO: 051323 Complete implementation of image insert
            // BUG: 111723 https://github.com/GoPassGoNetworks/AVR/issues/31
            apiKey={API_KEY_TINY_MCE}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            value={noticeCopy.message}
            onEditorChange={(content, editor) => {
              const event = {
                target: {
                  name: "body",
                  value: content,
                },
              };

              handleMessage_Change(event);
            }}
            init={{
              height: editorHeight,
              menubar: false,
              branding: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount textcolor",
              ],
              toolbar:
                "undo redo | formatselect | image | " +
                "bold italic | forecolor backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </Grid>

        {/*         <Grid item xs={3}>
          <Input
            type="string"
            label="Acknowledgement"
            fullWidth
            value={noticeCopy.acknowledgementText}
          />
        </Grid> */}
        <Grid item xs={11}></Grid>
        <Grid item xs={1} style={{ textAlign: "right" }}>
          <Button fullWidth onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Eula = ({
  isOpen = false,
  setIsOpen = null,
  preview = false,

}) => {
  utils.log.component("Eula");

  const [isNoticeViewerOpen,setIsNoticeViewerOpen] = useState(false)
  
  return (
    <NoticesViewer
      section="EULA"
      title="End User License Agreement"
      width="80%"
      filterOutResponses={false}
      isNoticeViewerOpen={preview ? isOpen : isNoticeViewerOpen}
      setIsNoticeViewerOpen={ preview ? setIsOpen :setIsNoticeViewerOpen}
      preview = {preview}
    />
  );
};


export const PrivacyPolicy = ({isOpen,setIsOpen}) => {
  return (
    <NoticesViewer
    section="PrivacyPolicy"
    title="Privacy Policy"
    width="80%"
    isNoticeViewerOpen={isOpen}
    setIsNoticeViewerOpen={setIsOpen}
    />
  );
};
// Blog
// Feature
// Help
