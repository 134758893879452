// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { AuthContext } from "../../services/context";
import FormUtility from "../../services/form";
import * as utils from "../../services/utilities";
import { toSelectItem } from "../../services/utilities";
import { HeaderActions } from "../../components/HeaderActions";
import DashboardLayout from "../../components/DashboardLayout";
import { Worksite } from "../recipients/management/summary";
import { SurveySelector } from "../survey";
import MUIDialog from "../../components/Modal";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "../../hooks/useMediaQuery";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
const formUtility = new FormUtility();

const useStyles = makeStyles((theme) => ({
  worksiteDeptContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "20px",
    },
  },
  worksiteDeptSurveySelector: {
    width: "200px",
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
  },
}));
export default function Worksites() {
  utils.log.component("Worksites(list)");
  // #region Assertions
  // #endregion
  // #region Functions
  const updateList = async () => {
    utils.log.info("Update worksite list");

    const result = await worksiteService.getAll();
    setList(result);
    setisLoading(false);
    utils.log.stateChange("setList(result)", result);
  };
  const getWorksite = async (id) => {
    try {
      const worksite = await worksiteService.get(id);

      setWorksite(worksite);
      setWorksiteOpen(true);
      setisLoading(false);
      utils.log.stateChange(`setWorksite(${id})`, worksite);
      utils.log.stateChange(`setWorksiteOpen(true)`, worksite);
    } catch (error) {
      utils.log.error(`Error fetching worksite (${id})`, error);
      setisLoading(false);
    }
  };
  // #endregion
  // #region Initialize
  const confirm = useConfirm();
  const [worksiteOpen, setWorksiteOpen] = useState(false);
  const [openAdd, setAddOpen] = useState(false);
  const [worksite, setWorksite] = useState(null);
  const { worksiteService } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Departments", accessor: "departments" },

    {
      Header: "Mailing Address",
      accessor: "mailingAddress",
    },
    /* { Header: "Coordinator", accessor: "coordinator" }, */
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("Worksites(list) - Initialize");
    updateList();
  }, []);

  const handleClose = () => {
    setAddOpen(false);
    setWorksiteOpen(false);
    updateList();
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleEdit = (update) => getWorksite(update.id);
  const handleDelete = () => {
    confirm({
      description: `Confirm deletion of worksite ${worksite?.name}.`,
    })
      .then(() => {
        return worksiteService.remove(worksite.id);
      })
      .then(async () => {
        const updatedWorksites = list?.filter(
          (item) => item?.id != worksite?.id
        );
        setList(updatedWorksites);
        setWorksiteOpen(false);
        enqueueSnackbar("Worksite deleted successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        if(error){
        utils.log.error("Worksite deletion cancelled.")
        enqueueSnackbar(error?.message || "Error deleting employee", {
          variant: "error",
        });
      }
      });
  };

  const headerAction = (
    <HeaderActions
      tourClassName={"onboarding-13"}
      buttonTitle={"Add"}
      onAdd={handleAddOpen}
    />
  );
  // #endregion
  return (
    <DashboardLayout
      title="Worksites"
      description="Worksite helps you to easily manage your worksites"
      code={"worksite-list"}
    >
      {openAdd && <Add open={openAdd} onClose={handleClose} list={list}/>}
      {worksiteOpen && (
        <Overview
          open={worksiteOpen}
          onClose={handleClose}
          worksite={worksite}
          setWorksite={setWorksite}
          worksiteList={list}
          handleDelete={handleDelete}
        ></Overview>
      )}

      <Table
        columns={columns}
        data={list}
        selected
        onSelected={(row) => handleEdit(row.original)}
        headerAction={headerAction}
        isLoading={isLoading}
        filterTourClassName={"onboarding-14"}
        paginationTourClassName={"onboarding-15"}
      ></Table>
    </DashboardLayout>
  );
}

const Overview = ({
  onClose,
  open,
  worksite,
  setWorksite,
  worksiteList,
  handleDelete,
}) => {
  utils.log.component("Worksite(Overview)", worksite);

  const { cache, worksiteService, error } = useContext(AuthContext);

  // #region Assertions
  // #endregion
  // #region Functions
  const isValid = () => {
    // PURPOSE: Validate worksite.
    if (!utils.isUniqueValue(cache.get("code"), worksiteCodes)) {
      // Code already used.
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject(error.getErrorObject("code"));
    } else {
      error.clearErrorObject("code");
    }
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };
  //  #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  /*eslint-disable-next-line*/
  const [codeErrorObject, setCodeErrorObject] = useState(null);

  const worksiteCodes =
    worksiteList === undefined
      ? null
      : worksiteList
          .map((item) => item.code)
          .filter((item) => item !== worksite.code); // Exclude current worksite.
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect(
      `Surveys(Overview) - When survey change: Cache survey ${worksite.id}`
    );
    cache.setDetails(worksite);
  }, [worksite]);
  const handleUpdate = async (event) => {
    // PURPOSE: Persist worksite being edited to database.
    utils.log.event("Worksite(Overview) handleUpdate()");
    event.preventDefault();

    if (isValid()) {
      utils.log.info("Worksite(Overview) handleUpdate() - Valid");
      cache.set("id", worksite.id);
      try {
        await worksiteService.update(cache.getDetails());
        enqueueSnackbar("Worksite updated", { variant: "success" });
        setWorksite(cache.getDetails());
      } catch (error) {
        utils.log.error(error);
        enqueueSnackbar(error.message, { variant: "error", persist: true });
      }finally{
        onClose();
      }
    }
    worksiteService.update(worksite);
  };

  const { isSmDown, isMdDown } = useMediaQuery();
  // #endregion
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      // PaperProps={{ style: { height: "600px", margin: "50px" } }}
      height={"600px"}
      title={"Update Worksite"}
      description={
        "A worksite is a grouping of departments at a physical location an employee commutes to."
      }
      actions={
        <>
          {isSmDown ? (
            <Button
              style={{
                backgroundColor: "red",
                padding: "9px 12px",
                fontSize: "12px",
                borderRadius: "8px",
              }}
              onClick={handleDelete}
              tabIndex={-1}
            >
              Delete
            </Button>
          ) : (
            <div onClick={handleDelete} style={{ cursor: "pointer" }}>
              <DeleteOutlineTwoToneIcon
                sx={{ marginTop: "0px", marginLeft: "-9px" }}
                color="red"
                htmlColor="red"
                fontSize="medium"
              />
            </div>
          )}
          {/* <Button
            variant="contained-white"
            style={{
              border: isSmDown ? "1px solid red" : "none",
              borderRadius: "8px",
              color: "red",
              padding: isSmDown ? "8px 25px" : "0px 10px",
              fontSize: isMdDown ? "12px" : "14px",
            }}
            onClick={onClose}
            tabIndex={-1}
          >
            CANCEL
          </Button>{" "} */}
          <Button
            style={{
              padding: isMdDown ? "9px 12px" : "9px 25px",
              fontSize: isMdDown ? "12px" : "14px",
              borderRadius: "8px",
            }}
            onClick={handleUpdate}
          >
            UPDATE
          </Button>
        </>
      }
    >
      <Tabs>
        <Tab
          label="Details"
          component={<Details worksite={worksite} onClose={onClose} />}
        />
        <Tab
          label="Departments"
          component={<WorksiteDepartments worksite={worksite} />}
        />
        <Tab label="Metrics" component={<Metrics />} />
      </Tabs>
    </MUIDialog>
  );
};

const AddDepartment = ({
  worksiteCode,
  isOpenAddDepartments,
  onClose,
  onDepartmentAdded,
  selectedSurveyCode
}) => {
  const { departmentService } = useContext(AuthContext);
  const [departments, setDepartments] = useState({
    name: "",
    code: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartments((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const respons = await departmentService.add(worksiteCode, {
        name: departments.name,
        code: departments.code,
        selectedSurveyCode:selectedSurveyCode ? selectedSurveyCode : null
      });
      utils.log.info(":: response of Added Worksite", respons);
      if (respons.ok) {
        enqueueSnackbar("Deparrtment Added successfully", {
          variant: "success",
        });
        onDepartmentAdded();
        onClose();
      }
    } catch (error) {
      utils.log.error(error);
    }
  };

  const { isSmDown, isMdDown } = useMediaQuery();

  const actions = (
    <>
      <Button
        variant="contained-white"
        style={{
          border: isSmDown ? "1px solid red" : "none",
          borderRadius: "8px",
          color: "red",
          padding: isSmDown ? "8px 25px" : "0px 10px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        onClick={onClose}
        tabIndex={-1}
      >
        CANCEL
      </Button>{" "}
      <Button
        style={{
          padding: isMdDown ? "9px 12px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
          borderRadius: "8px",
        }}
        type="submit"
      >
        ADD
      </Button>
    </>
  );

  return (
    <>
      <MUIDialog
        title="Add Departments"
        open={isOpenAddDepartments}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        // PaperProps={{
        //   style: {
        //     height: "auto",
        //     margin: "50px",
        //   },
        // }}
        height={"auto"}
        onSubmit={handleSubmit}
        actions={actions}
      >
        {/* give padding to match the alignment with the header */}
        <Grid container spacing={2} sx={{p:'6px'}}>
          <Grid item xs={12}>
            <Input
              label="Worksite"
              fullWidth
              name="code"
              onChange={handleChange}
              defaultValue={worksiteCode}
              required
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Input
              label="Code"
              fullWidth
              name="code"
              onChange={handleChange}
              defaultValue={departments.code}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Input
              label="Name"
              fullWidth
              name="name"
              onChange={handleChange}
              defaultValue={departments.name}
              required
            />
          </Grid>
        </Grid>
      </MUIDialog>
    </>
  );
};

function Details({ worksite, onClose }) {
  // TODO: Add list parameter to enable updates
  utils.log.component("Worksite(Details)", worksite);
  const { cache } = useContext(AuthContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Input
          required
          label="Code"
          fullWidth
          name="code"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("code")}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Input
          required
          label="Name"
          fullWidth
          name="name"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("name")}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Street Address"
          fullWidth
          name="streetAddress"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("streetAddress")}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label="Street Address 2"
          fullWidth
          name="streetAddress2"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("streetAddress2")}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Input
          label="City"
          name="city"
          fullWidth
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("city")}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Input
          label="State"
          name="state"
          fullWidth
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("state")}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          label="Zip Code"
          fullWidth
          name="zipCode"
          onChange={(e) => cache.set(e)}
          defaultValue={cache.get("zipCode")}
        />
      </Grid>
    </Grid>
  );
}

const Add = ({ open, onClose ,list }) => {
  // TODO: Ensure that the code is unique.
  //#region Assertions
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const { worksiteService, cache } = useContext(AuthContext);
  /*eslint-disable-next-line*/
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  formUtility.setDetail(null);
  formUtility.setInputs(inputs);
  // #endregion
  // #region Events

  const isValid = () => {
    const isCodePresent = list?.find((item) => item?.code.toLowerCase() === cache.get("code")?.toLowerCase());
    if(isCodePresent){
      return false;
    }
    return true;
  }
  const handleSubmit = async (event) => {
    utils.log.event("Worksite(Add).handleSubmit()");
    event.preventDefault();
    if(isValid()){
    setIsLoading(true);
    try {
      
      cache.set("departments", []);
      await worksiteService.add(cache.cache);
      enqueueSnackbar("Worksite Added Successfully", { variant: "success" });
      onClose();
    } catch (error) {
      utils.log.error("Worksite(Add).handleSubmit()", error);
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }else{
    utils.log.error("Worksite.Add","Code is already present");
    enqueueSnackbar("Code already present",{variant:"error"})
  }
  };

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Add Worksite"
      // PaperProps={{ style: { height: "500px" } }}
      height={"500px"}
      onSubmit={handleSubmit}
      actions={
        <>
          {/* <Button onClick={onClose}>Cancel</Button> */}
          <Button type="submit">{isLoading ? "Loading..." : "Add"}</Button>
        </>
      }
    >
      <Grid container spacing={2} sx={{p:'6px'}}>
        <Grid item xs={12} md={2}>
          <Input
            required
            label="Code"
            fullWidth
            name="code"
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("code")}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <Input
            label="Name"
            fullWidth
            name="name"
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("name")}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Street Address"
            fullWidth
            name="streetAddress"
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("streetAddress")}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Street Address 2"
            fullWidth
            name="streetAddress2"
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("streetAddress2")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="City"
            fullWidth
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("city")}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label="State"
            fullWidth
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("state")}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type={"number"}
            hideNumberSpinner
            label="Zip Code"
            fullWidth
            name="zipCode"
            onChange={(e) => cache.set(e)}
            defaultValue={() => cache.get("zipCode")}
            required
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

export const Selector = ({ worksiteCode, setWorksiteCode }) => {
  utils.log.component(`Worksite(Selector): default: ${worksiteCode}`);
  // #region Assertions
  //utils.assert(worksiteCode != null, "worksiteCode is required");
  utils.assert(setWorksiteCode != null, "setWorksiteCode is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const { worksiteService } = useContext(AuthContext);
  const [worksites, setWorksites] = useState();
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("INIT");
    const fetchData = async () => {
      const result = await worksiteService.getAll();
      const selectItems = toSelectItem(result, "name", "code");
      setWorksites(selectItems);
      utils.log.stateChange("setWorksites(selectItems)", selectItems);
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setWorksiteCode(event.target.value);
  };
  // #endregion

  if (worksites == null) return <>- No worksites found. -</>;
  return (
    <FormSelect
      label="Worksites"
      value={worksiteCode}
      data={worksites}
      fullWidth
      onChange={handleChange}
    ></FormSelect>
  );
};

const Metrics = () => {
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding:"130px"
    }}
    >
      <h1>Coming Soon</h1>
    </div>
  );
};

const WorksiteDepartments = ({ worksite }) => {
  const [selectedSurveyCode, setSelectedSurveyCode] = useState("");
  const [isOpenAddDepartments, setIsOpenAddDepartments] = useState(false);
  const [refreshDepartments, setRefreshDepartments] = useState(false);
  const handleClose = () => {
    setIsOpenAddDepartments(false);
  };

  const handleDepartmentAdded = () => {
    setRefreshDepartments((prev) => !prev);
  };


  const classes = useStyles();

  return (
    <>
      <div className={classes.worksiteDeptContainer}>
        <div className={classes.worksiteDeptSurveySelector}>
          <SurveySelector
            filter={"Active"}
            selected={selectedSurveyCode}
            setSelected={setSelectedSurveyCode}
            label={"Surveys(active)"}
            firstItemSelected
          ></SurveySelector>
        </div>
        <div>
          <HeaderActions
            buttonTitle="Add"
            onAdd={() => setIsOpenAddDepartments(true)}
            disabled={selectedSurveyCode ? false : true}
          />
        </div>
      </div>

      {selectedSurveyCode  && (
        <Worksite
          key={selectedSurveyCode} 
          worksite={worksite}
          survey={selectedSurveyCode}
          refreshDepartments={refreshDepartments}
        />
      )}

      {isOpenAddDepartments  && (
        <AddDepartment
          worksiteCode={worksite?.code}
          isOpenAddDepartments={isOpenAddDepartments}
          onClose={handleClose}
          onDepartmentAdded={handleDepartmentAdded}
          selectedSurveyCode={selectedSurveyCode}
        />
      )}
    </>
  );
};
