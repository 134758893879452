import React from "react";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { Checkbox } from "../../components/Input/inputs";
import SurveyService from "../../services/survey";
import * as utils from "../../services/utilities";
import { renderHtml } from "../../services/question";
import CategoryChoiceQuestion from "./categoryChoiceQuestion";
import MultipleChoiceQuestion from "./multipleChoiceQuestion";
import SingleChoiceQuestion from "./singleChoiceQuestion";
import TickIcon from "../../assets/Tick.png";
import * as config from "../../services/config";

export const isDependencyAnswered = (question, dependency, survey) => {
  // !ISSUE 072223 This method is likely deprecated in favor of {questionType}.isAnswered()

  //console.table(dependency);

  const dependencyQuestion = survey.question(dependency.question);

  const target = {
    question: dependencyQuestion,
    choice: dependencyQuestion.choice(dependency.choice),
    category: dependency.category,
    type: dependency.type.toLowerCase(),
  };

  switch (target.type) {
    case "question": // Question dependency
      return target.question.isComplete;
    case "choice":
      // Determine how to proceed by question type
      switch (target.question.type.toLowerCase()) {
        case "categorychoice": // CategoryChoice type
          return dependencyQuestion.resolveDependency(question, target);
        default: // Choice Dependency
          return target.choice.isSelected;
      }

    default:
      console.error(`ISSUE: Unrecognized dependency type: ${target.type}`);
      return false;
  }
};

const isChoice = (dependency) => {
  // !DEPRECATED: See if getType(dependency) in views\questions\dependency.js will work
  return dependency.choice != null;
};
const isQuestion = (dependency) => {
  // !DEPRECATED: See if getType(dependency) in views\questions\dependency.js will work
  return dependency.choice == null;
};
export const isSelectedNew = (dependency, survey) => {
  // Find depedency in survey
  if (isQuestion(dependency)) {
    // If question is answered, then return true, otherwise return false?
    return survey.getQuestion(dependency).isComplete;
  }
  if (isChoice(dependency)) {
    const choice = getChoice(survey.questions, dependency).isSelected;
    debugger;
    return choice.isSelected;
  }
};
export const isSelected = (dependency, selectedItems) => {
  if (selectedItems.length === 0 || dependency == null) return false; // Quick abort gate

  for (var i = 0; i < selectedItems.length; i++) {
    if (
      dependency.question.toLowerCase() ===
        selectedItems[i].question.code.toLowerCase() &&
      dependency.choice.toLowerCase() ===
        selectedItems[i].choice.code.toLowerCase()
    ) {
      if (dependency.category == null) {
        // Handle single/multiple question/choice
        return true;
      } else {
        if (dependency.category === selectedItems[i].category) {
          // Handle Generic question/choice/category
          return true;
        }
      }
    }
  }
  return false;
};

export const selectChoice = (selectedItem) => {
  // PURPOSE: Sets the selected choice isSelected == true and the rest to false in the survey.

  /*   const choices = survey.questions.find((question) => {
    return question.code === selectedItem.question.code;
  }).choices; */

  selectedItem.question.setIsSelected(selectedItem.question.choices, false); //resetIsSelected(selectedItem.question.choices, false);

  /*   const choice = choices.find((choice) => {
    return choice.code === selectedItem.choice.code;
  }); */
  selectedItem.choice.isSelected = true;

  return selectedItem.choice;
};
export const toggleChoice = (survey, selectedItem) => {
  // PURPOSE: Takes the selected choice and selects it or unselects it.
  const choices = selectedItem.question.choices;
  const choice = choices.find((choice) => {
    return choice.code === selectedItem.choice.code;
  });
  choice.isSelected = !choice.isSelected;
  return choice;
};
export const getQuestion = (questions, dependency) => {
  const code = resolveCode("question", dependency);
  const question = questions.find((question) => {
    return question.code.toLowerCase() === code.toLowerCase();
  });

  return question;
};
const resolveCode = (type, dependency) => {
  // PURPOSE: Gets code for type between string and object formats.

  switch (typeof dependency[type]) {
    case "string":
      return dependency[type]; // string value
    case "object":
      return dependency[type]?.code;
    default: // Dependency is Question object
      return dependency?.code;
  }
};
export const getChoice = (questions, dependency) => {
  if (dependency.choice == null) return undefined;

  const choiceCode = resolveCode("choice", dependency); //dependency.choice.code ?? dependency.choice;

  const question = getQuestion(questions, dependency);
  const choice = question.choices.find((choice) => {
    //return choice.code.toLowerCase() === dependency.choice.toLowerCase();
    return choice.code.toLowerCase() === choiceCode.toLowerCase();
  });
  return choice;
};

export const getChoicesSelected = (choices) => {
  // PURPOSE: Returns the number of choices that are selected.
  var selected = [];
  for (var i = 0; i < choices.length; i++) {
    if (choices[i].isSelected) {
      selected.push(choices[i]);
    }
  }
  return selected;
};

// #region Survey functions
const surveyAddFunctionality = (survey) => {};
// #endregion Survey functions

// #region Question functions
const questionAddFunctionality = (question) => {
  question.setIsSelected = function (list, state) {
    // PURPOSE: Resets the isSelected property of list members to state (bool).

    for (var i = 0; i < list.length; i++) {
      list[i].isSelected = state;
    }
  };

  question.hasDependencies = () => {
    if (question.dependency != null) {
      return true;
    }
    // Iterate through question choices and check if dependencies contains any elements
    for (var i = 0; i < question.choices.length; i++) {
      if (question.choices[i].dependency != null) {
        return true;
      }
    }
  };

  question.isVisible = !question.hasDependencies();
};
// #endregion Question functions
// #region Question rendering
export const Questions = ({ questions, survey, handleSelected }) => {

  surveyAddFunctionality(survey);
  return questions.map((question, index) => {
    // TODO: Evaluate question and set properties
    questionAddFunctionality(question);
    return (
      <RenderQuestion
        question={question}
        survey={survey}
        handleSelected={handleSelected}
        index={index}
        key={index}
      />
    );
  });
};
const RenderQuestion = ({ question, index, survey, handleSelected }) => {
  utils.log.component(":: RenderQuestion");
  // !DEPRECATED: Use questionType.render() instead
  const { enqueueSnackbar } = useSnackbar();
  const surveyService = new SurveyService(null);
  const questionType = surveyService.typeLookup(question.type).label;

  switch (questionType) {
    case "Single Choice":
      return (
        <SingleChoiceQuestion
          question={question}
          survey={survey}
          handleSelected={handleSelected}
          key={index}
        />
      );

    case "Multiple Choice":
      return (
        <MultipleChoiceQuestion
          question={question}
          survey={survey}
          handleSelected={handleSelected}
          key={index}
        />
      );
    case "Category Choice":
      return (
        <CategoryChoiceQuestion
          question={question}
          survey={survey}
          handleSelected={handleSelected}
          key={index}
        />
      );
    default:
      enqueueSnackbar(`Unrecognized question type: ${questionType}`, {
        variant: "error",
      });
  }
  return <h1>{question.title}</h1>;
};
export const QuestionHeader = ({ question }) => {
  utils.log.component("QuestionHeader", question);
  utils.assert(question !== undefined, "Question is undefined");
  const questionType = config.typeLookup(question.type);
  const isQuestionCompleted  = questionType?.isCompleted(question);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
        style={{display:"flex", justifyContent:"space-between"}}
        >
          <h5>{question.title}</h5>
         {isQuestionCompleted && <div
            style={{
              height: "30px",
              width: "30px",
              background: "#3BDB41",
              borderRadius: "100%",
              display:"flex",
              justifyContent:"center",
              alignItems:"center"
            }}
          >
            <img src={TickIcon} alt="Tick" />
          </div>}
        </div>
        <div
          style={{ color: "#070707B2", marginBottom: "20px" }}
          dangerouslySetInnerHTML={{
            __html: renderHtml(question.description),
          }}
        />
      </div>
    </>
  );
};

export const Choice = ({ choice, noLabel, handleSelected, renderAs }) => {
  const control = [
    { type: "dropdown", value: null }, // TODO: Implement dropdown control
    {
      type: "checkbox",
      value: (
        <Checkbox
          checked={choice.isSelected}
          onChange={() => handleSelected(choice)}
        />
      ),
    },
    {
      type: "radio",
      value: <Radio onChange={() => handleSelected(choice)} />,
    },
    {
      type: "comment",
      value: (
        <>
          <Radio onChange={() => handleSelected(choice)} />
          <TextField label="Other" inputProps={{ maxLength: 5 }}></TextField>
        </>
      ),
    },
  ].find((c) => c.type === renderAs);

  //if (noLabel) return useCheckBox ? Checkbox : Radio;
  if (noLabel) return control;
  else
    return (
      <FormControlLabel
        value={`${choice.valueFormatted?.()}`}
        control={control.value}
        labelPlacement="end"
        label={renderAs === "comment" ? "" : choice.description || choice.value}
        key={`choice_${choice.code}`}
        //onChange={(e) => handleSelected(e, selected, setSelected)}
      />
    );
};

// #endregion Question rendering
/*
FIXED

*/
