import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BlogCard } from "./blogCard";
import { useContext } from "react";
import { AuthContext } from "../../services/context";
import Loading from "../../components/Loading";
import { useState,useEffect } from "react";
import * as utils from "../../services/utilities";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px",
  },
  wrapper: {
    padding: "100px 0px",
  },
  backLink: {
    color: "#007BFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: "20px",
      gap: "20px",
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      margin: "auto",
    },
  },
  blogImage: {
    borderRadius: "10px",
    height: "200px",
    // [theme.breakpoints.down("sm")]:{
    //   display:"none"
    // },
    [theme.breakpoints.down("md")]: {
      width: "400px",
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      margin: "auto",
    },
  },
  blogContent: {
    marginTop: "40px",
    padding: "0 80px",
    fontSize: "19px",
    lineHeight: "1.6",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "10px",
    },
  },
  continueReadingSection: {
    padding: "50px 0px",
  },
  continueReadingText: {
    fontSize: "18px",
    fontWeight: "600",
  },
  relatedBlogs: {
    display: "flex",
    gap: "20px",
    paddingTop: "15px",
  },
  blogCardContainer: {
    width: "400px",
  },
}));

export const BlogDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const blogSlug = useParams()?.id;


  const [loading, setLoading] = useState(false);
  const [allBlogs,setAllBlogs] = useState([]);
  const { noticeService } = useContext(AuthContext);


  const fetchNoticeBlogs = async () => {
    if (!noticeService) {
      utils.log.info("noticeService is not available.");
      return;
    }
    setLoading(true);
    try {
      const allBlogs = await noticeService.getBySection(
        "Blogs",
        "view",
        null,
        true
      );

      utils.log.info(":: all allBlogs",allBlogs);
      const formatedBlogs = allBlogs?.map((blog) =>{

        const slugStart = blog?.title?.indexOf("[");
        const slugEnd = blog?.title?.indexOf("]");
        const slug = blog?.title?.slice(slugStart + 1,slugEnd);
        const imgLinkStart = blog?.title?.indexOf("(");
        const imgLinkEnd = blog?.title?.indexOf(")");
        const imgLink = blog?.title?.slice(imgLinkStart + 1,imgLinkEnd);
        const title = blog?.title?.slice(imgLinkEnd + 1);
        return({
          image:imgLink,
          blogData: 1,
          item: 1,
          title:title,
          description:blog?.message,
          slug:slug,
        })
      })

      utils.log.info(":: all allBlogs formatedBlogs",formatedBlogs);
      setAllBlogs(formatedBlogs);
    } catch (err) {
      utils.log.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNoticeBlogs();
  }, [noticeService]);

  if (loading) return <Loading />;


  const blogDetails =
    Array.isArray(allBlogs) &&
    allBlogs?.find((blog) => blog?.slug === blogSlug);

    function unescapeHTML(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.documentElement.textContent;
    }

  return (
    <Container className={classes.container}>
      <div className={classes.wrapper}>
        <span className={classes.backLink} onClick={() => navigate("/blog")}>
          Back to resources
        </span>
        <div className={classes.headerSection}>
          <h3 className={classes.title}>{blogDetails?.title}</h3>
          <img
            src={blogDetails?.image}
            className={classes.blogImage}
            alt={blogDetails?.title}
          />
        </div>
        <div
          className={classes.blogContent}
          dangerouslySetInnerHTML={{ __html: unescapeHTML(blogDetails?.description) }}
        />
        <div className={classes.continueReadingSection}>
          <span className={classes.continueReadingText}>
            Continue Reading...
          </span>

          <div className={classes.relatedBlogs}>
            {Array.isArray(allBlogs) &&
              allBlogs
                ?.filter((blog) => blog?.slug !== blogSlug)
                ?.map((item) => (
                  <div className={classes.blogCardContainer}>
                    <BlogCard item={item} key={item.slug} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </Container>
  );
};
