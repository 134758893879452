import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import * as utils from "../../services/utilities";

export default function FormSelect({
  label,
  data,
  onChange,
  defaultValue,
  allowNoSelection,
  caseInsensitive,
  debug,
  name,
  type,
  onRemove,
  select,
  ...props
}) {
  // if (data == null) {
  //   utils.log.error("data is required.");
  //   return null;
  // }
  if (debug)
    utils.log.component(
      `FormSelect(label: ${label}) defaultValue: ${defaultValue}`,
      data
    );

  const labelId = `${label}-label`;

  if (allowNoSelection && data.filter((i) => i.value === -1).length === 0)
    data.unshift(allowNoSelection);
  if (debug)
    utils.log.debug("%cFormSelect: ", "color: green;background-color:yellow", {
      label,
      data,
      defaultValue,
    });
  if (allowNoSelection && defaultValue == null)
    defaultValue = allowNoSelection.value;

  const handleChange = (e) => {
    utils.log.event(
      `FormSelect.handleChange(${label}) - value: ${e.target.value} defaultValue: ${defaultValue}`,
      { e, data }
    );
    if (onChange) {
      onChange(e);
    }
  };

  const handleRemove = (item) => {
    if (onRemove) {
      onRemove(item);
    }
  };
  // shrink={data && data.length > 0 ? true : false}
  return (
    <FormControl {...props}>
      <InputLabel  id={labelId}>{label}</InputLabel>
      <Select
        name={name}
        value={props?.value}
        labelId={labelId}
        label={label}
        onChange={handleChange}
        // disabled={!data?.length}
        renderValue={(selectedValue) => {
          const selectedItem = data.find(
            (item) => item.value === selectedValue
          );
          if (type === "add-categories") {
            const selectedItem = data.find((item) => item === selectedValue);
            return selectedItem;
          }
          return selectedItem ? selectedItem.label ?? selectedItem : "";
        }}
      >
        {data?.length > 0 ? (
          data.map((item) => {
            const key = item.value ?? item;
            const value = caseInsensitive
              ? item?.value?.toUpperCase() ?? item.toUpperCase()
              : item.value ?? item;

            return type === "add-categories" ? (
              <MenuItem
                value={value}
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {item.label ?? item}
                {type === "add-categories" && (
                  <RemoveCircleIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(item);
                    }}
                    style={{ cursor: "pointer",color:"grey"}}
                  />
                )}
              </MenuItem>
            ) : (
              <MenuItem value={value} key={key}>
                {item.label ?? item}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""}>{"No Data found"}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
};
