import Typography from "@material-ui/core/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useEffect, useState } from "react";
//import Input from "../../../components/Input/inputs";
import Button from "../../../components/Button";
import Form from "../../../components/Form";
import Grid from "../../../components/Grid";
import FormSelect from "../../../components/Select";
import { AuthContext } from "../../../services/context";
import * as utils from "../../../services/utilities";
import { Selector as WorksiteSelector } from "../../worksites";
import { useSearchParams } from "react-router-dom";

export default function Upload({ survey }) {
  // #region Assertions
  utils.assert(survey != null, "Survey required.");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState();
  const [worksiteCode, setWorksiteCode] = useState(
    searchParams.get("worksiteCode") || ""
  );
  const [format, setFormat] = useState("");
  const [layout, setLayout] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSpinner] = useState(false);

  // #endregion
  // #region Events
  const handleFileSelected = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    utils.log.event("Upload.handleSubmit(e)", event);
    // TODO: 083023 Display upload complete message.
    event.preventDefault();
    try {
      const response = await surveyService.uploadRecipients(
        file,
        survey.code,
        worksiteCode,
        format,
        layout
      );

      if (response.ok) {
        setErrorMessage("Upload complete.");
      } else {
        const result = await response.json();
        setErrorMessage(result.detail);
      }
    } catch (error) {
      debugger;
      setErrorMessage(error);
    }
  };
  // #endregion

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="baseLine"
      >
        <Grid item xs={12}>
          <ImportOptionsSelector
            worksiteCode={worksiteCode}
            format={format}
            layout={layout}
            setWorksiteCode={setWorksiteCode}
            setFormat={setFormat}
            setLayout={setLayout}
          ></ImportOptionsSelector>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" component="label" fullWidth>
            Select File
            <input
              hidden
              accept="text/*"
              type="file"
              onChange={handleFileSelected}
            />
          </Button>
        </Grid>
        <Grid
          item
          hidden={!showSpinner}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="info" />
        </Grid>
        <Grid item xs={12}>
          {file?.name ?? "No file selected"}
        </Grid>
        {errorMessage ?? (
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom color="error" style={{fontFamily:'Public-sans'}}>
              {
                // BUG: Error formatting being applied
              }
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Form onSubmit={handleSubmit}>
            <Button
              type="submit"
              disabled={!(file && format && layout && worksiteCode)}
              fullWidth
            >
              Upload
            </Button>
          </Form>
        </Grid>
      </Grid>
    </>
  );
}

const ImportOptionsSelector = ({
  worksiteCode,
  format,
  layout,
  setWorksiteCode,
  setFormat,
  setLayout,
}) => {
  const { surveyService } = useContext(AuthContext);

  const [importFormats, setImportFormats] = useState([]);
  const [importLayouts, setImportLayouts] = useState([]);

  useEffect(() => {
    if (!importFormats.length)
      surveyService.getImportFormats().then((response) => {
        response.json().then((result) => {
          const list = utils.toSelectItem(result, "description", "code");

          setImportFormats(list);
          if (list.length > 0) {
            setFormat(list.at(0).code);
          }
        });
      });

    if (!importLayouts.length)
      surveyService.getImportLayouts().then((response) => {
        response.json().then((result) => {
          setImportLayouts(utils.toSelectItem(result, "description", "code"));
          if (result.length > 0) {
            setLayout(result.at(0).code);
          }
        });
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WorksiteSelector
          worksiteCode={worksiteCode}
          setWorksiteCode={setWorksiteCode}
        />
      </Grid>
      <Grid item xs={12}>
        {importFormats == null ? (
          "- No import formats found -"
        ) : (
          <FormSelect
            label="Format"
            defaultValue={format}
            data={importFormats}
            fullWidth
            onChange={(event) => {
              setFormat(event.target.value);
            }}
          ></FormSelect>
        )}
      </Grid>
      <Grid item xs={12}>
        {importLayouts == null ? (
          "- No import layouts found -"
        ) : (
          <FormSelect
            label="Layout"
            defaultValue={layout}
            data={importLayouts}
            fullWidth
            onChange={(event) => {
              setLayout(event.target.value);
            }}
          ></FormSelect>
        )}
      </Grid>
    </Grid>
  );
};
