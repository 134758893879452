/* eslint-disable no-unused-vars */
// TODO: Add preview button to each row of the table.
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import loadingImg from "../../assets/loading.gif";
import { DatePicker, TimePicker } from "antd";
import { DialogContent, Slider, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, useSnackbar } from "notistack";
import TimezoneSelect from "react-timezone-select";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import Button from "../../components/Button";
import Form from "../../components/Form";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { NoticeEditor } from "../../components/Notice";
import * as config from "../../services/config";
import { AuthContext } from "../../services/context";
import Debug from "../../services/debug";
import * as utils from "../../services/utilities";
import { Questions } from "../survey-questions";
import * as dependencyUtil from "../survey-questions/dependency";
import { timeFormat } from "../survey-questions/timeInput";
import Emails from "../recipients/management/emails";
import Exemptions from "../recipients/management/exemptions";
import Find from "../recipients/management/find";
import Summary from "../recipients/management/summary";
import Upload from "../recipients/management/upload";
import Detail from "../recipients/detail";
import Chart from "react-apexcharts";
import { HeaderActions } from "../../components/HeaderActions";
import DashboardLayout from "../../components/DashboardLayout";
import { useSearchParams } from "react-router-dom";
import MUIDialog from "../../components/Modal";
import styles from "./style.module.css";
import usePermissions from "../../hooks/usePermission";
import useMediaQuery from "../../hooks/useMediaQuery";
import { CustomWidthTooltip } from "../../components/Tooltip";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { HelpOutlineOutlined } from "@mui/icons-material";
import {
  COLORS,
  customStylesOfTimeZone,
  pieChartData,
  renderCustomizedLabel,
} from "../../utils/surveyUtils";
// import detailAnalyticsGraph from "../../../src/assets/analyticsGraph.svg";
// import arrowUp from "../../../src/assets/redArrowUp.svg";
// import infoIcon from "../../../src/assets/infoIcon.svg";
// import Loading from "../../components/Loading";

const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} h:mm A`; //const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} ${timeFormat}`;
const { RangePicker } = DatePicker;

const columns = [
  { Header: "Code", accessor: "code" },
  { Header: "Name", accessor: "name" },
  {
    Header: "Start",
    accessor: "surveyActivePeriod_EffectiveDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_EffectiveDate
        ? dayjs(row.original.surveyActivePeriod_EffectiveDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
  {
    Header: "End",
    accessor: "surveyActivePeriod_ExpirationDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_ExpirationDate
        ? dayjs(row.original.surveyActivePeriod_ExpirationDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
];

export const SurveyContext = React.createContext({});

export default function Surveys() {
  utils.log.component(":: Survey()");
  const { surveyService, employee } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSurveyCode, setSelectedSurveyCode] = useState(null);
  const [searchParams] = useSearchParams();
  const confirm = useConfirm();
  const { canAddSurvey } = usePermissions();

  const fetchSurveys = async () => {
    try {
      const list = await surveyService.getAll();
      setList(list);
    } catch (error) {
      utils.log.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteSurvey = (surveyCode) => {
    confirm({
      description: `Confirm deletion of survey ${surveyCode}.`,
    })
      .then(() => {
        return surveyService.remove(surveyCode);
      })
      .then(() => {
        setList((prevList) =>
          prevList?.filter((list) => list?.code !== surveyCode)
        );
        setSelectedSurveyCode(null);
        enqueueSnackbar("Survey deleted successfully", {
          variant: "success",
          closeable: true,
        });
      })
      .catch((error) => {
        if (error) {
          utils.log.error("Survey deletion cancelled.");
          enqueueSnackbar(error?.message || "Error deleting employee", {
            variant: "error",
          });
        }
      });
  };

  const handleRowClick = (row) => {
    setSelectedSurveyCode(row?.code);
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  useEffect(() => {
    const querySurveyCode = searchParams.get("surveyCode");
    if (querySurveyCode) {
      fetchSurveyUsingCode(querySurveyCode);
    }
  }, [searchParams]);

  const fetchSurveyUsingCode = async (code) => {
    setIsLoading(true);
    try {
      const survey = await surveyService.getByCode(code);
      let stagedSurvey = survey;
      if (!survey.emailSendTime) {
        stagedSurvey.emailSendTime = config.defaults.SURVEY_EMAIL_SEND_TIME;
      }
      setSelectedSurvey(stagedSurvey);
    } catch (error) {
      utils.log.error(`Error fetching survey (${code})`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const surveyContextValue = useMemo(() => {
    return {
      list,
      setList,
      selectedSurvey,
      setSelectedSurvey,
      handleDeleteSurvey,
      fetchSurveys,
      selectedSurveyCode,
      setSelectedSurveyCode,
    };
  }, [
    list,
    selectedSurvey,
    selectedSurveyCode,
    handleDeleteSurvey,
    fetchSurveys,
  ]);

  // const isSurveyLimitReached = !canAddSurvey(list?.length, employee?.plan);

  const headerAction = (
    <HeaderActions
      buttonTitle={"Add"}
      onAdd={setIsAddModalOpen}
      disabled={false}
      // tooltipTitle={
      //   isSurveyLimitReached ? "maximum survey limit is reached" : ""
      // }
      tourClassName={"onboarding-16"}
    />
  );

  return (
    <SurveyContext.Provider value={surveyContextValue}>
      <DashboardLayout
        title="Surveys"
        description="Easily Manage your surveys from a single place"
        code={"survey-list"}
      >
        {isAddModalOpen && (
          <Add
            open={isAddModalOpen}
            onClose={() => setIsAddModalOpen(false)}
          ></Add>
        )}

        {selectedSurveyCode && <Overview />}

        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => handleRowClick(row.original)}
          headerAction={headerAction}
          isLoading={isLoading}
          filterTourClassName={"onboarding-17"}
          paginationTourClassName={"onboarding-18"}
        ></Table>
      </DashboardLayout>
    </SurveyContext.Provider>
  );
}

export const Overview = () => {
  utils.log.component(":: Survey.Overview()");

  const {
    list: surveys,
    selectedSurvey,
    setSelectedSurvey,
    handleDeleteSurvey,
    fetchSurveys,
    selectedSurveyCode,
    setSelectedSurveyCode,
  } = useContext(SurveyContext);

  const [preview, setPreview] = useState(false);
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isConfirmValidationModalOpen, setIsConfirmValidationModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCommandBarVisible, setIsCommandBarVisible] = useState(true);
  const [validations, setValidations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { surveyService, cache, error } = useContext(AuthContext);
  const { isMdDown, isSmDown, isSmUp } = useMediaQuery();

  // PURPOSE: Validate survey.

  const surveyCodes = surveys
    ?.map((item) => item.code)
    ?.filter((item) => item !== selectedSurveyCode);

  const isValid = () => {
    utils.log.info(
      ":: Suvrey(Overview).handleUpdateSurvey.isValid()",
      error.hasErrors()
    );
    if (error.hasErrors())
      utils.log.error(
        `Validation errors: ${error.hasErrors()}`,
        error.getErrors()
      );
    return !error.hasErrors();
  };

  const fetchSurveyUsingCode = async (code) => {
    setLoading(true);
    try {
      const survey = await surveyService.getByCode(code);
      let stagedSurvey = survey;
      if (!survey.emailSendTime) {
        stagedSurvey.emailSendTime = config.defaults.SURVEY_EMAIL_SEND_TIME;
      }
      cache.setDetails({
        ...stagedSurvey,
        sampleGoalPct: stagedSurvey?.sampleGoalPct || 30,
        skipValue: stagedSurvey?.skipValue || 10,
      });
      setSelectedSurvey(stagedSurvey);
      setQuestions(survey?.questions);
      const { validations } = stagedSurvey;
      setValidations(validations);
    } catch (error) {
      utils.log.error(`Error fetching survey (${code})`, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchValidations = async () => {
    try {
      const payload = { ...cache?.cache };
      if (isValidationLoading && payload) {
        const response = await surveyService.validate(payload);
        if (response?.length > 0) {
          setValidations(response);
        }
      }
    } catch (error) {
      utils.log.error("Overview.fetchValidations()", error);
    } finally {
      setIsValidationLoading(false);
    }
  };

  useEffect(() => {
    fetchValidations();
  }, [isValidationLoading]);

  const handleUpdateSurvey = async (
    event,
    ignoreWarning,
    isPublishFlag = false
  ) => {
    event.preventDefault();
    if (isValid()) {
      setLoading(isPublishFlag ? "publishing" : "updating");
      cache.set("id", selectedSurvey?.id); // inputs["id"] = survey.id;
      if (isPublishFlag) cache.set("isEnabled", selectedSurvey?.isEnabled);
      const requestBody = cache.getDetails();

      delete requestBody.validations;

      try {
        const query = {
          ignoreWarning: ignoreWarning,
        };

        const response = await surveyService.update(
          {
            ...requestBody,
            isEnabled: isPublishFlag
              ? !selectedSurvey?.isEnabled
              : selectedSurvey?.isEnabled,
          },
          query
        );

        setLoading(false);
        // Check for error
        if ("status" in response && response.status !== 200) {
          enqueueSnackbar(
            response?.detail ||
              "Something went wrong while updating the survey",
            { variant: "error" }
          );
          return;
        }

        if (response?.length > 0) {
          setLoading(false);
          setIsConfirmValidationModalOpen(!isConfirmValidationModalOpen);
          setSelectedSurvey({
            ...cache.getDetails(),
            validations: response,
            isEnabled: isPublishFlag
              ? !selectedSurvey?.isEnabled
              : selectedSurvey?.isEnabled,
          });
          return;
        }
        enqueueSnackbar("Success!", { variant: "success" });
        setSelectedSurvey(cache.getDetails());
        setLoading(false);
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
      } finally {
        fetchSurveys();
      }
      error.clearErrorObject();
      setCodeErrorObject([]);
    } else {
      utils.log.error(error);
    }
  };

  const handleCopy = () => {
    utils.log.event("Survey(Overview) handleCopy()");
  };

  const questionsReset = () => {
    utils.log.debug("questionsReset()", questions);
    setQuestions((prevQuestions) => {
      // PURPOSE: Reset questions.
      utils.log.info(
        "questionsReset(setQuestions) prevQuestions:",
        prevQuestions
      );
      const updatedQuestions = prevQuestions
        .filter((prevQuestion) => prevQuestion.hasOwnProperty("choices"))
        .map((prevQuestion) => {
          // Reset selections for choice based questions.
          const updatedChoices = prevQuestion.choices.map((choice) => {
            return { ...choice, isSelected: false };
          });
          // Re-establish dependency references.
          const dependency = dependencyUtil.resolveDependency(
            prevQuestions,
            prevQuestion.dependency
          );
          utils.log.info(
            `question(${prevQuestion.code}): dependency`,
            dependency
          );
          return {
            ...prevQuestion,
            choices: updatedChoices,
          };
        });
      utils.log.info(":: questionReset", updatedQuestions);
      updatedQuestions.forEach((question) => {
        surveyService.typeLookup(question.type).reset(question); // Reset user entered values.
      });
      dependencyUtil.resolveVisibilities(updatedQuestions);
      utils.log.stateChange(
        `questionsReset(questions.IsVisible): `,
        updatedQuestions.filter((q) => q.isVisible)
      );
      return updatedQuestions;
    });
  };

  const handlePreview = (isOpen) => {
    // OnInit: Reset questions.
    utils.log.event(
      `handlePreview(${isOpen}) ${isOpen ? "Open" : "Close"} clicked.`,
      questions
    );
    questionsReset();
    setPreview(isOpen);
  };

  // useEffect(() => {
  //   setQuestions(selectedSurvey?.questions);
  //   cache.set("questions",selectedSurvey?.questions)
  // }, [selectedSurvey?.questions]);

  useEffect(() => {
    fetchSurveyUsingCode(selectedSurveyCode);
  }, []);

  const handleClose = () => {
    setSelectedSurveyCode(null);
    setSelectedSurvey(null);
    setSearchParams({
      view: "surveys",
    });
    cache.clear();
  };
  const surveyManagementActionButtons = (
    <Form
      spacing={2}
      style={{ width: "100%" }}
      onSubmit={(e) => {
        handleUpdateSurvey(e, false);
      }}
    >
      <Grid container spacing={2}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent={isSmDown && "space-between"}
          cursor="pointer"
          gap={isSmDown ? "0px" : "8px"}
          spacing={1}
          xs={12}
          item
          sm={6}
          md={6}
          lg={6}
        >
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedSurvey?.isEnabled ? "green" : "red",
                borderRadius: "8px",
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
              }}
              onClick={(event) => {
                handleUpdateSurvey(event, !selectedSurvey?.isEnabled, true);
              }}
              tabIndex={-1}
            >
              {loading === "publishing"
                ? "Loading..."
                : selectedSurvey?.isEnabled
                ? "UnPublish"
                : "Publish"}
            </Button>{" "}
          </Grid>
          <Grid item>
            <Button
              variant="contained-white"
              style={{
                border: "1px solid black",
                borderRadius: "8px",
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
              }}
              onClick={handleCopy}
              tabIndex={-1}
            >
              COPY
            </Button>
          </Grid>
          <Grid item>
            {isSmDown ? (
              <Button
                style={{
                  backgroundColor: "red",
                  padding: "9px 12px",
                  fontSize: "12px",
                  borderRadius: "8px",
                }}
                onClick={() => handleDeleteSurvey(selectedSurveyCode)}
                tabIndex={-1}
              >
                Delete
              </Button>
            ) : (
              <div
                onClick={() => handleDeleteSurvey(selectedSurveyCode)}
                style={{ cursor: "pointer" }}
              >
                <DeleteOutlineTwoToneIcon
                  sx={{ marginTop: "0px", marginLeft: "-9px" }}
                  color="red"
                  htmlColor="red"
                  fontSize="medium"
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          item
          display="flex"
          alignItems="center"
          gap={isSmDown ? "0px" : "12px"}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          justifyContent={{ xs: "space-between", sm: "flex-end" }}
        >
          <Grid item>
            {/* <Button
              variant="contained-white"
              style={{
                border: isSmDown ? "1px solid red" : "none",
                borderRadius: "8px",
                color: "red",
                padding: isSmDown ? "8px 25px" : "0px 10px",
                fontSize: isMdDown ? "12px" : "14px",
              }}
              onClick={handleClose}
              tabIndex={-1}
            >
              CANCEL
            </Button>{" "} */}
          </Grid>
          <Grid item>
            <Button
              variant="contained-white"
              style={{
                border: "1px solid black",
                borderRadius: "8px",
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
              }}
              onClick={() => handlePreview(true)}
              tabIndex={-1}
            >
              PREVIEW
            </Button>{" "}
          </Grid>
          <Grid item>
            <Button
              style={{
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
                borderRadius: "8px",
              }}
              type="submit"
            >
              {loading === "updating" ? "Loading" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );

 
  const validationErrorActionButtons = (
    <>
      <Button
        onClick={() => setIsConfirmValidationModalOpen(false)}
        tabIndex={-1}
      >
        Cancel
      </Button>{" "}
      <Button onClick={(e) => handleUpdateSurvey(e, true, false)} type="submit">
        {loading === "updating" ? "Loading" : "Update"}
      </Button>
    </>
  );

  return (
    <>
      <MUIDialog
        open={Boolean(selectedSurveyCode)}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        height={isMdDown ? "800px" : isSmDown ? "600px" : "800px"}
        title="Update Survey"
        description=" A survey is a series of questions that either have a choice or input
          that need to be answered by the user."
        actions={surveyManagementActionButtons}
        code={"survey-edit"}
      >
        {!selectedSurvey ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "300px",
            }}
          >
            <img src={loadingImg} width={50} height={50} alt="Loading" />
            <span>Please wait...</span>
          </div>
        ) : (
          <div style={{ marginTop: "0px" }}>
            <Tabs
              defaultActiveTab={
                searchParams.get("activeTab")?.includes("recipients") ? 2 : 0
              }
              style={{ marginTop: "0px" }}
            >
              <Tab
                style={{ marginTop: "0px" }}
                label="Details"
                component={
                  <Details
                    errorObject={codeErrorObject}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                    surveyCodes={surveyCodes}
                    error={error}
                    codeErrorObject={codeErrorObject}
                    setCodeErrorObject={setCodeErrorObject}
                  />
                }
              />
              <Tab
                label="Questions"
                component={
                  <Questions
                    questions={questions}
                    setQuestions={setQuestions}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
              <Tab
                label="Recipients"
                component={
                  <Recipients
                    survey={selectedSurvey}
                    setSurvey={setSelectedSurvey}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
              {/* <Tab label="Configuration" component={<Configuration />} /> */}
              <Tab
                label="Notices"
                component={
                  <Notices
                    setIsCommandBarVisible={setIsCommandBarVisible}
                    type="Survey"
                    id={selectedSurvey?.id}
                  />
                }
              />

              <Tab
                label={`Validations (${validations?.length || 0})`}
                component={
                  <Validations
                    validations={validations}
                    isValidationLoading={isValidationLoading}
                    setIsValidationLoading={setIsValidationLoading}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
            </Tabs>
            <Preview
              survey={selectedSurvey}
              questions={questions}
              setQuestions={setQuestions}
              open={preview}
              handleClose={() => {
                handlePreview(false);
              }}
            ></Preview>
          </div>
        )}
      </MUIDialog>

      {/* Modal to show validations error message  */}
      <MUIDialog
        onClose={() => setIsConfirmValidationModalOpen(false)}
        open={Boolean(isConfirmValidationModalOpen)}
        fullWidth
        maxWidth="md"
        title="Are you sure,you want to update survey with validation error?"
        actions={validationErrorActionButtons}
      >
        <Validations
          validations={validations || []}
          // isValidationLoading={isValidationLoading}
          // setIsValidationLoading={setIsValidationLoading}
          setIsCommandBarVisible={setIsCommandBarVisible}
        />
      </MUIDialog>
    </>
  );
};

export const Validations = ({
  validations,
  isValidationLoading,
  setIsValidationLoading,
  setIsCommandBarVisible,
}) => {
  utils.log.component(":: Survey.Validations()");
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  const columns = [
    { Header: "Category", accessor: "category" },
    { Header: "Source", accessor: "source" },
    { Header: "Property", accessor: "property" },
    { Header: "Summary", accessor: "summary" },
  ];

  useEffect(() => {
    if (!isValidationLoading && setIsValidationLoading) {
      setIsValidationLoading(true);
    }
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isValidationLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "250px",
              }}
            >
              <img src={loadingImg} width={50} height={50} alt="Loading" />
              <span>Please wait...</span>
            </div>
          ) : (
            <Table columns={columns} data={validations} />
          )}
        </Grid>
      </Grid>
    </>
  );
};


const Notices = ({ setIsCommandBarVisible, type, id }) => {
  utils.log.component(":: Survey.Notices()");
  setIsCommandBarVisible(false);
  const sections = [
    "Survey_Recipient_Unavailable",
    "Survey_Recipient_BeforeStart",
    "Survey_Recipient_Completion",
    "Exemption_BeforeStart",
    "Exemption_Completion",
  ];
  return <NoticeEditor sections={sections} type={type} id={id} />;
};
export const Render = (props) => {
  utils.log.component(":: Survey.Render()", props);
  const { isSmDown } = useMediaQuery();
  return (
    <>
      {props?.questions?.map((question, index) => {
        const rowStyle = {
          background: "#FFFFFF",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          margin: "20px 0px",
          padding: "20px 30px",
          display: "flex",
          gap: isSmDown ? "4px" : "30px",
          flexDirection: isSmDown ? "column" : "row",
        };
        const questionType = config.typeLookup(question.type); // Get question type
        utils.log.section(
          `Render ${question.type} Question(${question?.code})})`,
          question
        );
        return questionType.render({
          index: index,
          rowStyle: rowStyle,
          question: question,
          questions: props.questions,
          setQuestions: props.setQuestions,
          survey: props.survey,
          preview: false,
          /*             setSurvey: props.setSurvey, */
          /* questionsResolveVisibility: props.questionsResolveVisibility, */
        });
      })}
    </>
  );
};

Overview.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Details = (props) => {
  utils.log.component("Survey.Details()", props);
  props.setIsCommandBarVisible(true);

  const { selectedSurvey } = useContext(SurveyContext);
  const { cache, surveyService } = useContext(AuthContext);
  const [isOnboarding, setIsOnboarding] = useState(true);
  const [skipValue, setSkipValue] = useState(cache.get("skipValue"));
  const [metricsModal, setMetricsModal] = useState({
    isOpen: false,
    type: "",
    data: [],
    options: {},
  });

  const [startDate, setStartDate] = useState(
    dayjs(cache?.surveyActivePeriod_EffectiveDate) || dayjs()
  );
  const [endDate, setEndDate] = useState(
    dayjs(cache?.surveyActivePeriod_ExpirationDate) || dayjs().add(1, "day")
  );

  const [sampleGoalPct, setSampleGoalPct] = useState(
    cache.get("sampleGoalPct")
  );
  // TODO: Survey.PercentRate property
  const [surveyMetrics, setSurveyMetrics] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(
    selectedSurvey.timeZone ?? utils.getUserTimeZone() ?? {}
  );
  const [surveyDays, setSurveyDays] = useState(
    endDate?.diff(startDate, "days")
  );

  // const [startDate,setStartDate] = useState(null);

  /* When we create survey => timezone && SurveyActive period is null,so When we land on detail page 
   then we update timezone and survey active period*/
  const initializeTimeZone = () => {
    if (!selectedSurvey.timeZone) {
      const userTimeZone = utils.getUserTimeZone();
      cache.set("timeZone", userTimeZone);
      setSelectedTimezone(userTimeZone);
    } else {
      setSelectedTimezone(selectedSurvey.timeZone);
    }
  };

  const initializeSurveyActivePeriod = () => {
    if (!cache?.surveyActivePeriod_EffectiveDate) {
      // cache.set("surveyActivePeriod_EffectiveDate", effectiveDate);
      cache.set("surveyActivePeriod_EffectiveDate", startDate);
    }
    if (!cache?.surveyActivePeriod_ExpirationDate) {
      // cache.set("surveyActivePeriod_ExpirationDate", expirationDate);
      cache.set("surveyActivePeriod_ExpirationDate", endDate);
    }
  };

  const getSurveyMetrics = async () => {
    utils.log.info(":: Survey(Details).getSurveyMetrics()");
    try {
      const metricsData = await surveyService?.getSurveyMetrics(
        selectedSurvey?.code
      );
      if (metricsData?.length > 0) {
        setSurveyMetrics(metricsData);
      }
    } catch (error) {
      utils.log.error("Failed to fetch the survey metrics", error);
    }
  };

  useEffect(() => {
    initializeTimeZone();
    initializeSurveyActivePeriod();
    getSurveyMetrics();
  }, []);

  const handleTimezoneChange = (e) => {
    const timeZone = e.value;
    setSelectedTimezone(timeZone);
    cache.set("timeZone", timeZone);
  };

  const handleSurveyActivePeriodOkClick = (timeRange) => {
    if (timeRange === null) return;

    utils.log.event(`handleSurveyActivePeriodOkClick(timeRange)`, timeRange);
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");
    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
    );

    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
      );
    }
    setStartDate(dayjs(timeRange[0]));
    setEndDate(dayjs(timeRange[1]));
    cache.set(
      "surveyActivePeriod_EffectiveDate",
      dayjs(timeRange[0]).format("YYYY-MM-DDTHH:mm:ssZ")
    );
    cache.set(
      "surveyActivePeriod_ExpirationDate",
      dayjs(timeRange[1]).format("YYYY-MM-DDTHH:mm:ssZ")
    );
    setSurveyDays(dayjs(timeRange[1]).diff(dayjs(timeRange[0]), "days"));
    utils.log.stateChange(
      "setSurveyDays(endDate.diff(startDate, 'days'))",
      surveyDays
    );
  };

  const handleSampleGoalPctChange = (e) => {
    setSampleGoalPct(e.target.value);
    cache.set("sampleGoalPct", e.target.value);
  };

  const handleSampleGoalPctBlur = () => {
    if (sampleGoalPct < 0) {
      setSampleGoalPct(0);
    } else if (sampleGoalPct > 100) {
      setSampleGoalPct(100);
    }
  };

  const handleOpenMetricsModal = (type, data, options) => {
    setMetricsModal({
      isOpen: true,
      type: type,
      data,
      options,
    });
  };

  const handleCloseMetricsModal = () => {
    setMetricsModal({
      isOpen: false,
      type: "",
      data: [],
      options: {},
    });
  };

  const handleClick = (metric) => {
    const dates = metric?.daily?.map((entry) => entry?.date);
    const totals = metric?.daily?.map((entry) => entry?.total);
    const newData = [
      {
        name: metric?.type,
        data: totals || [],
      },
    ];
    handleOpenMetricsModal(metric?.metric, newData, {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: dates,
      },
    });
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      props.error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      props.setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        props.error.getErrorObject(name),
      ]);
    } else if (
      name === "code" &&
      !utils.isUniqueValue(cache.get("code"), props.surveyCodes)
    ) {
      props.error.setErrorObject("code", true, "Code already used.");
      props.setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        props.error.getErrorObject("code"),
      ]);
    } else {
      props.error.clearErrorObject(name);
      const filteredErrors = props.codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      props.setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name } = e.target;
    const updatedErrors = props.codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    props.setCodeErrorObject(updatedErrors);
    cache.set(e);
  };

  const getHelperTextMessage = (name) => {
    return props.codeErrorObject?.find((error) => error?.name === name)
      ?.message;
  };
  const hasError = (name) => {
    return !!props?.codeErrorObject?.find((error) => error?.name === name)
      ?.state;
  };

  const handleSkipValue = (e) => {
    const updatedSkipValue = Number(e.target.value);
    setSkipValue(updatedSkipValue);
    cache.set("skipValue", updatedSkipValue);
  };

  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} md={6}>
        <Input
          required
          label="Code"
          fullWidth
          name="code"
          onChange={handleInputChange}
          defaultValue={cache.get("code")}
          value={cache.get("code")}
          error={hasError("code")}
          helperText={getHelperTextMessage("code")}
          onBlur={handleInputBlur}
        />
      </Grid>
      <Grid item xs={10} sm={11} md={5}>
        <Input
          required
          label="Name"
          fullWidth
          name="name"
          onChange={handleInputChange}
          defaultValue={cache.get("name")}
          value={cache.get("name")}
          error={hasError("name")}
          helperText={getHelperTextMessage("name")}
          onBlur={handleInputBlur}
        />
      </Grid>

      {
        <Grid
          item
          sx={2}
          sm={1}
          md={1}
          paddingBottom={"16px"}
          display={"flex"}
          justifyContent={"end"}
        >
          {isOnboarding ? (
            <CustomWidthTooltip
              code={"survey-question-add"}
              placement={"left-start"}
            >
              <HelpOutlineOutlined
                style={{ cursor: "pointer", marginRight: "15px" }}
                color="primary"
                fontSize="medium"
              />
            </CustomWidthTooltip>
          ) : (
            <HelpOutlineOutlined
              style={{ cursor: "pointer" }}
              color="primary"
              fontSize="medium"
            />
          )}
        </Grid>
      }

      <Grid item xs={12} md={6}>
        Survey Active Period ({surveyDays} days)
        <RangePicker
          style={{ height: "56px", width: "100%" }}
          size="large"
          showTime={{
            format: "h:mm A",
          }}
          defaultValue={[
            dayjs(startDate, dateTimeFormat),
            dayjs(endDate, dateTimeFormat),
          ]}
          format={dateTimeFormat}
          onOk={handleSurveyActivePeriodOkClick}
          getPopupContainer={(triggerNode) => {
            // PURPOSE:  Used to position time picker relative to the question.
            return triggerNode.parentNode;
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ zIndex: "100" }}>
        Location
        <TimezoneSelect
          styles={customStylesOfTimeZone}
          height={"56px"}
          value={selectedTimezone}
          onChange={handleTimezoneChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          type="number"
          label="Skip Value"
          fullWidth
          name={"skipValue"}
          defaultValue={skipValue}
          value={skipValue}
          onChange={handleSkipValue}
        />
      </Grid>
      <Grid item xs={10} md={4} margin={"0px 15px"}>
        Sampling Goal(%)
        <Slider
          name="sampleGoalPct"
          value={sampleGoalPct}
          onChange={handleSampleGoalPctChange}
          aria-labelledby="input-slider"
        />
      </Grid>
      <Grid item xs={2} md={1}>
        <Input
          label="Goal(%)"
          name={"sampleGoalPct"}
          defaultValue={cache.get("sampleGoalPct")}
          value={sampleGoalPct}
          onChange={handleSampleGoalPctChange}
          onBlur={handleSampleGoalPctBlur}
          inputProps={{
            step: 10,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={styles.detailsBarChartWrapper}>
          <div>Progress</div>

          <div className={styles.detailsBarChartContainer}>
            {surveyMetrics &&
              surveyMetrics?.map((metric) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(metric)}
                >
                  <Input
                    label={metric?.metric}
                    disabled
                    value={metric?.total}
                    style={{ pointerEvents: "none" }}
                  ></Input>
                </div>
              ))}
          </div>
        </div>
      </Grid>

      <Grid item xs={5}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="350px" height="350px">
            <Pie
              data={pieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              nameKey="name"
              dataKey="value"
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>

      <MUIDialog
        open={metricsModal.isOpen}
        onClose={handleCloseMetricsModal}
        maxWidth="lg"
        fullWidth
      >
        <Chart
          options={metricsModal.options}
          series={metricsModal?.data || []}
          type="bar"
          width="100%"
          height="400px"
        />
      </MUIDialog>
    </Grid>
  );
};
// const Details = (props) => {
//   utils.log.component("Survey.Details()", props);
//   props.setIsCommandBarVisible(true);
//   const { selectedSurvey } = useContext(SurveyContext);
//   const { cache, surveyService } = useContext(AuthContext);
//   const [isOnboarding, setIsOnboarding] = useState(true);
//   const [skipValue, setSkipValue] = useState(cache.get("skipValue"));
//   const [metricsModal, setMetricsModal] = useState({
//     isOpen: false,
//     type: "",
//     data: [],
//     options: {},
//   });
//   const [startDate, setStartDate] = useState(
//     dayjs(cache?.surveyActivePeriod_EffectiveDate) || dayjs()
//   );
//   const [endDate, setEndDate] = useState(
//     dayjs(cache?.surveyActivePeriod_ExpirationDate) || dayjs().add(1, "day")
//   );
//   const [sampleGoalPct, setSampleGoalPct] = useState(
//     cache.get("sampleGoalPct")
//   );
//   // TODO: Survey.PercentRate property
//   const [surveyMetrics, setSurveyMetrics] = useState([]);
//   const [selectedTimezone, setSelectedTimezone] = useState(
//     selectedSurvey.timeZone ?? utils.getUserTimeZone() ?? {}
//   );
//   const [surveyDays, setSurveyDays] = useState(
//     endDate?.diff(startDate, "days")
//   );
//   // const [startDate,setStartDate] = useState(null);
//   /* When we create survey => timezone && SurveyActive period is null,so When we land on detail page
//    then we update timezone and survey active period*/
//   const initializeTimeZone = () => {
//     if (!selectedSurvey.timeZone) {
//       const userTimeZone = utils.getUserTimeZone();
//       cache.set("timeZone", userTimeZone);
//       setSelectedTimezone(userTimeZone);
//     } else {
//       setSelectedTimezone(selectedSurvey.timeZone);
//     }
//   };
//   const initializeSurveyActivePeriod = () => {
//     if (!cache?.surveyActivePeriod_EffectiveDate) {
//       // cache.set("surveyActivePeriod_EffectiveDate", effectiveDate);
//       cache.set("surveyActivePeriod_EffectiveDate", startDate);
//     }
//     if (!cache?.surveyActivePeriod_ExpirationDate) {
//       // cache.set("surveyActivePeriod_ExpirationDate", expirationDate);
//       cache.set("surveyActivePeriod_ExpirationDate", endDate);
//     }
//   };
//   const getSurveyMetrics = async () => {
//     utils.log.info(":: Survey(Details).getSurveyMetrics()");
//     try {
//       const metricsData = await surveyService?.getSurveyMetrics(
//         selectedSurvey?.code
//       );
//       if (metricsData?.length > 0) {
//         setSurveyMetrics(metricsData);
//       }
//     } catch (error) {
//       utils.log.error("Failed to fetch the survey metrics", error);
//     }
//   };
//   useEffect(() => {
//     initializeTimeZone();
//     initializeSurveyActivePeriod();
//     getSurveyMetrics();
//   }, []);
//   const handleTimezoneChange = (e) => {
//     const timeZone = e.value;
//     setSelectedTimezone(timeZone);
//     cache.set("timeZone", timeZone);
//   };
//   const handleSurveyActivePeriodOkClick = (timeRange) => {
//     if (timeRange === null) return;
//     utils.log.event(`handleSurveyActivePeriodOkClick(timeRange)`, timeRange);
//     utils.assert(timeRange.length === 2, "timeRange.length !== 2");
//     utils.assert(
//       dayjs(timeRange[0]).isValid(),
//       `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
//     );
//     if (timeRange[1] != null) {
//       utils.assert(
//         dayjs(timeRange[1]).isValid(),
//         `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
//       );
//     }
//     setStartDate(dayjs(timeRange[0]));
//     setEndDate(dayjs(timeRange[1]));
//     cache.set(
//       "surveyActivePeriod_EffectiveDate",
//       dayjs(timeRange[0]).format("YYYY-MM-DDTHH:mm:ssZ")
//     );
//     cache.set(
//       "surveyActivePeriod_ExpirationDate",
//       dayjs(timeRange[1]).format("YYYY-MM-DDTHH:mm:ssZ")
//     );
//     setSurveyDays(dayjs(timeRange[1]).diff(dayjs(timeRange[0]), "days"));
//     utils.log.stateChange(
//       "setSurveyDays(endDate.diff(startDate, 'days'))",
//       surveyDays
//     );
//   };
//   const handleSampleGoalPctChange = (e) => {
//     setSampleGoalPct(e.target.value);
//     cache.set("sampleGoalPct", e.target.value);
//   };
//   const handleSampleGoalPctBlur = () => {
//     if (sampleGoalPct < 0) {
//       setSampleGoalPct(0);
//     } else if (sampleGoalPct > 100) {
//       setSampleGoalPct(100);
//     }
//   };
//   const handleOpenMetricsModal = (type, data, options) => {
//     setMetricsModal({
//       isOpen: true,
//       type: type,
//       data,
//       options,
//     });
//   };
//   const handleCloseMetricsModal = () => {
//     setMetricsModal({
//       isOpen: false,
//       type: "",
//       data: [],
//       options: {},
//     });
//   };
//   const handleClick = (metric) => {
//     const dates = metric?.daily?.map((entry) => entry?.date);
//     const totals = metric?.daily?.map((entry) => entry?.total);
//     const newData = [
//       {
//         name: metric?.type,
//         data: totals || [],
//       },
//     ];
//     handleOpenMetricsModal(metric?.metric, newData, {
//       chart: {
//         id: "basic-bar",
//         toolbar: {
//           show: false,
//         },
//       },
//       xaxis: {
//         categories: dates,
//       },
//     });
//   };
//   const handleInputBlur = (e) => {
//     const { name, value } = e.target;
//     if (!value) {
//       props.error.setErrorObject(
//         name,
//         true,
//         `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
//       );
//       props.setCodeErrorObject((prevErrors) => [
//         ...prevErrors,
//         props.error.getErrorObject(name),
//       ]);
//     } else if (
//       name === "code" &&
//       !utils.isUniqueValue(cache.get("code"), props.surveyCodes)
//     ) {
//       props.error.setErrorObject("code", true, "Code already used.");
//       props.setCodeErrorObject((prevErrors) => [
//         ...prevErrors,
//         props.error.getErrorObject("code"),
//       ]);
//     } else {
//       props.error.clearErrorObject(name);
//       const filteredErrors = props.codeErrorObject?.filter(
//         (error) => error?.name !== name
//       );
//       props.setCodeErrorObject(filteredErrors);
//     }
//   };
//   const handleInputChange = function (e) {
//     const { name } = e.target;
//     const updatedErrors = props.codeErrorObject?.filter(
//       (error) => error?.name !== name
//     );
//     props.setCodeErrorObject(updatedErrors);
//     cache.set(e);
//   };
//   const getHelperTextMessage = (name) => {
//     return props.codeErrorObject?.find((error) => error?.name === name)
//       ?.message;
//   };
//   const hasError = (name) => {
//     return !!props?.codeErrorObject?.find((error) => error?.name === name)
//       ?.state;
//   };
//   const handleSkipValue = (e) => {
//     const updatedSkipValue = Number(e.target.value);
//     setSkipValue(updatedSkipValue);
//     cache.set("skipValue", updatedSkipValue);
//   };
//   return (
//     <div className={styles.updateSurveyBg}>
//       <Grid container spacing={3} alignItems="flex-end">
//         <Grid item xs={12}>
//           <div className={styles.detailsBarChartWrapper}>
//             <div className={styles.analytics}>
//               <div className={styles.overallPorgress}>Overall Progress</div>
//               <div className={styles.detailedAnalytics}>
//                 <div>
//                   <img src={detailAnalyticsGraph} alt="detailAnalyticsGraph" />
//                 </div>
//                 <div className={styles.analyticsText}>detailed Analytics</div>
//               </div>
//             </div>
//             <div className={styles.horizontalLine}></div>
//             <div className={styles.detailsBarChartContainer}>
//               {surveyMetrics &&
//                 surveyMetrics?.map((metric) => (
//                   <div
//                     style={{ cursor: "pointer" }}
//                     onClick={() => handleClick(metric)}
//                   >
//                     <div className={styles.overallmetric}>
//                       <div className={styles.metricSection}>
//                         <div className={styles.metricTitle}>
//                           {metric?.metric}
//                         </div>
//                         <div className={styles.metricTotal}>
//                           <div className={styles.total}>{metric?.total}%</div>
//                           <div className={styles.overallChange}>
//                             <span className={styles.numericChange}>+</span>
//                             <span className={styles.numericChange}>0.0</span>
//                             <span className={styles.arrowClasses}>
//                               <img src={arrowUp} alt="arrowUp" />
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     {/* <Input
//                       label={metric?.metric}
//                       disabled
//                       value={metric?.total}
//                       style={{
//                         pointerEvents: "none",
//                         BorderBottom: "none",
//                       }}
//                       variant="standard"
//                     ></Input> */}
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//       <div className={styles.detailsBarChartWrapper}>
//         <div className={styles.updateInfo}>
//           {isOnboarding ? (
//             <CustomWidthTooltip
//               code={"survey-question-add"}
//               placement={"left-start"}
//             >
//               <HelpOutlineOutlined
//                 style={{ cursor: "pointer" }}
//                 color="primary"
//                 fontSize="medium"
//               />
//             </CustomWidthTooltip>
//           ) : (
//             <HelpOutlineOutlined
//               style={{ cursor: "pointer" }}
//               color="primary"
//               fontSize="medium"
//             />
//           )}
//         </div>
//         <Grid container spacing={3} alignItems="flex-end">
//           {/* <Grid item xs={12}>
//         <div className={styles.detailsBarChartWrapper}>
//           <div className={styles.overallPorgress}>Overall Progress</div>
//           <div className={styles.detailsBarChartContainer}>
//             {surveyMetrics &&
//               surveyMetrics?.map((metric) => (
//                 <div
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleClick(metric)}
//                 >
//                   <Input
//                     label={metric?.metric}
//                     disabled
//                     value={metric?.total}
//                     style={{ pointerEvents: "none" }}
//                   ></Input>
//                 </div>
//               ))}
//           </div>
//         </div>
//       </Grid> */}
//           <Grid item xs={12} md={6}>
//             <Input
//               required
//               label="Name"
//               fullWidth
//               name="name"
//               onChange={handleInputChange}
//               defaultValue={cache.get("name")}
//               value={cache.get("name")}
//               error={hasError("name")}
//               helperText={getHelperTextMessage("name")}
//               onBlur={handleInputBlur}
//             />
//           </Grid>
//           <Grid item xs={12} sm={12} md={6}>
//             <Input
//               required
//               label="Code"
//               fullWidth
//               name="code"
//               onChange={handleInputChange}
//               defaultValue={cache.get("code")}
//               value={cache.get("code")}
//               error={hasError("code")}
//               helperText={getHelperTextMessage("code")}
//               onBlur={handleInputBlur}
//             />
//           </Grid>
//           {/* {
//             <Grid
//               item
//               sx={2}
//               sm={1}
//               md={1}
//               paddingBottom={"16px"}
//               display={"flex"}
//               justifyContent={"end"}
//             >
//               {isOnboarding ? (
//                 <CustomWidthTooltip
//                   code={"survey-question-add"}
//                   placement={"left-start"}
//                 >
//                   <HelpOutlineOutlined
//                     style={{ cursor: "pointer", marginRight: "15px" }}
//                     color="primary"
//                     fontSize="medium"
//                   />
//                 </CustomWidthTooltip>
//               ) : (
//                 <HelpOutlineOutlined
//                   style={{ cursor: "pointer" }}
//                   color="primary"
//                   fontSize="medium"
//                 />
//               )}
//             </Grid>
//           } */}
//           <Grid item xs={12} md={6} style={{ zIndex: "100" }}>
//             <div className={styles.location}>
//               <span className={styles.locationText}>Location</span>
//               <div>
//                 <TimezoneSelect
//                   styles={customStylesOfTimeZone}
//                   height={"56px"}
//                   value={selectedTimezone}
//                   onChange={handleTimezoneChange}
//                 />
//               </div>
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className={styles.location}>
//               <span className={styles.locationText}>
//                 Survey Active Period ({surveyDays} days)
//               </span>
//               <RangePicker
//                 style={{ height: "56px", width: "100%" }}
//                 size="large"
//                 showTime={{
//                   format: "h:mm A",
//                 }}
//                 defaultValue={[
//                   dayjs(startDate, dateTimeFormat),
//                   dayjs(endDate, dateTimeFormat),
//                 ]}
//                 format={dateTimeFormat}
//                 onOk={handleSurveyActivePeriodOkClick}
//                 getPopupContainer={(triggerNode) => {
//                   // PURPOSE:  Used to position time picker relative to the question.
//                   return triggerNode.parentNode;
//                 }}
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Input
//               type="number"
//               label="Skip Value"
//               fullWidth
//               name={"skipValue"}
//               defaultValue={skipValue}
//               value={skipValue}
//               onChange={handleSkipValue}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             Sampling Goal(%)
//             <Slider
//               name="sampleGoalPct"
//               value={sampleGoalPct}
//               onChange={handleSampleGoalPctChange}
//               aria-labelledby="input-slider"
//             />
//           </Grid>
//           {/* <Grid item xs={2} md={1}>
//             <Input
//               label="Goal(%)"
//               name={"sampleGoalPct"}
//               defaultValue={cache.get("sampleGoalPct")}
//               value={sampleGoalPct}
//               onChange={handleSampleGoalPctChange}
//               onBlur={handleSampleGoalPctBlur}
//               inputProps={{
//                 step: 10,
//                 min: 0,
//                 max: 100,
//                 type: "number",
//                 "aria-labelledby": "input-slider",
//               }}
//             />
//           </Grid> */}
//           <Grid item xs={5}>
//             <ResponsiveContainer width="100%" height="100%">
//               <PieChart width="350px" height="350px">
//                 <Pie
//                   data={pieChartData}
//                   cx="50%"
//                   cy="50%"
//                   labelLine={false}
//                   label={renderCustomizedLabel}
//                   outerRadius={80}
//                   fill="#8884d8"
//                   nameKey="name"
//                   dataKey="value"
//                 >
//                   {pieChartData.map((entry, index) => (
//                     <Cell
//                       key={`cell-${index}`}
//                       fill={COLORS[index % COLORS.length]}
//                     />
//                   ))}
//                 </Pie>
//               </PieChart>
//             </ResponsiveContainer>
//           </Grid>
//           <MUIDialog
//             open={metricsModal.isOpen}
//             onClose={handleCloseMetricsModal}
//             maxWidth="lg"
//             fullWidth
//           >
//             <Chart
//               options={metricsModal.options}
//               series={metricsModal?.data || []}
//               type="bar"
//               width="100%"
//               height="400px"
//             />
//           </MUIDialog>
//         </Grid>
//       </div>
//     </div>
//   );
// };

const Add = (props) => {
  utils.log.component(":: Survey.Add()");
  const { surveyService, error } = useContext(AuthContext);
  const { fetchSurveys, list: allSurveys } = useContext(SurveyContext);
  const { enqueueSnackbar } = useSnackbar();

  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [inputs, setInputs] = useState({
    code: "",
    name: "",
    surveyActivePeriod_EffectiveDate: dayjs(),
    surveyActivePeriod_ExpirationDate: dayjs().add(1, "day"),
    description: "",
  });

  useEffect(() => {
    utils.log.event("Init: Add survey");
  }, []);

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    utils.log.event("Survey(Add) handleSubmit()");
    event.preventDefault();
    try {
      if (
        dayjs(inputs.surveyActivePeriod_EffectiveDate).isAfter(
          dayjs(inputs.surveyActivePeriod_ExpirationDate)
        )
      ) {
        enqueueSnackbar("Start date cannot be after end date", {
          variant: "error",
          persist: true,
        });
        return;
      }

      const result = await surveyService.add(inputs);

      if (!result.ok) {
        const error = await result.json();
        enqueueSnackbar(error.detail, { variant: "error", persist: true });
      } else {
        // we update get all to get the new survey data
        fetchSurveys();
        enqueueSnackbar("Success!", { variant: "success" });
        props.onClose();
      }
    } catch (error) {
      utils.log.error("Error adding the survey", error);
    }
  };

  const getMinEndDate = () => {
    if (inputs.surveyActivePeriod_EffectiveDate) {
      return dayjs(inputs.surveyActivePeriod_EffectiveDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    return dayjs().add(1, "day").format("YYYY-MM-DD");
  };

  const handleSurveyActivePeriodOkClick = (timeRange) => {
    if (timeRange === null) return;

    utils.log.event(`handleSurveyActivePeriodOkClick(timeRange)`, timeRange);
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");
    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
    );

    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
      );
    }
    setInputs((prev) => ({
      ...prev,
      surveyActivePeriod_EffectiveDate: dayjs(timeRange[0]).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
      surveyActivePeriod_ExpirationDate: dayjs(timeRange[1]).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
    }));
  };

  const { isSmDown, isMdDown } = useMediaQuery();

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const duplicateSurveyCode = allSurveys?.find(
      (survey) => survey?.code?.toLowerCase() === value.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateSurveyCode) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name, value } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };

  return (
    <MUIDialog
      open={props.open}
      onClose={props.onClose}
      title="Add Survey"
      actions={
        <>
          {/* <Button
            variant="contained-white"
            style={{
              border: isSmDown ? "1px solid red" : "none",
              borderRadius: "8px",
              color: "red",
              padding: isSmDown ? "8px 25px" : "0px 10px",
              fontSize: isMdDown ? "12px" : "14px",
            }}
            onClick={props.onClose}
            tabIndex={-1}
          >
            CANCEL
          </Button> */}
          <Button
            style={{
              padding: isMdDown ? "9px 12px" : "9px 25px",
              fontSize: isMdDown ? "12px" : "14px",
              borderRadius: "8px",
            }}
            type="submit"
          >
            ADD
          </Button>
        </>
      }
      height="auto"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} padding="10px 0px">
        <Grid item xs={12} md={4}>
          <Input
            required
            label="Code"
            fullWidth
            name="code"
            onChange={handleInputChange}
            defaultValue={inputs.code}
            value={inputs.code}
            error={hasError("code")}
            helperText={getHelperTextMessage("code")}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Input
            required
            label="Name"
            fullWidth
            name="name"
            onChange={handleInputChange}
            defaultValue={inputs.name}
            value={inputs.name}
            error={hasError("name")}
            helperText={getHelperTextMessage("name")}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <RangePicker
            style={{ height: "56px", width: "100%" }}
            size="large"
            showTime={{ format: "h:mm A" }}
            // defaultValue={[dayjs(inputs.surveyActivePeriod_EffectiveDate, dateTimeFormat),dayjs(inputs.surveyActivePeriod_ExpirationDate, dateTimeFormat)]}
            format={dateTimeFormat}
            onOk={handleSurveyActivePeriodOkClick}
            getPopupContainer={(triggerNode) => {
              // PURPOSE:  Used to position time picker relative to the question.
              return triggerNode.parentNode;
            }}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Input
            required
            label="Start Date"
            name="surveyActivePeriod_EffectiveDate"
            type="date"
            fullWidth
            onChange={handleChange}
            value={inputs.surveyActivePeriod_EffectiveDate}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            required
            label="End Date"
            name="surveyActivePeriod_ExpirationDate"
            type="date"
            fullWidth
            onChange={handleChange}
            value={inputs.surveyActivePeriod_ExpirationDate}
            inputProps={{
              min: getMinEndDate(),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}
        <Grid item xs={12} md={6} marginBottom="220px">
          <Input
            label="Description"
            name="description"
            fullWidth
            onChange={handleChange}
            value={inputs.description}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const Preview = (props) => {
  utils.log.component(":: Survey.Preview()");

  const { cache } = useContext(AuthContext);
  const [previewQuestions, setPreviewQuestions] = useState(
    cache?.cache?.questions
  );

  useEffect(() => {
    // utils.log.info(":: Preview question in useEffect cache",cache)
    // if(cache?.questions?.length > 0) {
    setPreviewQuestions(cache?.cache?.questions);
    // }
  }, [cache]);

  // const formattedQuestions = () => {
  //   utils.log.info(":: Preview question in formattedQuestions previewQuestions",previewQuestions)
  //   if (previewQuestions?.length > 0) {
  //     const temp = [...previewQuestions];
  //     utils.log.info(":: Survey.Render() Preview temp",temp);
  //     dependencyUtil.resolveVisibilities(temp);
  //     return temp.filter((q) => q.isVisible);
  //   }
  //   return [];
  // };

  // const formattedQuestions = formattedQuestionsFunctions();

  utils.log.info(":: Preview question", {
    // previewQuestions,
    ...props?.questions,
    // formattedQuestions,
    // props,
    // ...cache?.cache?.questions
  });

  return (
    <MUIDialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen
      maxWidth="xl"
    >
      <DialogContent style={{ padding: "50px" }}>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            {/* <Button onClick={props.handleClose}>Close</Button> */}
            <Debug value={props}></Debug>
            {/* { formattedQuestions?.length === 0 ? "Please Add Question" : ""} */}
          </Grid>
          <Grid item xs={12}>
            <Render
              survey={props.survey}
              questions={props?.questions}
              setQuestions={props?.setQuestions}
            ></Render>
          </Grid>
        </Grid>
      </DialogContent>
    </MUIDialog>
  );
};
const Recipients = ({ survey, setSurvey, setIsCommandBarVisible }) => {
  utils.log.component(":: Survey.Recipients()");
  // #region Assertions
  utils.assert(survey != null, "Survey required.  Unable to find recipients.");

  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  /* const effectiveDate = dayjs(survey.surveyActivePeriod_EffectiveDate);
  const expirationDate = dayjs(survey.surveyActivePeriod_ExpirationDate);
  const surveyDays = expirationDate.diff(effectiveDate, "days"); */

  const [emailSendTime, setEmailSendTime] = useState(
    survey.emailSendTime ?? config.defaults.SURVEY_EMAIL_SEND_TIME
  );
  const [openEmailRecipientsPreview, setOpenEmailRecipientsPreview] =
    useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!survey.emailSendTime) {
      setSurvey({
        ...survey,
        emailSendTime: config.defaults.SURVEY_EMAIL_SEND_TIME,
      });
    }
  }, [survey, setSurvey]);

  //const [openExemption, setOpenExemption] = useState(false);
  // #endregion
  // #region Events

  const handleCloseEmailRecipientsPreview = () => {
    setOpenEmailRecipientsPreview(false);
  };

  const handleEmailSendTimeChange = (e) => {
    //to handle case when we click on cross icon then we get nothing in e
    if (!e) return;
    const time = e?.format(timeFormat);
    // survey.emailSendTime = time;
    setSurvey({ ...survey, emailSendTime: time });
    setEmailSendTime(time);
    utils.log.stateChange(`setEmailSendTime(${time})`);
  };

  const handlePreviewRecipients = () => {
    utils.log.event("Survey(Recipients) handlePreviewRecipients()");
    setOpenEmailRecipientsPreview(true);
  };
  // #endregion

  return (
    <>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <div style={{ fontSize: "10px" }}>
            <span style={{ color: "red" }}>*</span> Be sure to click UPDATE
            below to save settings.
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={10}>
          Email Schedule
          <br />
          <TimePicker
            use12Hours
            changeOnBlur
            size="large"
            format="h:mm A"
            style={{ height: "55px" }}
            defaultValue={dayjs(emailSendTime, timeFormat)}
            onChange={handleEmailSendTimeChange}
            //disabled={emailSendModeSelected !== "Automatic"}
            placeholder="Send time"
            getPopupContainer={(triggerNode) => {
              // PURPOSE:  Used to position time picker relative to the question.
              return triggerNode.parentNode;
            }}
            renderExtraFooter={() => (
              <div
                style={{
                  color: "blue",
                  marginLeft: "10px",
                  lineHeight: "18px",
                }}
              >
                Set daily time to send email invitations and reminders.
              </div>
            )}
          ></TimePicker>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            tabIndex={-1}
            // fullWidth
            variant="contained"
            className={styles.recipientButton}
            onClick={handlePreviewRecipients}
          >
            Preview
          </Button>
        </Grid>
      </Grid>
      <br></br>
      <Tabs
        defaultActiveTab={
          searchParams.get("activeTab")?.includes("upload") ? 3 : 0
        }
      >
        <Tab label="Summary" component={<Summary survey={survey}></Summary>} />
        <Tab label="Find" component={<Find survey={survey}></Find>} />
        <Tab
          label="Exemptions"
          component={
            <Exemptions
            /*             survey={survey}
            open={openExemption}
            onClose={handleClose} */
            ></Exemptions>
          }
        />
        <Tab label="Upload" component={<Upload survey={survey}></Upload>} />
        <Tab label="Emails" component={<Emails survey={survey}></Emails>}></Tab>
        {/* <Tab
          label="Add"
          tooltip="Add recipients to the survey"
          component={<RecipientAdd survey={survey}></RecipientAdd>}
        ></Tab> */}
      </Tabs>
      {openEmailRecipientsPreview && (
        <EmailRecipientsPreview
          survey={survey}
          isOpen={openEmailRecipientsPreview}
          onClose={handleCloseEmailRecipientsPreview}
          disabled={true}
        ></EmailRecipientsPreview>
      )}
    </>
  );
};

// const Recipients = ({ survey, setSurvey, setIsCommandBarVisible }) => {
//   utils.log.component(":: Survey(Recipients)");

//   // #region Assertions
//   utils.assert(survey != null, "Survey required.  Unable to find recipients.");

//   // #endregion
//   // #region Functions
//   // #endregion
//   // #region Initialize
//   if (setIsCommandBarVisible) setIsCommandBarVisible(true);
//   /* const effectiveDate = dayjs(survey.surveyActivePeriod_EffectiveDate);
//   const expirationDate = dayjs(survey.surveyActivePeriod_ExpirationDate);
//   const surveyDays = expirationDate.diff(effectiveDate, "days"); */

//   const [emailSendTime, setEmailSendTime] = useState(
//     survey.emailSendTime ?? config.defaults.SURVEY_EMAIL_SEND_TIME
//   );
//   const [openEmailRecipientsPreview, setOpenEmailRecipientsPreview] =
//     useState(false);
//   const [searchParams] = useSearchParams();

//   useEffect(() => {
//     if (!survey.emailSendTime) {
//       setSurvey({
//         ...survey,
//         emailSendTime: config.defaults.SURVEY_EMAIL_SEND_TIME,
//       });
//     }
//   }, [survey, setSurvey]);

//   //const [openExemption, setOpenExemption] = useState(false);
//   // #endregion
//   // #region Events

//   const handleCloseEmailRecipientsPreview = () => {
//     setOpenEmailRecipientsPreview(false);
//   };

//   const handleEmailSendTimeChange = (e) => {
//     //to handle case when we click on cross icon then we get nothing in e
//     if (!e) return;
//     const time = e?.format(timeFormat);
//     // survey.emailSendTime = time;
//     setSurvey({ ...survey, emailSendTime: time });
//     setEmailSendTime(time);
//     utils.log.stateChange(`setEmailSendTime(${time})`);
//   };

//   const handlePreviewRecipients = () => {
//     utils.log.event("Survey(Recipients) handlePreviewRecipients()");
//     setOpenEmailRecipientsPreview(true);
//   };
//   // #endregion

//   return (
//     <>
//        <Grid container spacing={2} alignItems={"center"}>
//       <Grid item xs={12} sm={8} md={10}>
//           Email Schedule
//           <br />
//           <TimePicker
//             use12Hours
//             changeOnBlur
//             size="large"
//             format="h:mm A"
//             style={{ height: "55px" }}
//             defaultValue={dayjs(emailSendTime, timeFormat)}
//             onChange={handleEmailSendTimeChange}
//             //disabled={emailSendModeSelected !== "Automatic"}
//             placeholder="Send time"
//             getPopupContainer={(triggerNode) => {
//               // PURPOSE:  Used to position time picker relative to the question.
//               return triggerNode.parentNode;
//             }}
//             renderExtraFooter={() => (
//               <div
//                 style={{
//                   color: "blue",
//                   marginLeft: "10px",
//                   lineHeight: "18px",
//                 }}
//               >
//                 Set daily time to send email invitations and reminders.
//               </div>
//             )}
//           ></TimePicker>
//         </Grid>
//        <Grid item xs={12} sm={4} md={2}>
//           <Button
//             tabIndex={-1}
//             // fullWidth
//             variant="contained"
//             className={styles.recipientButton}
//             onClick={handlePreviewRecipients}
//           >
//             Preview
//           </Button>
//         </Grid>
//       </Grid>

//       <br></br>

//        <Exemptions
//             /*             survey={survey}
//             open={openExemption}
//             onClose={handleClose} */
//             ></Exemptions>

//        <Tabs
//         defaultActiveTab={
//           searchParams.get("activeTab")?.includes("upload") ? 3 : 0
//         }
//       >
//         <Tab label="Summary" component={<Summary survey={survey}></Summary>} />
//         <Tab label="Find" component={<Find survey={survey}></Find>} />
//         <Tab
//           label="Exemptions"
//           component={
//             <Exemptions

//             ></Exemptions>
//           }
//         />
//         <Tab label="Upload" component={<Upload survey={survey}></Upload>} />
//         <Tab label="Emails" component={<Emails survey={survey}></Emails>}></Tab>
//         <Tab
//           label="Add"
//           tooltip="Add recipients to the survey"
//           component={<RecipientAdd survey={survey}></RecipientAdd>}
//         ></Tab>
//       </Tabs>
//       {/* <Summary survey={survey}></Summary> */}

//       {openEmailRecipientsPreview && (
//         <EmailRecipientsPreview
//           survey={survey}
//           isOpen={openEmailRecipientsPreview}
//           onClose={handleCloseEmailRecipientsPreview}
//           disabled={true}
//         ></EmailRecipientsPreview>
//       )}
//     </>
//   );
// };

const Metrics = () => {
  utils.log.component(":: Survey.Metrics()");
  return (
    <>
      <h1>Metrics</h1>
      <Button>Export (CSV, Excel)</Button>
    </>
  );
};
export const SurveySelector = ({
  filter, // string (enabled, disabled, active) required
  selected, // string - required
  setSelected, // function - required
  label = "Survey", // string
  firstItemSelected = "",
}) => {
  utils.log.component(":: Survey.SurveySelector()", {
    filter,
    selected,
    setSelected,
  });
  // #region Assertions
  // #endregion
  // #region Functions
  const updateList = async () => {
    try {
      /*       const result = await surveyService.getAll(filter);
      const data = await result.json();
      if (data.status !== 500) {
        setList(utils.toSelectItem(data, "name", "code"));
        utils.log.stateChange("setList(data)", data);
      } */
      const list = await surveyService.getAll(filter);
      // if (allSurvey) {
      //   const updatedSurvey = [
      //     { label: "All Survey", value: "allSurvey" },
      //     ...utils.toSelectItem(list, "name", "code"),
      //   ];
      //   setList(updatedSurvey);
      //   setSelected(updatedSurvey[0]?.value);
      // } else {
      setList(utils.toSelectItem(list, "name", "code") || []);
      // }
      if (firstItemSelected) {
        setSelected(list[0]?.code);
      }
      utils.log.stateChange("setList(data)", list);
    } catch (error) {
      utils.log.error("Error updating list:", error);
    }
  };
  // #endregion
  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const [list, setList] = useState([]);

  // #endregion
  // #region Events

  useEffect(() => {
    updateList();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
    utils.log.stateChange(
      `SurveySelector.setSelected(event.target.value: ${event.target.value})`
    );
  };

  // if(!firstItemSelected){
  //   return;
  // }
  // #endregion

  return (
    <FormSelect
      label={label}
      data={list}
      fullWidth
      value={selected}
      onChange={(e) => {
        handleChange(e);
      }}
    ></FormSelect>
  );
};
const EmailRecipientsPreview = ({ survey, isOpen, onClose }) => {
  utils.log.component(`:: Survey.EmailRecipientsPreview() isOpen: ${isOpen}`);
  // #region Assertions
  utils.assert(survey != null, "Survey required.");
  // #endregion
  // #region Functions

  // #endregion
  // #region Initialize
  const { surveyService, isDevelopment } = useContext(AuthContext);
  const [recipients, setRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]);

  const filters = utils.toSelectItem([`Invitation`, `Reminder`, `Followup`]);
  const [filterSelected, setFilterSelected] = useState(filters[0].value);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "Email", accessor: "email" },
    /* { Header: "Type", accessor: "messageTemplate" }, */
  ];

  const updateList = async () => {
    try {
      const recipients = await surveyService.recipientsContact(survey.code);
      setRecipients(recipients);
      const filter = filterSelected ?? filters[0].value;
      const filteredRecipients = recipients.filter(
        (item) => item.messageTemplate === filter
      );
      setFilteredRecipients(filteredRecipients);
      utils.log.stateChange(
        `setRecipients(recipients, ${filter})`,
        recipients,
        filteredRecipients
      );
    } catch (error) {
      // Handle any potential errors here
      utils.log.error("Error updating list:", error);
    }
  };

  const handleFilterChange = (e) => {
    utils.log.event("handleFilterChange(e)", e.target);
    const filter = e.target.value;
    setFilterSelected(filter);
    const filteredRecipients = recipients.filter(
      (item) => item.messageTemplate === filter
    );
    setFilteredRecipients(filteredRecipients);
    utils.log.stateChange(`setFilterSelected(${filter})`, filteredRecipients);
  };
  const handleSendEmails = async () => {
    utils.log.event("handleSendEmails()");
    await surveyService.recipientsContact(survey.code, false);
    onClose();
  };

  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("EmailRecipientsPreview", "Initialize");
    updateList();
  }, []);
  // #endregion

  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      title="Email Recipients(Preview)"
      actions={<Button onClick={onClose}>Close</Button>}
    >
      <Grid container spacing={2}>
        {isDevelopment && (
          <Grid item xs={12}>
            <div style={{ color: "red" }}>
              REMINDER: When in DEBUG mode, only a small subset of recipients
              are retrieved.
            </div>
          </Grid>
        )}
        <Grid item container xs={3} justifyContent="flex-end">
          <Button
            tabIndex={-1}
            fullWidth
            variant="contained"
            style={{ height: "55px" }}
            onClick={handleSendEmails}
          >
            Send NOW
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Table
            columns={columns}
            data={filteredRecipients}
            selected
            caption={`Count: ${filteredRecipients.length} Total Recipients: ${recipients.length}`}
          ></Table>
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const RecipientAdd = ({ survey }) => {
  utils.log.component(":: Survey.RecipientAdd()");
  if (!survey) return null;
  return <Detail surveyCode={survey.code} recipient={{}} mode="add" />;
};

/* 
FIXED
051223 - Prior survey details displayed on edit.
  STEPS: 
    1. Select a survey to edit from list.
    2. Note details of survey (e.g. code), then click Cancel or Update button.
    3. Select a different survey to edit from list.
    4. The edit screen displays the details of the prior survey.
    5. Note details of survey (e.g. code), then click Cancel or Update button.
    6. Edit the same survey from step 3.
    7. The edit screen displays the correct details of the survey.
  EXPECTED: The edit screen displays the correct details of the survey.
  CAUSE: Moving setSurveyOpen(true) from getSurvey() to handleEdit() caused extra renders.
  RESOLUTION: Put setSurveyOpen(true) back in getSurvey().
  FIX: Moved setSurveyOpen(true) back to getSurvey().
*/
