import React, { useContext, useState, useMemo, useEffect } from "react";
import { useSnackbar } from "notistack";
import Grid from "../../components/Grid";
import RenderForm from "../../components/Form/RenderForm";
import Button from "../../components/Button";
import { Stack } from "@mui/material";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { SideBar } from "../../components/SideBar";
import { PROFILE_PAGE_TAB_LINKS } from "../../utils/sideBarData";
import { AppContext } from "../../App";
import DashboardLayout from "../../components/DashboardLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { formatPlanData } from "../../utils/formatPlanData";
import PricingCard from "../../components/PricingCard";
import PricingShimmer from "../../components/pricingShimmer";
import styles from "./style.module.css";
import useMediaQuery from "../../hooks/useMediaQuery";
import MUIDialog from "../../components/Modal";
import Input from "../../components/Input/inputs";

const Profile = ({ isSideBarOpen, handleToggleSideBar }) => {
  utils.log.component("Profile");
  const { employee } = useContext(AuthContext);
  const { navBarToggler } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("view") || "details"
  );
  const { isMdDown } = useMediaQuery();
  const handleTabClick = (tab) => {
    if (tab.id === "goBack") {
      navigate("/?view=coordinators");
      return;
    }
    setActiveTab(tab.id);
    setSearchParams({ view: tab.id });
    if (isMdDown) {
      handleToggleSideBar();
    }
  };
  // #endregion

  // #region Functions
  const tabs = useMemo(
    () =>
      PROFILE_PAGE_TAB_LINKS.map((tab) => (
        <div
          key={tab?.id}
          className={`${styles.tabContainer} ${
            activeTab === tab?.id ? styles.selectedTabs : ""
          }`}
          onClick={() => handleTabClick(tab)}
        >
          <div className={`${styles.tabs}`}>
            <div className="tab-label">{tab?.label}</div>
          </div>
        </div>
      )),
    [activeTab]
  );

  const ActiveComponent = useMemo(
    () => PROFILE_PAGE_TAB_LINKS.find((tab) => tab.id === activeTab)?.component,
    [activeTab]
  );
  // #endregion

  if (!employee) {
    return null;
  }

  return (
    <DashboardLayout title="Manage your Profile">
      <div
        className={`${styles.dashboard} ${
          navBarToggler ? styles.dashboardSecondary : ""
        }`}
      >
        {isSideBarOpen && <SideBar tabs={tabs} />}
        <div className={styles.dashboardBody}>{ActiveComponent}</div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;

export const Details = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Details", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Events
  const handleUpdate = async () => {
    utils.log.event("handleUpdate", employee);
    const result = await employeeService.update(cache.getDetails());

    if (!result.ok) {
      const error = await result.json();
      enqueueSnackbar(error.detail, { variant: "error", persist: true });
    } else {
      enqueueSnackbar("Success!", { variant: "success" });
      window.location.reload();
    }
  };
  // #endregion
  // #region Initialize
  const { enqueueSnackbar } = useSnackbar();
  const { employeeService, cache } = useContext(AuthContext);
  const formElements = {
    // spacing: 2,
    data: { ...employee },
    commands: (
      <Grid item xs={12} paddingRight="20px">
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button style={{ padding: "10px 20px" }} onClick={handleUpdate}>
            Update
          </Button>
        </Stack>
      </Grid>
    ),
    items: [
      {
        xs: 12,
        md: 6,
        inputs: [{ label: "First Name", name: "firstName" }],
      },
      {
        xs: 12,
        md: 6,
        inputs: [{ label: "Last Name", name: "lastName" }],
      },
      {
        xs: 12,
        md: 12,
        inputs: [{ label: "Street address", name: "streetAddress" }],
      },
      {
        xs: 12,
        md: 12,
        inputs: [{ label: "Street address2", name: "streetAddress2" }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "State", name: "state" }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "City", name: "city" }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "Zip code", name: "zipCode" }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "UID", name: "uid" }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "Email", name: "email", disabled: true }],
      },
      {
        xs: 12,
        md: 4,
        inputs: [{ label: "Phone", name: "phone" }],
      },
    ],
  };
  // #endregion

  return (
    <div
      style={{
        background: "white",
        borderRadius: "12px",
        margin: "20px",
        border: "1px solid #e9e9e9",

      }}
    >
      <Grid container padding="30px 40px">
        <div style={{display:"flex", padding: "0px 0px 30px 0px",width:"100%" ,justifyContent:"space-between"}}>
          <h4 >Manage Your Profiles</h4>
        <span>{formElements.commands}</span>
        </div>
        <RenderForm formElements={formElements}></RenderForm>
      </Grid>
    </div>
  );
};

export const Plan = () => {
  utils.log.component("Plan");

  const { employee } = useContext(AuthContext);
  const { planService } = useContext(AuthContext);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { plan: activePlan } = employee;

  const fetchPlans = async () => {
    try {
      setIsLoading(true);
      const response = await planService.getSubscriptionPlans();
      if (response) {
        const updatedPlans = formatPlanData(response, false, activePlan);
        setPricingPlans(updatedPlans);
      }
    } catch (error) {
      utils.log.error("Error fetching and setting plans:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handlePayment = async (item) => {
    try {
      const response = await planService.subscribe(item?.code, employee.email);
      if (!response) return;
      const data = await response.json();
      if (data) {
        window.open(data.redirect);
      }
    } catch (error) {
      utils.log.error(error);
    }
  };

  return (
    <div className={styles.pricingPlansContainer}>
      {isLoading ? (
        <PricingShimmer preview={false} />
      ) : (
        <PricingCard pricingPlans={pricingPlans} handlePlan={handlePayment} />
      )}
    </div>
  );
};

export const Organizations = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Organizations", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  const organisations = employee?.organizations || [];
  const [openRename, setRename] = useState(false);

  const columns = [
    { Header: "Organization", accessor: "legalName" },
    { Header: "Code", accessor: "code" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return row?.original?.code === employee?.organization?.code ? (
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            onClick={() => setRename(true)}
          >
            {"Rename Organization"}
          </Button>
        ) : null;
      },
    },
  ];

  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={organisations}
          selected
          onSelected={(row) =>
            utils.log.info(
              "Organisions table row click is not implemented",
              row
            )
          }
        ></Table>
        {openRename && (
          <RenameOrganization
            open={openRename}
            onClose={() => setRename(false)}
            employee={employee}
          />
        )}
      </Grid>
    </Grid>
  );
};

const RenameOrganization = ({ open, onClose, employee }) => {
  const [input, setInput] = useState({
    name: employee?.organization?.legalName,
    code: employee?.organization?.code,
  });

  const { employeeService } = useContext(AuthContext);

  const updateOrganization = () => {
    const response = employeeService?.renameOrganization(
      input?.code,
      input?.name
    );
    utils.log.info(":: updateOrganization", response);
  };

  const { isSmDown, isMdDown } = useMediaQuery();
  const actions = (
    <>
      <Button
        variant="contained-white"
        style={{
          border: isSmDown ? "1px solid red" : "none",
          borderRadius: "8px",
          color: "red",
          padding: isSmDown ? "8px 25px" : "0px 10px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        onClick={onClose}
        tabIndex={-1}
      >
        CANCEL
      </Button>{" "}
      <Button
        style={{
          padding: isMdDown ? "9px 12px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
          borderRadius: "8px",
        }}
        onClick={() => updateOrganization()}
      >
        UPDATE
      </Button>
    </>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Update Organization"
      actions={actions}
    >
      <Grid container spacing={2} padding={"20px 10px"}>
        <Grid item xs={12} sm={6} md={12}>
          <Input
            label="Code"
            fullWidth
            name="code"
            onChange={handleChange}
            defaultValue={input.code}
            required
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Input
            label="Name"
            fullWidth
            name="name"
            onChange={handleChange}
            defaultValue={input.name}
            required
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

export const Messages = () => {
  const { employee } = useContext(AuthContext);
  utils.log.component("Messages", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const [list] = useState([]);
  const columns = [
    { Header: "Sender", accessor: "sender" },
    { Header: "Received", accessor: "dateCreated" },
    { Header: "Subject", accessor: "subject" },
  ];
  // #endregion
  // #region Events
  // #endregion

  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => alert("Not implemented")}
        ></Table>
      </Grid>
    </Grid>
  );
};

export const Billing = () => {
  const { employee, employeeService } = useContext(AuthContext);
  utils.log.component("Billing", employee);
  // #region Assertions
  utils.assert(employee != null, "employee is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const [list, setList] = useState([]);

  const columns = [
    { Header: "Order Id", accessor: "orderId" },
    {
      Header: "Order Date",
      accessor: "orderDate",
      Cell: ({ row }) =>
        row.original.orderDate
          ? dayjs(row.original.orderDate).format("YYYY-MM-DD HH:mm")
          : "N/A",
    },
    { Header: "Description", accessor: "description" },
    { Header: "Code", accessor: "code" },
    { Header: "Amount", accessor: "amount", align: "right" },
    { Header: "Ordered By", accessor: "orderedBy" },
  ];
  const fetchTransactions = async (email) => {
    try {
      const response = await employeeService.getEmployeeTransactions(email);
      if (response) {
        setList(response);
      }
      utils.log.stateChange(`Billing.Init`, list);
    } catch (error) {
      utils.log.error("Error fetching and setting transactions:", error);
    }
  };

  useEffect(() => {
    if (employee) {
      fetchTransactions(employee.email);
    }
  }, [employee]);
  // #endregion
  // #region Events
  // #endregion

  return (
    <Grid container sx={{ padding: "16px 42px 0px 42px" }}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => alert("Not implemented")}
        ></Table>
      </Grid>
    </Grid>
  );
};
