// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES:

*/
import React from "react";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid, FormHelperText } from "@mui/material";
import * as dependencyUtil from "./dependency";
import * as utils from "../../services/utilities";
import { updateQuestionAnswer } from "../../services/question";
import Debug from "../../services/debug";
import { QuestionHeader } from "../recipients/question";
import FormUtility from "../../services/form";
import errorIcon from "../../assets/errorIcons.svg";
// import useMediaQuery from "../../hooks/useMediaQuery";

const formUtility = new FormUtility();
export const comparators = ["equals", "contains", "startsWith", "endsWith"];
export function New() {
  return {
    type: "TextInput",
    code: null,
    title: "New Text Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    minLength: 1,
    maxLength: 10,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  utils.assert(question != null, "question is null.");
  utils.log.component(`TextInput.Reset(${question.code})`, question);
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // #region Initialize

  formUtility?.setDetail(question);
  // TODO: Parameterize value label.
  // Validate min/max length exists
  if (question.minLength == null) question.minLength = 1; //throw new Error("TextInput.Edit(required): MinLength not found.");
  if (question.maxLength == null) question.maxLength = 10; //throw new Error("TextInput.Edit(required): MaxLength not found.");

  // #endregion
  // #region Events
  const handleMinLengthChange = (e) => {
    const value = e.target.value;
    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Minimum must be a number.", { variant: "error" });
    else if (value <= 0)
      // Negative value
      enqueueSnackbar("Minimum must be greater than zero.", {
        variant: "error",
      });
    else {
      // Valid value
      question.minLength = Number(value);
    }
    question.minLength = Number(value);
  };
  const handleMaxLengthChange = (e) => {
    const value = e.target.value;
    const minLength = question.minLength;

    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Maximum must be a number.", { variant: "error" });
    else if (value <= 0)
      // Negative value
      enqueueSnackbar("Maximum must be greater than zero.", {
        variant: "error",
      });
    else if (value < minLength)
      // Greater than max
      enqueueSnackbar("Maximum must be greater than maximum.", {
        variant: "error",
      });
    // Valid value
    else {
      question.maxLength = Number(value);
    }

    question.maxLength = Number(value);
  };

  const handleChange = (e) =>{

    if(e.target.name === "minLength"){
      if(e.target.value >= formUtility.getValue("maxLength")){
        return enqueueSnackbar("Min can't be greater than maximum",{variant:"error"});
      }
    }
    formUtility.handleChange(e, setInputs)
  }
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography id="non-linear-slider" gutterBottom style={{fontFamily:'Public-sans'}}>
          Length:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {/*         <TextField
          label="Minimum"
          defaultValue={minLength}
          onBlur={handleMinLengthChange}
        ></TextField> */}
        <Input
          label="Minimum"
          fullWidth
          name="minLength"
          // onChange={(event) => formUtility.handleChange(event, setInputs)}
          onChange={handleChange}
          onBlur={handleMinLengthChange}
          value={formUtility.getValue("minLength")}
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <TextField label="Maximum" defaultValue={maxLength}></TextField> */}
        <Input
          label="Maximum"
          fullWidth
          name="maxLength"
          // onChange={(event) => formUtility.handleChange(event, setInputs)}
          onChange={handleChange}
          onBlur={handleMaxLengthChange}
          value={formUtility.getValue("maxLength")}
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
          }}
        />
      </Grid>
    </Grid>
  );
}

export const DependencySelector = ({
  context,
  selectedQuestion,
  formUtility,
  inputs,
  setInputs,
  ...rest
}) => {
  // !NOTE: useState() is prohibited in this component.
  // #region Initialize
  utils.log.section("DependencySelector(TextInput)");
  const dependency = dependencyUtil.get(context);
  const list = utils.toSelectItem(comparators);

  //let comparator = dependency?.comparator ?? list[0].value;

  if (dependency && !dependency.value) {
    // Add a value if it doesn't exist
    dependency.comparator = list[0].value;
    dependency.value = null; //setDependency({ ...dependency, comparator: list[0].value, value: "" });
  }
  // #endregion
  // #region Events
  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency.comparator = e.target.value;
    dependencyUtil.set(context, dependency);
    //comparator = dependency.comparator;
  };
  const handleValueChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency.value = e.target.value;
    dependencyUtil.set(context, dependency);
  };

  // #endregion
  return (
    <>
      <hr></hr>
      <Grid container spacing="20">
        <Grid item xs={5}>
          <FormSelect
            id="textInput-comparators"
            label="Comparators"
            data={list}
            defaultValue={dependency?.comparator || null}
            style={{ width: "120px" }}
            onChange={handleComparatorChange}
            debug
          ></FormSelect>{" "}
        </Grid>
        <Grid item xs={6}>
          <Input
            id="textInput-value"
            label="Value"
            name="value"
            style={{ width: "120px" }}
            onChange={handleValueChange}
            /* defaultValue={formUtility.getValue("value")} */
            defaultValue={dependency?.value || dependency?.choice?.value}
            onFocus={(e) => e.currentTarget.select()}
            fullWidth
          ></Input>
        </Grid>
        {/*         <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={handleValueChange}
            endIcon={<CreateIcon></CreateIcon>}
          >
            Set
          </Button>
        </Grid> */}
        <Grid item xs={1}>
          <Debug debugging={dependencyUtil.get(context)}></Debug>
        </Grid>
      </Grid>
    </>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.comparator = comparators[0];
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("TextInput.Render()");
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  const maxLength = question.maxLength ?? 0;
  const textBoxWidth = utils.getTextBoxWidth(maxLength);
  const answer = question.answer ?? ""; //const [value, setValue] = useState("");
  // BUG: 062623 Validation message does not update in preview.  When using state variable, error is caused by dependent isAnswered(presumed).
  // #endregion
  // #region Events
  const handleChange = (e) => {
    const value = e.target.value;
    utils.log.event(`handleChange(e): ${value}`);
    updateQuestionAnswer(question, value, preview, props.setQuestions);
  };


  const isQuestionCompleted = isCompleted(props.question);
  /*eslint-disable-next-line*/
// const {isSmDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{width:"100%",padding:preview ? "30px" : 0,}}>
          {/* <div>{helperText}</div> */}
          <QuestionHeader question={question}></QuestionHeader>
          <Input
            id="textInput-value"
            key={`textInput-value.${question.code}`}
            label="Value"
            name="value"
            onChange={handleChange}
            style={{
              maxWidth: { textBoxWidthFormatted: textBoxWidth },
              width: "300px",
              background: "#F8F8F8",
            }}
          ></Input>
        </div>
      </div>
      
      {answer.length > maxLength && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop:preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            Maximum Length message is reached
          </FormHelperText>
        </div>
      )}
    </>
  );
}
// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  //debugger;
  if (dependency == null) {
    utils.log.info(`TextInput.isAnswered(false): dependency is null`);
    return false;
  }
  utils.log.info(
    `TextInput.isAnswered(${dependency.question.code})`,
    dependency
  );
  // *** Evaluate ***
  switch (dependency.comparator) {
    case "equals":
      return handleComparatorEquals(dependency);
    case "contains":
      return handleComparatorContains(dependency);
    case "startsWith":
      return handleComparatorStartsWith(dependency);
    case "endsWith":
      return handleComparatorEndsWith(dependency);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
function handleComparatorEquals(dependency) {
  return dependency.value === dependency.question.answer;
}
function handleComparatorContains(dependency) {
  return dependency.question.answer?.includes(dependency.value);
}
function handleComparatorStartsWith(dependency) {
  return dependency.question?.answer?.startsWith(dependency.value);
}
function handleComparatorEndsWith(dependency) {
  return dependency.question.answer?.endsWith(dependency.value);
}

export const isCompleted = (question) => {
  utils.log.info(":: Running TextInput.isCompleted()", question);
  if (!question) {
    utils.log.warn("TextInput.isCompleted(false): question is null");
    return false;
  }
  // Also check if the answer is at least the minimum length and atmost the max length
  return (
    question.answer != null &&
    question.answer.length >= question.minLength &&
    question.answer.length <= question.maxLength
  );
};

export const getValue = (question) => {
  return {
    value: question?.answer || "",
  };
};

// #endregion
/* 
FIXED
042123 - DependencySelector - Value property is not being retrieved correctly after earlier set.
  STEPS:
    1. From survey questions, select question that is after a question of type Text Input (e.g. S: TEST Q: Q6).
		2. In Visibility Dependency, select a Text Input type (e.g. S: TEST Q: Q5).
		3. In Comparators dropdown, select an option other than "equals".
    4. Click the Update button.
    5. Exit the question.
    6. Open another question and exit again (need to fix deselection bug).
    7. Re-enter the question.
  EXPECTED    
    * The value should reflect what was entered earlier.
  CHECKS:
    * dependencyUtil.set(context, dependency)
      * Is it setting the dependency correctly in handleComparatorChange()?
        * 042123 (RHYSS) - Verified working correctly.

    * dependencyUtil.get(context)
      * Is it retrieving the dependency correctly?

  CAUSE:
    * In DependencySelector() Initialize region, there was an IF block that was intended
      to set the dependency value to an empty string if it was null. 
      However, it was using a dependencyUtil.create() erroneously.
  RESOLUTION:
    * Changed IF block clause and action taken.
      ORIGINAL:
		if (dependency?.value == null)
			dependencyUtil.set(context, dependencyUtil.create(selectedQuestion, null));
      CHANGE:
		if (dependency != null && dependency?.value == null)
			// Add a value if it doesn't exist
			dependency.value = ""; 
      
042123 - DependencySelector - Comparator dropdown not reflecting choice user just made.
	STEPS: 
		1. From survey questions, select question that is after a question of type Text Input (e.g. S: TEST Q: Q6).
		2. In Visibility Dependency, select a Text Input type (e.g. S: TEST Q: Q5).
		3. In Comparators dropdown, select an option other than "equals".
	EXPECTED
		* Comparator should reflect the choice you just selected (other than "equals").

	CHECKS: 
		context - 
			Single Choice: 	dependencySelector.js --> Selector() --> singleChoice.js -->	DependencySelector() --> 
							dependencySelector.js --> ChoiceSelector() --> handleChoiceChange() --> dependency.js --> set(context, create(selectedQuestion, choice))
			Text Input: 	dependencySelector.js --> Selector() --> textInput.js --> 		DependencySelector() --> handleComparatorChange() --> dependency.js -> set()
		FormSelect() - components/Select/index.js
		handleValueChange() - This is being called by FormSelect() in response to a onChange event in Select component.
		dependencyUtil.set()
			* This method works fine for Questions dropdown and should be working for the specific question type components.  
				* See dependencySelector.js Selector.handleQuestionChange().
			* In handleComparatorChange(), this is working fine as evidenced by selecting something other than equals, then selecting another item while pausing for debugging.  
				dependencyUtil.get() retrieve the dependency with the new comparator selection evident, so it is an issue of dropdown rendering.
	CAUSE: FormSelect() is using defaultValue parameter when the correct one is value.
		defaultValue - Meant to specify the list item initially selected item.
		value - Meant to specify the list item to selected.
	RESOLUTION:
		* In FormSelect() --> Select component
			ORIGINAL: 
			      <Select
			        labelId={labelId}
			        label={label}
			        value={defaultValue} // <-- Problem Area
			        onChange={handleChange}
			      >
			CHANGE
				  <Select
					labelId={labelId}
					label={label}
					value={value} // RPL042123 - Changed from defaultValue to value.
					onChange={handleChange}
				  >		
*/
