/*eslint-disable*/
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogActions } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../Form";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CustomWidthTooltip } from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  dialogActionsStyle: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: "20px",
    },
  },
  iconStyle: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

// const style = {
//   height: "500px",
// };

export default function MUIDialog({
  open,
  onClose,
  fullWidth = true,
  maxWidth = "md",
  height,
  width, 
  title = "",
  description = "",
  children,
  actions,
  onSubmit,
  code = null,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [isOnboarding, setIsOnboarding] = React.useState(true);
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth={fullWidth}
        onClose={onClose}
        maxWidth={maxWidth}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            margin: "10px",
            height: height, 
            width: width,   
          },
        }}
      >
        {title && (
          <DialogTitle
            id="responsive-dialog-title"
            style={{ padding: "16px 24px 0px 24px",borderBottom:"0px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{title}</div>
              {isOnboarding && code ? (
                <CustomWidthTooltip code={code} placement={"left-start"}>
                  <HelpOutlineIcon
                    style={{ cursor: "pointer", marginRight: "25px" }}
                    color="primary"
                    fontSize="medium"
                  />
                </CustomWidthTooltip>
              ) : (
                code && (
                  <HelpOutlineIcon
                    style={{ cursor: "pointer", marginRight: "15px" }}
                    color="primary"
                    fontSize="medium"
                  />
                )
              )}
            </div>
          { description &&  <div style={{fontSize:"15px",fontWeight:400,padding:"4px 0px 10px 0px"}}>{description}</div>}
          </DialogTitle>
        )}
       <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        {onSubmit ? (
          <Form onSubmit={onSubmit}>
            <DialogContent dividers style={{    borderTop: "0",
    paddingTop: "0",
    paddingLeft: "12px",
    paddingRight: "12px"}}>
              {/* {description && (
                <DialogContentText>{description}</DialogContentText>
              )} */}
              {children}
            </DialogContent>
            {actions && (
              <DialogActions dividers className={classes.dialogActionsStyle}>
                {actions}
              </DialogActions>
            )}
          </Form>
        ) : (
          <>
            <DialogContent dividers style={{    borderTop: "0",
    paddingTop: "0",
    paddingLeft: "12px",
    paddingRight: "12px"}}>
              {/* {description && (
                <p
                // style={{ paddingTop: "0px", paddingBottom: "5px" }}
                >
                  {description}
                </p>
              )} */}
              {children}
            </DialogContent>

            {actions && (
              <DialogActions className={classes.dialogActionsStyle}>
                {actions}
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

MUIDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
