import { useEffect, useState } from "react";
import * as utils from "../services/utilities";
import { makeStyles } from "@mui/styles";
import PlanService from "../services/plan";
import PricingCard from "../components/PricingCard";
import { formatPlanData } from "../utils/formatPlanData";
import PricingShimmer from "../components/pricingShimmer";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "200px",
    zIndex: 1,
    // background: "#f5f5f5",
    position: "relative",
  },
}));


export const Pricing = () => {

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const {loginWithRedirect} = useAuth0();

  const fetchPricing = async () => {
    setIsLoading(true);
    try {
      const allPlans = await new PlanService().getSubscriptionPlans();
      const updatePlans = formatPlanData(allPlans,true);
      setPricingPlans(updatePlans);
    } catch (error) {
      utils.log.error("Pricing.fetchPricing()", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  const handlePlan = async (item) => {
    loginWithRedirect();
  };

  return (
    <>
      {isLoading ? (
        <PricingShimmer preview={true}/>
      ) : (
        <div className={classes.wrapper}>
          <div>
            <PricingCard
              pricingPlans={pricingPlans}
              handlePlan={handlePlan}
            />
          </div>
        </div>
      )}
    </>
  );
};
