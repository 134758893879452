import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, Checkbox, Grid ,FormControlLabel} from "@mui/material";
import Button from "../components/Button";
import Form from "../components/Form";
import Tabs, { Tab } from "../components/Tabs";
import Table from "../components/Table";
import Input from "../components/Input/inputs";
import { AuthContext } from "../services/context";
import * as utils from "../services/utilities";
import EmployeeService from "../services/employee";
import PlanService from "../services/plan";
import { Progress } from "./departments";
import { CoordinatorContext } from "../components/contexts/CoordinatorContext";
import { useSnackbar } from "notistack";
import { Status } from "../components/Status";
import DashboardLayout from "../components/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { HeaderActions } from "../components/HeaderActions";
import MUIDialog from "../components/Modal";
import PricingCard from "../components/PricingCard";
import usePermissions from "../hooks/usePermission";
import useMediaQuery from "../hooks/useMediaQuery";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { useConfirm } from "material-ui-confirm";

export default function Coordinators() {
  utils.log.component("Coordinators");

  // #region Initialize

  const [list, setList] = useState([]);
  const [detail, setDetail] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [openOverview, setOpenDetail] = useState(false);
  const [departmentAssignToEmployee, setDepartmentAssignToEmployee] = useState(
    []
  );
  const [departmentUnAssignToEmployee, setDepartmentUnAssignToEmployee] =
    useState([]);
  const [selectedEmployeeUid, setSelectedEmployeeUid] = useState("");
  const [selectedCoordinatorDetail, setSelectedCoordinatorDetail] =
    useState("");

  const navigate = useNavigate();
  const { employeeService, employee } = useContext(AuthContext);
  const { canAddCoordinator } = usePermissions();

  utils.log.info(":: employee info", { employee });

  const handleLicenseClick = (email) => {
    if (email === employee?.email) {
      navigate("/profile?view=plan");
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const capitalize = (str) =>
            str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
          const firstName = row?.original?.firstName
            ? capitalize(row.original.firstName)
            : "";
          const lastName = row?.original?.lastName
            ? capitalize(row.original.lastName)
            : "";
          return `${firstName} ${lastName}`;
        },
      },
      { Header: "Email", accessor: "email" },
      {
        Header: "Roles",
        accessor: "roles",
        align: "center",
        Cell: ({ row }) => {
          const role = row?.original?.roles?.find(
            (role) =>
              role === "PrimaryCoordinator" || role === "DepartmentCoordinator"
          );

          return (
            <div>
              {role === "PrimaryCoordinator" ? (
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    padding: "6px 12px",
                    borderRadius: "12px",
                    color: "#0162C9",
                    border: "1px solid #0162C9",
                  }}
                >
                  PrimaryCoordinator
                </span>
              ) : role === "DepartmentCoordinator" ? (
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    padding: "6px 12px",
                    borderRadius: "10px",
                    color: "#691CD4",
                    border: "1px solid #691CD4",
                  }}
                >
                  DepartmentCoordinator
                </span>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      { Header: "Phone", accessor: "phone" },
      {
        Header: "Subscriptions",
        accessor: "subscriberStatus",
        align: "center",
        Cell: (cell) => {
          const { email } = cell.row.values;
          return (
            <div onClick={() => handleLicenseClick(email)}>
              <Status value={cell.value} />
            </div>
          );
        },
      },
    ],
    []
  );
  // #endregion
  // #region Events

  const updateList = useCallback(async () => {
    try {
      const list = await employeeService.getAll();
      setList(list);
    } catch (error) {
      utils.log.error(error);
    } finally {
      setisLoading(false);
    }
  }, [employeeService]);

  useEffect(() => {
    utils.log.useEffect("Coordinators - updateList()", employee);
    updateList();
  }, [employee]);

  const handleAddOpen = () => {
    // TODO: Add back subscription check
    // if (employee.subscription === null) {
    //   setOpenOrder(true);
    // } else {
    //   setDetail(null);
    //   setOpen(true);
    // }
    setOpenAdd(true);
  };

  const handleClose = () => {
    //setOpenOrder(false);
    setOpenAdd(false);
    setOpenDetail(false);
    setSelectedCoordinatorDetail(null);
  };

  const handleEdit = (update) => {
    setSelectedEmployeeUid(update?.uid);
    setSelectedCoordinatorDetail(update);
    setDetail(update);
    setOpenDetail(true);
  };

  const coordinatorContextValue = useMemo(() => {
    return {
      departmentAssignToEmployee,
      setDepartmentAssignToEmployee,
      selectedEmployeeUid,
      setSelectedEmployeeUid,
      departmentUnAssignToEmployee,
      setDepartmentUnAssignToEmployee,
      updateList,
      selectedCoordinatorDetail,
      setSelectedCoordinatorDetail,
    };
  }, [
    selectedEmployeeUid,
    departmentAssignToEmployee,
    departmentUnAssignToEmployee,
    selectedCoordinatorDetail,
    updateList,
  ]);
  const isCoordinatorLimitReached = !canAddCoordinator(
    list?.length,
    employee?.plan
  );

  const actions = (
    <HeaderActions
      buttonTitle={"Add"}
      onAdd={handleAddOpen}
      disabled={isCoordinatorLimitReached}
      tooltipTitle={
        isCoordinatorLimitReached
          ? "You’ve reached the maximum limit for inviting new users on this platform. Please upgrade your plan to invite more users."
          : ""
      }
      tourClassName={"onboarding-10"}
    />
  );

  // #endregion

  return (
    <CoordinatorContext.Provider value={coordinatorContextValue}>
      <DashboardLayout
        title="Coordinators"
        description="Easily manage all the coordinators from here. This will help you manage access of who are primary and secondary coordinators"
        code={"coordinator-list"}
      >
        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => handleEdit(row.original)}
          isLoading={isLoading}
          headerAction={actions}
          filterTourClassName={"onboarding-11"}
          paginationTourClassName={"onboarding-12"}
        />
      </DashboardLayout>

      {openOverview && (
        <Overview
          open={openOverview}
          onClose={handleClose}
          detail={detail}
          list={list}
          setList={setList}
        />
      )}

      {openAdd && <CoordinatorsAdd open={openAdd} onClose={handleClose} />}
    </CoordinatorContext.Provider>
  );
}

const Overview = ({ open, onClose, detail, list, setList }) => {
  utils.log.component("Coordinators(Overview)", detail);
  // #region Assertions
  utils.assert(onClose != null, "onClose is required");
  utils.assert(open != null, "open is required");
  utils.assert(detail != null, "detail is required");
  // #endregion

  // #region Initialize
  const [coordinator, setCoordinator] = useState(detail);
  const { employeeService, cache, employee } = useContext(AuthContext);
  const {
    departmentAssignToEmployee,
    departmentUnAssignToEmployee,
    selectedEmployeeUid,
    updateList,
  } = useContext(CoordinatorContext);
  const { enqueueSnackbar } = useSnackbar();
  // #endregion

  // #region Events
  useEffect(() => {
    setCoordinator(detail);
  }, [detail]);
  const confirm = useConfirm();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      utils.log.event("Coordinators(Detail).handleSubmit", coordinator);
      if (detail == null) {
        await employeeService.add(coordinator);
        updateList();
        onClose(); // TODO: 021324 Verify this is needed.
      } else {
        cache.getDetails["id"] = detail.id;
        const response = await employeeService.update(coordinator);
        if (response.status === 200) {
          updateList();
          let assignResponse;
          if (departmentAssignToEmployee.length > 0) {
            assignResponse = await employeeService.updateEmployeeDepartments(
              selectedEmployeeUid,
              departmentAssignToEmployee,
              "assign"
            );
          }
          let unAssignResponse;
          if (departmentUnAssignToEmployee.length > 0) {
            unAssignResponse = await employeeService.updateEmployeeDepartments(
              selectedEmployeeUid,
              departmentUnAssignToEmployee,
              "unassign"
            );
          }
          if (
            assignResponse?.status === 200 ||
            unAssignResponse?.status === 200
          ) {
            enqueueSnackbar("Updated successfully", { variant: "success" });
          }
        }
        onClose();
      }
    } catch (error) {
      utils.log.error(error);
    }
  };

  const handleDisableEmployee = async () => {
    const response = await employeeService.disableEmployee(
      detail.uid,
      !detail.isEnabled // if enabled, disable, if disabled, enable
    );
    if (!response.ok) {
      enqueueSnackbar(
        `Error ${detail.isEnabled ? "disabling" : "enabling"} employee`,
        { variant: "error" }
      );
      return;
    }

    updateList();
    onClose();
    enqueueSnackbar(
      `Employee ${detail.isEnabled ? "disabled" : "enabled"} successfully`,
      { variant: "success" }
    );
  };

  const handleDeleteEmployee = () => {
    confirm({
      description: `Confirm deletion of coordinator ${
        detail.firstName + " " + detail.lastName
      }.`,
    })
      .then(() => {
        return employeeService.remove(detail.uid);
      })
      .then(async () => {
        const updatedList = list.filter((item) => item?.uid != detail?.uid);
        setList(updatedList);
        enqueueSnackbar("Coordinator deleted successfully", {
          variant: "success",
        });
        onClose();
      })
      .catch((error) => {
        // under if => on cancel instead of ok of confirm triggering this catch block
        if (error) {
          utils.log.error("Overview.handleDeleteEmployee()", error);
          enqueueSnackbar(error?.message || "Error deleting employee", {
            variant: "error",
          });
        }
      });
  };

  const navigate = useNavigate();
  const { isSmDown, isMdDown } = useMediaQuery();

  const dialogActionButtons = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        flexWrap: "wrap",
        justifyContent: "end",
      }}
    >
      {employee?.email === detail?.email && (
        <Button
          style={{
            borderRadius: "8px",
            padding: isMdDown ? "9px 12px" : "9px 25px",
            fontSize: isMdDown ? "12px" : "14px",
          }}
          onClick={() => navigate("/profile?view=plan")}
        >
          Change Plan
        </Button>
      )}
      <Button
        style={{
          backgroundColor: detail.isEnabled ? "green" : "red",
          borderRadius: "8px",
          padding: isMdDown ? "9px 12px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        onClick={() => handleDisableEmployee()}
      >
        {detail?.isEnabled ? "Enabled" : "Disabled"}
      </Button>
      {/* Logged in user should not be able to delete their own account */}
      {isSmDown && !(employee && employee.id === detail.id) ? (
        <Button
          style={{
            backgroundColor: "red",
            padding: "9px 12px",
            fontSize: "12px",
            borderRadius: "8px",
          }}
          onClick={() => handleDeleteEmployee()}
        >
          Delete
        </Button>
      ) : (
        !(employee && employee.id === detail.id) && (
          <div
            onClick={() => handleDeleteEmployee()}
            style={{ cursor: "pointer" }}
          >
            <DeleteOutlineTwoToneIcon
              sx={{ marginTop: "0px" }}
              color="red"
              htmlColor="red"
              fontSize="medium"
            />
          </div>
        )
      )}
      {/* <Button
        variant="contained-white"
        style={{
          border: isSmDown ? "1px solid red" : "none",
          borderRadius: "8px",
          color: "red",
          padding: isSmDown ? "8px 25px" : "0px 0px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        onClick={onClose}
      >
        CANCELEDD
      </Button> */}
      <Button
        style={{
          padding: isMdDown ? "9px 12px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
          borderRadius: "8px",
        }}
        onClick={handleSubmit}
      >
        {detail == null ? "ADD" : "UPDATE"}
      </Button>
    </div>
  );

  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        title={`${detail == null ? "Add" : "Update"} Coordinator`}
        description=" A coordinator follows up with survey recipients to get them to
    complete the survey or be excluded from it."
        actions={dialogActionButtons}
        // PaperProps={{ style: { height: "600px", margin: "50px" } }}
        height={"600px"}
      >
        {/* add centered here */}
        <Tabs centered={true}>
          <Tab
            label="Detail"
            component={
              <Detail
                detail={detail}
                coordinator={coordinator}
                setCoordinator={setCoordinator}
                handleSubmit={handleSubmit}
              />
            }
          />
          <Tab label="Assignments" component={<Assignments />} />
        </Tabs>
      </MUIDialog>
    </>
  );
};

const Detail = ({ coordinator, setCoordinator, handleSubmit }) => {
  utils.log.component("Coordinators(Detail)", coordinator);
  // #region Assertions
  utils.assert(coordinator != null, "coordinator is required");
  utils.assert(setCoordinator != null, "setCoordinator is required");
  utils.assert(handleSubmit != null, "handleSubmit is required");

  // #endregion
  // #region Initialize
  // const { employeeService } = useContext(AuthContext);
  // #endregion
  // #region Events

  const handlePrimaryCoordinatorSet = async (e) => {
    utils.log.event("Detail.handlePrimaryCoordinatorSet", coordinator);

    const isChecked = e.target.checked;
    const { roles = [] } = coordinator || {};
    const hasPrimaryCoordinatorRole = roles.includes("PrimaryCoordinator");

    const updatedRoles = isChecked
      ? hasPrimaryCoordinatorRole
        ? roles
        : [...roles, "PrimaryCoordinator"]
      : roles.filter((role) => role !== "PrimaryCoordinator");

    setCoordinator((prev) => ({ ...prev, roles: updatedRoles }));
  };

  return (
    <Form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            label="First Name"
            fullWidth
            name="firstName"
            onChange={(e) =>
              setCoordinator({
                ...coordinator,
                firstName: e.target.value,
              })
            }
            value={coordinator?.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label="Last Name"
            name="lastName"
            fullWidth
            onChange={(e) =>
              setCoordinator({ ...coordinator, lastName: e.target.value })
            }
            value={coordinator?.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Input
            label="UID"
            name="uid"
            fullWidth
            onChange={(e) =>
              setCoordinator({ ...coordinator, uid: e.target.value })
            }
            value={coordinator?.uid}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            label="Email"
            name="email"
            fullWidth
            onChange={(e) =>
              setCoordinator({ ...coordinator, email: e.target.value })
            }
            value={coordinator?.email}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            type={"number"}
            label="Phone"
            name="phone"
            fullWidth
            hideNumberSpinner
            onChange={(e) =>
              setCoordinator({ ...coordinator, phone: e.target.value })
            }
            value={coordinator?.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={coordinator?.roles?.includes(
                  "PrimaryCoordinator"
                )}
                onChange={handlePrimaryCoordinatorSet}
              />
            }
            label="Primary Coordinator"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

const Assignments = () => {
  const [allDepartments, setAllDepartments] = useState([]);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [employeeDepartments, setEmployeeDepartments] = useState([]);
  const {
    setDepartmentAssignToEmployee,
    setDepartmentUnAssignToEmployee,
    selectedCoordinatorDetail,
  } = useContext(CoordinatorContext);

  useEffect(() => {
    const updatedIndex = {};
    allDepartments?.forEach((department, index) => {
      if (department?.isAssigned) {
        updatedIndex[index] = true;
      }
    });
    setSelectedRowsIndex(updatedIndex);
  }, [allDepartments]);

  useEffect(() => {
    if (
      !selectedCoordinatorDetail ||
      !selectedDepartments ||
      !employeeDepartments
    )
      return;

    const selectedDepartmentCodes = selectedDepartments.map(
      (department) => department.code
    );

    const departmentsToAssign = selectedDepartments
      .filter((department) => !department.isAssigned)
      .map((department) => department.code);

    const departmentsToUnAssign = employeeDepartments
      .filter(
        (dept) =>
          dept.isAssigned && !selectedDepartmentCodes.includes(dept.code)
      )
      .map((dept) => dept.code);

    setDepartmentAssignToEmployee?.(departmentsToAssign);
    setDepartmentUnAssignToEmployee?.(departmentsToUnAssign);
  }, [selectedDepartments, employeeDepartments]);

  const handleSelectedRows = (rows) => {
    setSelectedDepartments(rows);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Progress
          coordinator={selectedCoordinatorDetail}
          allDepartments={allDepartments}
          setAllDepartments={setAllDepartments}
          setEmployeeDepartments={setEmployeeDepartments}
          selectedRowsIndex={selectedRowsIndex}
          handleSelectedRows={handleSelectedRows}
        />
      </Grid>
    </Grid>
  );
};

// const Assignments_OLD = ({ coordinator }) => {
//   // #region Assertions
//   // #endregion
//   // #region Functions
//   // #endregion
//   // #region Initialize
//   const { employeeService, employee } = useContext(AuthContext);
//   const columns = useMemo(
//     () => [
//       { Header: "Code", accessor: "code" },
//       { Header: "Name", accessor: "name" },
//       /* { Header: "Employees", accessor: "employeeCount" }, */ // ISSUE: Total is inaccurate and counts employees multiple times without survey code.
//       { Header: "Worksites", accessor: "worksite" },
//       { Header: "Coordinator", accessor: "coordinator" },
//     ],
//     []
//   );
//   // #endregion
//   // #region Events
//   // #endregion

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <DepartmentSelector
//           columns={columns}
//           coordinator={coordinator}
//         ></DepartmentSelector>
//       </Grid>
//     </Grid>
//   );
// };

// Detail.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
// };

const CoordinatorsAdd = ({ open, onClose }) => {
  utils.log.component("CoordinatorsAdd");
  // #region Assertions
  utils.assert(open != null, "open is required");
  utils.assert(onClose != null, "onClose is required");
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  // const classes = useStyles();
  const { employeeService } = useContext(AuthContext);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { updateList } = useContext(CoordinatorContext);
  // #endregion
  // #region Events
  const handleInputChange = (e) => {
    utils.log.event("CoordinatorsAdd.handleInputChange");
    const lines = e.target.value.split(/\r?\n/);
    const updatedEmails = lines.filter((l) => l.trim() !== "");

    utils.log.debug("Updated Emails", updatedEmails);
    const isAnyEmailInvalid = updatedEmails.some((e) => {
      return !utils.validateEmail(e);
    });

    if (isAnyEmailInvalid) {
      setError("One or more emails is invalid");
    } else {
      setError("");
    }

    setEmails(updatedEmails);
  };

  const handleCoordinatorsInvite = async () => {
    utils.log.event("CoordinatorsAdd.handleCoordinatorsInvite", emails);
    if (!emails.length) return;

    try {
      setLoading(true);
      const response = await employeeService.invite(emails);
      if (response) {
        updateList();
        setEmails([]);
        onClose();
        setError("");
      }
    } catch (error) {
      console.error("Errors", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleCancel = () => {
  //   utils.log.event("CoordinatorsAdd.handleCancel");
  //   setEmails([]);
  //   setError("");
  //   onClose();
  // };

  const { isMdDown, isSmDown } = useMediaQuery();

  const actions = (
    <>
      {/* <Button
        style={{
          border: isSmDown ? "1px solid red" : "none",
          borderRadius: "8px",
          color: "red",
          padding: isSmDown ? "8px 25px" : "0px 10px",
          fontSize: isMdDown ? "12px" : "14px",
        }}
        variant="contained-white"
        onClick={handleCancel}
        disabled={loading}
      >
        CANCEL
      </Button> */}
      <Button
        style={{
          padding: isMdDown ? "8px 25px" : "9px 25px",
          fontSize: isMdDown ? "12px" : "14px",
          borderRadius: "8px",
          fontWeight: 600,
        }}
        variant="contained"
        disabled={Boolean(error) || loading}
        onClick={handleCoordinatorsInvite}
      >
        {loading ? "PLEASE WAIT ..." : "INVITE"}
      </Button>
    </>
  );
  // #endregion

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Add Coordinator"
      description="Enter the email address of all employees you want to invite as coordinators"
      actions={actions}
      // PaperProps={{
      //   style: {
      //     height: isSmDown ? "500px" : "450px",
      //     width: "600px",
      //     margin: "50px",
      //   },
      // }}
      height={isSmDown ? "500px" : "450px"}
      width={"600px"}
    >
      <div style={{ padding: "15px 15px" }}>
        <Input
          multiline
          label="Emails"
          fullWidth
          rows="10"
          onChange={handleInputChange}
          error={Boolean(error)}
          helperText={error}
          disabled={loading}
        ></Input>
      </div>
      {/*           <Autocomplete
            value={emails}
            onChange={(e) => handleInputChange(e)}
            classes={{
              root: classes.emailsContainer,
            }}
            clearIcon={false}
            multiple
            options={[]}
            defaultValue={[]}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  color={!utils.validateEmail(option) ? "error" : "default"}
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <Input
                classes={{ root: classes.emailInput }}
                {...params}
                variant="outlined"
                error={Boolean(error)}
                helperText={error}
              />
            )}
          /> */}
    </MUIDialog>
  );
};

export const CoordinatorsActivate = () => {
  // #region Assertions
  // #endregion
  // #region Functions
  const fetchPlans = async () => {
    try {
      utils.log.debug("fetchPlans", plans);
      const planResponse = await new PlanService().getSubscriptionPlans();
      setPlans(planResponse);

      utils.log.stateChange(`PlanSelector.Init`, plans);
    } catch (error) {
      utils.log.error("Error fetching and setting plans:", error);
    }
  };
  // #endregion
  // #region Initialize
  const classes = useStyles();
  const [isActivating, setIsActivating] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activateEmployeeError, setActivateEmployeeError] = useState(false);
  /*eslint-disable-next-line*/
  const [activateEmployee, setActivateEmployee] = useState({});
  const [plans, setPlans] = useState([]);
  const accessCode = utils.getQuerystring("accessCode");
  const email = utils.getQuerystring("email");
  // #endregion
  // #region Events
  useEffect(() => {
    utils.log.useEffect("CoordinatorsActivate.Init");
    handleEmployeeActivate();
    fetchPlans();
  }, []); // On Initialize
  // const handleEmployeeActivate_DEPRECATED_20260623 = async () => {
  //   setIsActivating(true);
  //   try {
  //     const activateResponse = await new EmployeeService().activate(
  //       email,
  //       accessCode
  //     );

  //     const data = await activateResponse.json();

  //     if (!data || activateResponse?.status === 200) {
  //       setActivateEmployee(data);
  //     } else {
  //       setActivateEmployeeError(true);
  //       setIsModalOpen(true);
  //     }
  //   } catch (error) {
  //     setIsModalOpen(true);
  //     utils.log.error(error);
  //   } finally {
  //     setIsActivating(false);
  //   }
  // };
  const handleEmployeeActivate = async () => {
    debugger;
    setIsActivating(true);
    try {
      const coordinator = await new EmployeeService().activate(
        email,
        accessCode
      );

      if (!coordinator) {
        setActivateEmployee(coordinator);
      } else {
        setActivateEmployeeError(true);
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsModalOpen(true);
      utils.log.error(error);
    } finally {
      setIsActivating(false);
    }
  };
  // #endregion
  if (isActivating) return null;

  return (
    <>
      {activateEmployeeError ? (
        <Dialog open={isModalOpen}>
          <div className={classes.dialogWrapper}>
            <div className={classes.title}>
              <h4>Invalid Credentials</h4>
            </div>
            <DialogContent>
              <p>Your credentials are invalid.</p>
              <p>Please call your coordinator.</p>
            </DialogContent>
          </div>
        </Dialog>
      ) : (
        <div style={{ padding: "100px 20px" }}>
          <PricingCard
            pricingPlans={plans.filter((plan) => {
              return (
                plan.code === "Subscription(Monthly)" ||
                plan.code === "Subscription(Annual)"
              );
            })}
            handlePlan={() =>
              utils.log.info("CoordinatorsActivate.handlePlan()")
            }
          />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "600px",
  },
  contentContainer: {
    padding: theme.spacing(1, 2.5),
  },
  dialogWrapper: {
    width: "500px",
    height: "200px",
    "& p": {
      margin: 0,
      fontWeight: 600,
      fontSize: 18,
      color: "red",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
  },
  title: {
    marginLeft: "42px",
    fontFamily: "Public-sans",
    fontWeight: "400",
    fontSize: "16px",
    "& p": {
      margin: 0,
      fontWeight: 700,
      fontSize: 18,
    },
  },
  emailsContainer: {
    height: 250,
    overflow: "auto",
    width: "100%",
  },
  emailInput: {
    height: "100%",
    "& .MuiOutlinedInput-root": {
      height: "100%",
      alignItems: "flex-start",
      alignContent: "flex-start",
    },
  },
  actionButtons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  header: {
    color: "#070707",
    fontWeight: "700",
    fontFamily: "Public-sans",
  },
}));
