import { getApiOrigin } from "./context";
import * as utils from "./utilities";
import * as config from "./config";
import {
  checkedCount,
  valueFormatted,
} from "../views/survey-questions/choices";
export default class SurveyService {
  constructor(headers) {
    this.headers = headers;
  }

  async add(survey) {
    utils.log.api("survey.add(survey)", survey);
    utils.assert(survey != null, "survey is null.");
    try {
      const remoteUrl = `${getApiOrigin()}/survey/add`;
      return await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(survey),
      });
    } catch (error) {
      debugger;
    }
  }

  async get(id) {
    utils.log.api(`survey.get(${id})`);
    const remoteUrl = `${getApiOrigin()}/survey/getById/${id}?viewModel=Survey.ViewModels.Detail`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
    const survey = await response.json();

    this.surveyPrepare(survey);
    utils.log.debug(`survey.get(${id}) result`, survey);
    return survey;
  }

  async getByCode(code) {
    utils.log.api(`survey.getByCode(${code})`);
    const remoteUrl = `${getApiOrigin()}/survey/getByCode/${code}?viewModel=Survey.ViewModels.Detail`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
    const survey = await response.json();

    this.surveyPrepare(survey);
    utils.log.debug(`survey.getByCode(${code}) result`, survey);
    return survey;
  }
  surveyPrepare(survey) {
    // PURPOSE: Prepare survey object for use in application.
    utils.log.info("survey.surveyPrepare() BEFORE", survey);
    this.surveyAddFunctions(survey);
    this.surveyRestoreCrossReferences(survey);
    this.surveyDependencyFunctions(survey);
    utils.log.info("survey.surveyPrepare() AFTER", survey);
  }
  surveyAddFunctions(survey) {
    // *** PURPOSE: Functions for Survey object. ***

    survey.questions.survey = function () {
      return survey;
    };
    survey.questions.get = (code) => {
      // PURPOSE: Gets question by code.
      const question = survey.questions?.find((item) => item.code === code);
      if (question == null) throw new Error(`Question ${code} not found.`);
      return question;
    };
    survey.questions.reset = () => {
      survey.questions.forEach((question) => {
        question.reset();
      });
    };
    survey.question = (code) => {
      const question = survey.questions.get(code);

      question.choice = (choiceCode) => {
        const choice = question.choices.get(choiceCode);
        return choice;
      };

      return question;
    };

    this.surveyAddFunctions_questions(survey.questions);
  }
  surveyAddFunctions_questions(questions) {
    const survey = questions.survey();
    questions?.forEach((question) => {
      // PURPOSE: Functions for each question.

      question.survey = function () {
        return survey;
      };
      if (question.choices != null) {
        // PURPOSE: Choice collection functions.
        question.choices.survey = function () {
          return survey;
        };
        question.choices.question = function () {
          return question;
        };
        question.choice = (choiceCode) => {
          // PURPOSE: Define a new function for the 'choice' property
          const choice = question.choices.get(choiceCode);
          return choice;
        };
        question.choices.get = (code) => {
          // PURPOSE: Gets choice by code.
          const choice = question.choices?.find((item) => item.code === code);
          if (choice == null) throw new Error(`Choice ${code} not found.`);
          return choice;
        };
        question.reset = () => {
          // PURPOSE: Reset question answer.
          question.isVisible = false;
          question.choices.reset();
        };
        question.choices.reset = (value = false) => {
          // PURPOSE: Reset all choice answers.
          question.choices.forEach((choice) => {
            choice.isSelected = value;
          });
        };
        question.choices.checkedCount = () => {
          return checkedCount(question.choices);
        };
        question.choices.getChecked = () => {
          // PURPOSE: Return an array of checked choices.
          const checked = question.choices.filter(
            (choice) => choice.isSelected
          );
          return checked;
        };
        question.choices.setChecked = (code, value) => {
          const choice = question.choices.get(code);
          choice.isSelected = value;
          const count = question.choices.filter((choice) => choice.isSelected);
          return count;
        };

        question.choices.forEach((choice, defaultValue = "") => {
          // PURPOSE: Functions for each choice.
          choice.question = function () {
            return question;
          };
          choice.valueFormatted = function (delimeter = "|") {
            const value = valueFormatted(choice, delimeter);
            return value ?? defaultValue;
          };
        });
      }
    });
  }

  surveyRestoreCrossReferences(survey) {
    // PURPOSE: Restores references to dependency questions and choices after getting survey from server.
    survey.questions?.forEach((question) => {
      question.dependency = this.surveyDependencyParse(
        survey,
        question.dependency
      );
      question.choices?.forEach((choice) => {
        choice.dependency = this.surveyDependencyParse(
          survey,
          choice.dependency
        );
      });
    });
  }
  surveyRemoveMethods(survey) {
    // PURPOSE: Removes references to dependency questions and choices before sending survey to server.
    // Iterate through each survey property and remove functions.

    utils?.removeFunctions?.(survey);

    return survey;
  }
  surveyDependencyParse(survey, rawDependency) {
    // PURPOSE: Restores references to dependency questions.
    if (rawDependency == null) return null;
    const dependency =
      typeof rawDependency == "object"
        ? rawDependency
        : typeof JSON.parse(rawDependency) == "object"
        ? JSON.parse(rawDependency)
        : JSON.parse(JSON.parse(rawDependency)); // !KLUDGE - See notes below
    /* !KLUDGE NOTES:  
          For some reason, returned values for choice dependency escapes the quotes.  
          * JSON is stored correctly.
          * RawJsonValueConverter.Read() method is introducting the escaped single quotes (\\u0022)
          * Caused by calling serializer twice:
            1. Domain.Entities.Survey.QuestionLoad() - To deserialize Questions collection.
              NOTE: REFLECT MORE ON THIS.  Why would Deserializing to a type cause this?
            2. API.Controllers.SurveyController.Get(int) - To serialize the whole thing.

            RECOMMENDATION:  
            * Leave as-is.
            * Keep kludge in place.
          */

    dependency.question = survey.questions.get(dependency.question.code);
    if (dependency.choice != null)
      dependency.choice = survey.questions
        .get(dependency.question.code)
        .choices.get(dependency.choice?.code);

    return dependency;
  }
  surveyDependencyFunctions(survey) {
    // PURPOSE: Functions for question/choice dependencies
    const typeLookup = this.typeLookup.bind(this); // Bind typeLookup method to class instance
    survey.getDependents = (dependency) => {
      // PURPOSE: Get questions/choices that dependendent on this dependency(question/choice).
      utils.required(dependency);

      const dependents = new Set();
      survey.questions.forEach((question) => {
        if (question.hasDependency(dependency)) dependents.add(question);
        if (question.choices != null) {
          question.choices.forEach((choice) => {
            if (choice.hasDependency(dependency)) dependents.add(choice);
          });
        }
      });
      return Array.from(dependents);
    };
    survey.questions?.forEach((question) => {
      // PURPOSE: Functions for each question.
      question.isAnswered = function (dependency) {
        const isAnswered = typeLookup(question.type).isAnswered(dependency);
        return isAnswered;
      };

      question.isCompleted = function (q) {
        return typeLookup(question.type).isCompleted?.(q);
      };

      question.getValue = function (q) {
        return typeLookup(question.type).getValue?.(q);
      };

      if (question.dependency != null) {
        question.dependency.isAnswered = function () {
          return question.isAnswered(question.dependency);
        };
      }
      question.getDependents = survey.getDependents;
      question.hasDependency = (dependency) => {
        // PURPOSE: Check if question is dependent on dependency.
        if (question.dependency == null) return false;
        switch (question.dependency.type.toLowerCase()) {
          case "question":
            return question.dependency.question === dependency;
          case "choice":
            return question.dependency.choice === dependency;
          default:
            throw new Error(`Dependency type ${dependency.type} not found.`);
        }
      };
      question.dependents = () => {
        // PURPOSE: Get questions/choices that depend on this question.
        const list = question.getDependents(question);
        return list;
      };
      question.hasDependents = () => {
        // PURPOSE: Check if question has dependents.
        const dependents = question.dependents();
        return dependents != null && dependents.length > 0;
      };

      if (question.choices != null) {
        question.choices.forEach((choice) => {
          // PURPOSE: Functions for each choice.
          choice.getDependents = survey.getDependents;
          choice.hasDependency = (dependency) => {
            // PURPOSE: Check if choice has dependency.
            if (choice.dependency == null) return false;
            switch (choice.dependency.type.toLowerCase()) {
              case "question":
                return choice.dependency.question === dependency;
              case "choice":
                return choice.dependency.choice === dependency;
              default:
                throw new Error(
                  `Dependency type ${dependency.type} not found.`
                );
            }
          };
          choice.hasDependents = () => {
            // PURPOSE: Check if choice has dependents.
            const dependents = choice.dependents();
            return dependents != null && dependents.length > 0;
          };
          choice.dependents = () => {
            // PURPOSE: Get questions/choices that depend on this choice.
            const choiceDependents = survey.getDependents(choice);
            const questionDependents = choice.question().dependents();
            const dependents = choiceDependents.concat(questionDependents);

            return dependents;
          };
          if (choice.dependency != null) {
            //choice.dependency.isAnswered = dependencyUtil.isAnswered; // DEPRECATED 061023
          }
        });
      }
    });
  }

  async getAll(filter) {
    utils.log.api(`survey.getAll(filter: ${filter}})`);
    const remoteUrl = `${getApiOrigin()}/survey/GetAll?viewModel=Survey.ViewModels.List${
      filter != null ? `&filter=${filter}` : ""
    }`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    /*     return fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    }); */
    if (!response.ok) throw new Error("Failed to fetch recipients to contact.");
    return await response.json();
  }

  async update(survey, query) {
    utils.log.api(`survey.update(${survey.code})`, survey);
    const queryString = new URLSearchParams(query).toString();
    const remoteUrl = `${getApiOrigin()}/survey/update?${queryString}`;
    const serialized = JSON.stringify(this.surveyRemoveMethods(survey));

    try {
      const result = await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: serialized,
      });

      if (result.ok) {
        this.surveyPrepare(survey);
      }
      return await result.json();
    } catch (error) {
      utils.log.error(error);
      throw error;
    }
  }

  async remove(id) {
    utils.log.api(`survey.remove(${id})`);
    try {
      const remoteUrl = `${getApiOrigin()}/survey/${id}`;

      const response = await fetch(remoteUrl, {
        method: "DELETE",
        headers: this.headers,
      });

      if (!response?.ok) {
        throw new Error("An Error occur while Deleting the Survey");
      }
    } catch (error) {
      utils.log.error(":: SurveyService.remove()", error?.message);
      throw new Error("An Error occur while Deleting the Survey");
    }
  }
  async recipientsContact(surveyCode, preview = true) {
    utils.log.api(`survey.recipientsContact(surveyCode: ${surveyCode}`);
    utils.assert(
      surveyCode != null,
      "Survey code required.  Unable to get survey."
    );

    const remoteUrl = `${getApiOrigin()}/survey/${surveyCode}/recipients/contact?preview=${preview}`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) throw new Error("Failed to fetch recipients to contact.");
    return await response.json();
  }
  getQuestionTypes() {
    return config.questionTypes;
  }
  getQuestionTypeByLabel(label) {
    const result = config.questionTypes.find((item) => item.label === label);
    if (result == null)
      throw new Error(`Question type label: ${label} not found.`);
    return result;
  }
  typeLookup(type) {
    const result = config.questionTypes.find((item) => item.type === type);
    if (result == null) throw new Error(`Question type "${type}" not found.`);
    return result;
  }

  newQuestion(type, code, title, description, instructions) {
    const question = {
      type: type,
      code: code,
      title: title,
      description: description,
      instructions: instructions,
      dependency: null,
      choices: [],
    };

    return question;
  }

  newChoice(code, value, description, type = "GenericChoice") {
    return { code, value, description, type };
  }

  async uploadRecipients(file, surveyCode, worksiteCode, format, layout) {
    utils.log.api(
      `survey.uploadRecipients(file: ${file.name}, surveyCode: ${surveyCode}, worksiteCode: ${worksiteCode}, format: ${format}, layout: ${layout})`
    );
    utils.assert(file != null, "File is null.");
    utils.assert(surveyCode != null, "Survey code is null.");
    utils.assert(worksiteCode != null, "Worksite code is null.");
    utils.assert(format != null, "Format is null.");
    utils.assert(layout != null, "Layout is null.");

    const apiUrl = `${getApiOrigin()}/survey/uploadRecipients?surveyCode=${surveyCode}&worksiteCode=${worksiteCode}&format=${format}&layout=${layout}`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: this.headers.Authorization,
      },
      body: formData,
    });

    return response;
  }
  async getRecipientCount(surveyCode) {
    utils.log.api(`survey.getRecipientCount(surveyCode: ${surveyCode})`);
    const remoteUrl = `${getApiOrigin()}/survey/${surveyCode}/recipients/pool/count`;

    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) throw new Error("Failed to fetch recipient count.");
    const result = await response.json();

    return result.value;
  }

  async getImportFormats() {
    utils.log.api("survey.getImportFormats");
    const remoteUrl = `${getApiOrigin()}/survey/import/formats`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }

  async getImportLayouts() {
    utils.log.api("survey.getImportLayouts");
    const remoteUrl = `${getApiOrigin()}/survey/import/layouts`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }

  async getAllExemptions() {
    utils.log.api("survey.getAllExemptions");
    const endpoint = `${getApiOrigin()}/exemption/GetAll?surveyCode=&worksiteCode=&departmentCode=&viewModel=Survey.ViewModels.Recipient.Exemption`;

    return await fetch(endpoint, {
      method: "GET",
      headers: this.headers,
    });
  }

  async getSurveyMetrics(surveyCode) {
    utils.log.api("getSurveyMetrics");

    const remoteUrl = `${getApiOrigin()}/survey/${surveyCode}/metrics`;
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
    if (!response.ok) {
      throw new Error("An error occur while fetching survey Metrics");
    }
    const surveyMetrics = await response.json();
    return surveyMetrics;
  }

  async getTransactions() {
    try {
      const remoteUrl = `${getApiOrigin()}/surveyResponse/transactions`;
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      return await response.json();
    } catch (error) {
      utils.log.error("survey.getTransactions()", error);
    }
  }

  async getBalance() {
    try {
      const remoteUrl = `${getApiOrigin()}/surveyResponse/balance/`;
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      return await response.json();
    } catch (error) {
      utils.log.error("survey.getBalance()", error);
    }
  }

  async getClaim() {
    try {
      const remoteUrl = `${getApiOrigin()}/surveyResponse/claim`;
      const response = await fetch(remoteUrl, {
        merhod: "GET",
        headers: this.headers,
      });
      return await response.json();
    } catch (error) {
      utils.log.error("survey.getClaim()", error);
    }
  }

  async getOrders() {
    try {
      const remoteUrl = `${getApiOrigin()}/surveyResponse/orders`;
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });
      return await response.json();
    } catch (error) {
      utils.log.error("survey.getOrders()", error);
    }
  }

  async getPriceList() {
    try {
      const remoteUrl = `${getApiOrigin()}/surveyResponse/prices`;
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });
      return await response.json();
    } catch (error) {
      utils.log.error("survey.getPriceList()", error);
    }
  }

  async purchase(payload) {
    try {
      const remoteUrl = `${getApiOrigin()}/payment/purchase`;
      const response = await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(payload),
      });
      return await response.json();
    } catch (error) {
      utils.log.error("Survey.getPriceList()", error);
    }
  }

  async validate(payload) {
    try {
      const remoteUrl = `${getApiOrigin()}/survey/validate?forceFail=false`;
      const response = await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(payload),
      });
      if(!response.ok){
        throw new Error("An error occur while fetching validations")
      }
      return await response.json();
    } catch (error) {
      utils.log.error("Survey.validate()", error);
      throw error;
    }
  }
}
